import { defineStore } from "pinia";
import { markRaw } from "vue";

import { io } from "socket.io-client";
import * as constants from "./const";

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application

export const API_BASE = constants.API_BASE;

function loadSettingsSite() {
  let settingsSite = {
    sounds: true,
    animations: true,
    streamer: false,
    audioVolume: 1,
    locale: "ru",
  };

  try {
    let savedSettings = window.localStorage.getItem("SettingsSite");
    if (!savedSettings) throw new Error("not found settings");
    let parsedSettings = JSON.parse(savedSettings);
    Object.assign(settingsSite, parsedSettings);
  } finally {
    return settingsSite;
  }
}

function saveSettingsSite(settings) {
  let toSave = JSON.stringify(settings);

  window.localStorage.setItem("SettingsSite", toSave);
}

function toHexString(byteArray) {
  return Array.prototype.map
    .call(byteArray, function (byte) {
      return ("0" + (byte & 0xff).toString(16)).slice(-2);
    })
    .join("");
}

function generateSeed() {
  let randomValues = new Uint8Array(8);
  window.crypto.getRandomValues(randomValues);
  return toHexString(randomValues);
}

export function calcIntermediateCrash(msPassed, msByTick = 50, pingDelay = 0){
  msPassed -= pingDelay;

  let num = 1;
  for(; msPassed >= 0; msPassed -= msByTick){
      let delta = Math.max((0.5*num-1)*0.01, 0.005);
      num += delta
  }
  return num;
}


export const useGameStore = defineStore("interface", {
  state: () => ({
    windowWidth: window.innerWidth,
    bodyScroll: true,
    date: new Date(),

    $toast: undefined,
    socket: undefined,

    pingDelay: 0,

    internal: {
      account: null,
      support: {
        tickets: [],
        supports: [],
        socket: undefined,
      },
      admin:{
        socket: undefined,
        admins: [],
        bots: [],
        presets: [],
        promocodes: [],
        cases: [],
      }
    },

    audioSoundObject: {
      click: "/audio/click.mp3",
      testAudio: "/audio/testAudio.mp3",
      increaseBalance: "/audio/money.mp3",
      doom: "/audio/doom.mp3",


      voile: "/audio/sounds/voile.mp3", 
      withdrows: "/audio/sounds/withdrows.mp3", 
      claim: "/audio/sounds/claim.mp3", 
      crash: "/audio/sounds/crash.mp3", 
      deposit: "/audio/sounds/deposit.mp3", 
      // EndWhell: "/audio/sounds/EndWhell.mp3", 
      // finishCoin: "/audio/sounds/finishCoin.mp3", 
      // startCoin: "/audio/sounds/startCoin.mp3", 
      // startWhell: "/audio/sounds/startWhell.mp3",
      bid: "/audio/sounds/bid.mp3",
    },

    currentTab: "elements_chat",

    chatStatus: true,

    replenishmentStatus: false,
    withdrowStatus: false,

    bonus_popupStatus: false,

    shopStatus: false,

    notificationsStatus: false,

    mobileMenuStatus: false,

    settingsStatus: false,
    settingsSite: loadSettingsSite(),

    supportStatus: false,
    statusEnableTicket: false,

    gamePage: "block_mainScreen",
    bidStatus: "in game" /*in game, lose, win*/,

    authorization: false,
    user: {},
    io: markRaw(io),

    inventory: [],

    chat: {
      messages: [],
      usersOnline: 0,
      muted: [],
    },

    support: {
      tickets: [],
      socket: undefined,
      newTicket: { text: "", subject: "", edit: false },
      selectedTicket: -1,
    },

    notify: {
      notifications: [],
    },

    wheel: {
      round: undefined,
      myBid: {
        items: [],
        sum: 0,
      },
      history: [],
      onStart: () => {},
      onRoll: () => {},
      onResult: () => {},
      startNext: new Date().toISOString(),
      startFrom: new Date().toISOString(),
    },

    case: {
      cases: [],
      coupons: [],
      promocodes: [],
    },

    coin: {
      round: undefined,
      myBid: {
        items: [],
        sum: 0,
      },
      history: [],
      onStart: () => {},
      onRoll: () => {},
      onResult: () => {},
      startNext: new Date().toISOString(),
      startFrom: new Date().toISOString(),
      timerSeconds: 0,
    },

    crash: {
      round: undefined,
      myBid: {
        items: [],
        sum: 0,
        autoClaim: null,
      },
      history: [],
      onStart: () => {},
      onRoll: () => {},
      onResult: () => {},
      startNext: new Date().toISOString(),
      startFrom: new Date().toISOString(),
    },

    stats: {
      stat: undefined,
      otherStat: undefined,
      livedrop: [],
      daily: [],
      userlevels: [],
      dailyCheckData: undefined,
    },

    referrals: {
      stat: undefined,
      levels: [],
    },

    faq: [],

    faqSubjects: [
      {
        id: 'account',
        img: `/support/account.svg`,
        text: {ru:"Проблемы с аккаунтом", en: "Account"},
        class: "right",
        status: "",
      },
      {
        id: 'bonus',
        img: `/support/bounce.svg`,
        text: {ru:"Вопрос по бонусам", en: "Bonuses"},
        class: "right",
        status: "",
      },
      {
        id: 'deposit',
        img: `/support/replenishment.svg`,
        text: {ru:"Проблемы с пополнением", en: "Deposit"},
        class: "right",
        status: "",
      },
      {
        id: 'withdraw',
        img: `/support/conclusion.svg`,
        text: {ru:"Проблемы с выводом", en: "Withdraw"},
        class: "right",
        status: "",
      },
      {
        id: 'site',
        img: `/support/site.svg`,
        text: {ru:"Ошибка сайта", en: "Site error"},
        class: "right",
        status: "",
      },
      {
        id: 'game',
        img: `/support/modes.svg`,
        text: {ru:"Режимы", en: "Game"},
        class: "right",
        status: "",
      },
      {
        id: 'cooperation',
        img: `/support/cooperation.svg`,
        text: {ru:"Сотрудничество", en: "Cooperation"},
        class: "right",
        status: "",
      },
      {
        id: 'other',
        img: `/support/outher.svg`,
        text: {ru:"Другое", en: "Other"},
        class: "right",
        status: "",
      },
    ],

    battlepass: {
      battlepasses: [],
    },

    ...constants,
  }),
  getters: {
    userBalance(state) {
      return Number(state.user?.balance ?? 0);
    },
    userBonusBalance(state) {
      return Number(state.user?.bonusBalance ?? 0);
    },
    userFullBalance(state) {
      return state.userBalance + state.userBonusBalance;
    },
    userInventory(state) {
      return state.inventory;
    },

    finalAudioVolume(state) {
      if (state.settingsSite.sounds) {
        return state.settingsSite.audioVolume;
      } else return 0;
    },

    crashSummary(state) {
      let bids = state.crash.round?.bids ?? [];
      let bidSum = bids.reduce((accum, current) => {
        return accum + Number(current.bidSum);
      }, 0);
      let bidmade = bids.find((b) => b.user.username == state.user.username);

      let result = {
        bids: bids,
        bidSum: bidSum,
        bidsCount: bids.length,
        roundId: state.crash.round?.id ?? 1,
        seedHash: state.crash.round?.seedHash ?? "",
        isRoll: state.crashRolled,
        isResult: state.crashFinished,
        userBid: bidmade,
      };

      return result;
    },

    wheelSummary(state) {
      const bid = (idBid) => {
        let bids =
          state.wheel.round?.bids.filter((b) => b.bidId == idBid) ?? [];
        let bidsSum = bids.reduce((accum, current) => {
          return accum + Number(current.bidSum);
        }, 0);
        let bidsCount = bids.length;
        let isWin = idBid == state.wheel.round?.winBidId;

        return {
          bids: bids,
          bidsSum: bidsSum,
          bidsCount: bidsCount,
          isWin: isWin,
        };
      };

      let X2 = bid("X2");
      let X3 = bid("X3");
      let X6 = bid("X6");
      let X24 = bid("X24");

      let generalSum = X2.bidsSum + X3.bidsSum + X6.bidsSum + X24.bidsSum;

      let result = {
        bids: state.wheel.round?.bids,
        X2: X2,
        X3: X3,
        X6: X6,
        X24: X24,
        generalSum: generalSum,
        seedHash: state.wheel.round?.seedHash ?? "",
        roundId: state.wheel.round?.id ?? 1,
      };

      return result;
    },

    coinSummary(state) {
      const bid = (idBid) => {
        let bids = state.coin.round?.bids.filter((b) => b.bidId == idBid) ?? [];
        let bidsSum = bids.reduce((accum, current) => {
          return accum + Number(current.bidSum);
        }, 0);
        let bidsCount = bids.length;
        let isWin = idBid == state.coin.round?.winBidId;

        return {
          bids: bids,
          bidsSum: bidsSum,
          bidsCount: bidsCount,
          isWin: isWin,
        };
      };

      let HEAD = bid("HEAD");
      let EDGE = bid("EDGE");
      let TAIL = bid("TAIL");

      let generalSum = HEAD.bidsSum + EDGE.bidsSum + TAIL.bidsSum;

      let bids = state.coin.round?.bids ?? [];
      let players = [];
      for (let b of bids) {
        if (!players.includes(b.userId)) {
          players.push(b.userId);
        }
      }

      let playersCount = players.length;

      let result = {
        HEAD: HEAD,
        EDGE: EDGE,
        TAIL: TAIL,
        generalSum: generalSum,
        playersCount: playersCount,
        seedHash: state.coin.round?.seedHash ?? "",
        roundId: state.coin.round?.id ?? 1,
      };

      return result;
    },

    wheelFinished(state) {
      return Boolean(state.wheel.round?.status == "closed");
    },
    wheelRolled(state) {
      return Boolean(state.wheel.round?.status == "roll");
    },
    wheelTimer(state) {
      return Boolean(state.wheel.round?.status == "timer");
    },

    coinFinished(state) {
      return Boolean(state.coin.round?.status == "closed");
    },
    coinRolled(state) {
      return Boolean(state.coin.round?.status == "roll");
    },
    coinTimer(state) {
      return Boolean(state.coin.round?.status == "timer");
    },

    crashFinished(state) {
      return Boolean(state.crash.round?.status == "closed");
    },
    crashRolled(state) {
      return Boolean(state.crash.round?.status == "roll");
    },
    crashTimer(state) {
      return Boolean(state.crash.round?.status == "timer");
    },

    refLink(state) {
      return window.location.origin + "/auth?referrer=" + state.user?.id;
    },

    paymentMethods(state) {
      return [
        {
          type: "skins",
          // icon: "/payment/visa.svg",
          icon: "/payment/waxpeer.png",
          title: "WAXPEER",
          callback: state.payWaxpeer,
        },
        // {
        //   type: "crypto",
        //   icon: "/payment/cryptocloud.png",
        //   title: "CRYPTOCLOUD",
        //   callback: state.payCryptocloud,
        // },
        {
          type: "crypto",
          icon: "/payment/usds.png",
          title: "USDT (ERC20)",
          callback: async (money) => {
            await state.payFreekassa(money, 14);
          },
        },
        {
          type: "crypto",
          icon: "/payment/usdt.png",
          title: "USDT (TRC20)",
          callback: async (money) => {
            await state.payFreekassa(money, 15);
          },
        },
        {
          type: "money",
          icon: "/payment/fkwallet.png",
          title: "FK WALLET USD",
          callback: async (money) => {
            await state.payFreekassa(money, 2);
          },
        },
        {
          type: "money",
          icon: "/payment/sbp.png",
          title: "СБП",
          callback: async (money) => {
            await state.payFreekassa(money, 42);
          },
        },
        {
          type: "money",
          icon: "/payment/mastercard.png",
          title: "MasterCard",
          callback: async (money) => {
            await state.payFreekassa(money, 8);
          },
        },
        {
          type: "money",
          icon: "/payment/transfer.png",
          title: "Переводом",
          callback: async (money) => {
            await state.payFreekassa(money, 13);
          },
        },
        {
          type: "money",
          icon: "/payment/visa.svg",
          title: "VISA",
          callback: async (money) => {
            await state.payFreekassa(money, 4);
          },
        },
        // {
        //   type: "money",
        //   icon: "/payment/qiwi.svg",
        //   title: "Qiwi",
        //   callback: async (money) => {
        //     await state.payFreekassa(money, 10);
        //   },
        // },
        {
          type: "money",
          icon: "/payment/mir.svg",
          title: "МИР",
          callback: async (money) => {
            await state.payFreekassa(money, 12);
          },
        },
      ];
    },

    BALANCE_BY_USD() {
      return 10;
    },

    SITE_COPY_NAME() {
      return "CSSweets";
    },

    isMobile(state) {
      return state.windowWidth <= 1024;
    },

    isAdmin(state) {
      return Boolean(state.internal.account);
    },

    activeBattlePass(state) {
      return state.battlepass.battlepasses.find(bp => bp.isActive);
    },
  },
  actions: {
    setBodyScroll(scroll = false) {
      if (!this.isMobile) return;

      this.bodyScroll = scroll;
      if (scroll) {
        document.querySelector("body").style.overflow = "auto";
      } else {
        document.querySelector("body").style.overflow = "hidden";
      }
    },
    /* audio */

    playSound(sound) {
      var audio = new Audio();
      audio.volume = this.finalAudioVolume / 10;
      audio.src = this.audioSoundObject[sound];
      audio.autoplay = true;
    },

    /* audio */

    cnahgeMobileMenuStatus() {
      this.mobileMenuStatus = !this.mobileMenuStatus;
    },

    changeSettings(action) {
      if (action == "sounds") {
        this.settingsSite.sounds = !this.settingsSite.sounds;
        if (this.settingsSite.sounds && this.settingsSite.audioVolume == 0) {
          this.settingsSite.audioVolume = 1;
        }
      } else if (action == "animations") {
        this.settingsSite.animations = !this.settingsSite.animations;
      } else {
        this.settingsSite.streamer = !this.settingsSite.streamer;
      }

      saveSettingsSite(this.settingsSite);
    },
    setAudioVolume(volume) {
      this.settingsSite.audioVolume = volume;
      saveSettingsSite(this.settingsSite);
    },
    setLocale(locale) {
      this.settingsSite.locale = locale;
      saveSettingsSite(this.settingsSite);
    },



    toggleBonus_popupStatus() {
      this.bonus_popupStatus = !this.bonus_popup;
    },
    toggleReplenishmentStatus() {
      this.replenishmentStatus = !this.replenishmentStatus;
    },
    toggleWithdrowStatus() {
      this.withdrowStatus = !this.withdrowStatus;
    },
    toggleNotificationsStatus() {
      this.notificationsStatus = !this.notificationsStatus;
    },
    toggleSettingsStatus() {
      this.settingsStatus = !this.settingsStatus;
    },
    toggleChatStatus() {
      this.chatStatus = !this.chatStatus;
    },



    //авторизация

    async loadUser() {
      try {
        let res = await fetch(API_BASE + "/auth/test", {
          credentials: "include",
          cors: "none",
        });
        if (res.ok) {
          let user = await res.json();
          this.user = user;
          this.authorization = true;
        } else {
          throw { message: "unauthorized" };
        }
        return true;
      } catch (e) {
        console.warn(e);
        this.user = {};
        this.authorization = false;
        return false;
      }
    },
    async loginSteam(returnTo) {
      let params = new URLSearchParams({ returnTo: returnTo });

      let authLink = `${API_BASE}/auth?${params.toString()}`;
      window.location = authLink;
    },

    async supportLogin(login, pass) {
      try {
        let res = await fetch(API_BASE + "/support/auth", {
          credentials: "include",
          cors: "none",
          body: JSON.stringify({ login: login, pass: pass }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.ok) {
          let account = await res.json();
          this.internal.account = account;
        } else {
          throw { message: "unauthorized" };
        }
        return true;
      } catch (e) {
        console.warn(e);
        throw e;
      }
    },
    async checkSupportAuth() {
      try {
        let res = await fetch(API_BASE + "/support/test", {
          credentials: "include",
          cors: "none",
          method: "POST",
        });
        if (res.ok) {
          let account = await res.json();
          this.internal.account = account;
        } else {
          throw { message: "unauthorized" };
        }
        return true;
      } catch (e) {
        console.warn(e);
        return false;
        // throw e;
      }
    },

    async logout() {
      this.user = {};
      this.authorization = false;
      location.href = API_BASE + "/logout";
    },


    //тест баланса
    async testAddBalance() {
      try {
        let res = await fetch(API_BASE + "/auth/balance", {
          credentials: "include",
          cors: "none",
        });
        if (res.ok) {
        } else {
          throw { message: "unauthorized" };
        }
        return true;
      } catch (e) {
        console.warn(e);
        return false;
      }
    },

    //депозиты
    async payWaxpeer(money) {
      try {
        let res = await fetch(API_BASE + "/auth/gowaxdeposit", {
          credentials: "include",
          cors: "none",
        });
        if (res.ok) {
          let deposit = await res.json();
          //TODO добавить в стейт
          window.open(
            `https://pay.waxpeer.com/merchant/cssweets?project_id=${deposit.id}`
          );
          //location.href = `https://pay.waxpeer.com/merchant/cssweets?project_id=${deposit.id}`;
        } else {
          throw { message: "int.errorPay" };
        }
        return true;
      } catch (e) {
        console.warn(e);
        throw e;
        return false;
      }
      //location.href = 'https://pay.waxpeer.com/merchant/csfail?project_id=9cc0564a-889b-4120-ac72-6cbffedb8bc8&success_url=https%3A%2F%2Fcsfail.live%2Fru%2Fpayment%2Fsuccess&failed_url=https%3A%2F%2Fcsfail.live%2Fru%2Fpayment%2Ferror';
    },
    async payCryptocloud(money) {
      try {
        if (money <= 0) throw new Error('int.plusSum');
        let res = await fetch(API_BASE + "/auth/gocryptodeposit?sum=" + money, {
          credentials: "include",
          cors: "none",
        });
        if (res.ok) {
          let deposit = await res.json();
          window.open(deposit.pay_url);
          //location.href = `https://pay.waxpeer.com/merchant/cssweets?project_id=${deposit.id}`;
        } else {
          throw { message: "int.errorPay" };
        }
        return true;
      } catch (e) {
        console.warn(e);
        throw e;
        return false;
      }
      //location.href = 'https://pay.waxpeer.com/merchant/csfail?project_id=9cc0564a-889b-4120-ac72-6cbffedb8bc8&success_url=https%3A%2F%2Fcsfail.live%2Fru%2Fpayment%2Fsuccess&failed_url=https%3A%2F%2Fcsfail.live%2Fru%2Fpayment%2Ferror';
    },
    async payFreekassa(money, paySystemId) {
      try {
        if (money <= 0) throw new Error('int.plusSum');
        let res = await fetch(
          API_BASE +
            "/auth/gofreekassadeposit?sum=" +
            money +
            "&systemid=" +
            paySystemId,
          {
            credentials: "include",
            cors: "none",
          }
        );
        if (res.ok) {
          let deposit = await res.json();
          window.open(deposit.pay_url);
        } else {
          throw { message: "int.errorPay" };
        }
        return true;
      } catch (e) {
        console.warn(e);
        throw e;
        return false;
      }
    },

    //сокеты
    initUser() {
      if (this.socket) return;
      const socket = this.io(API_BASE + "/user", {
        withCredentials: true,
        autoConnect: true,
      });

      
      socket.on("rate limit", () => {
        this.$toast.error("Too Many Requests");
      });
      socket.on("server overloaded", () => {
        this.$toast.warning("The server is overloaded, try again later");
      });

      //chat
      socket.on("messagesList", (messages) => {
        this.chat.messages = messages;
      });

      socket.on("users count", (count) => {
        this.chat.usersOnline = Number(count);
      });

      socket.on("new message", (message) => {
        this.chat.messages.push(message);
      });

      socket.on("delete message", (messageId) => {
        const idx = this.chat.messages.findIndex(({id})=>id == messageId);
        if(idx !== -1){
          this.chat.messages.splice(idx, 1);
        }
      });

      //notify
      socket.on("notifications list", (notifications) => {
        this.notify.notifications = notifications;
      });

      socket.on("new notification", (notification) => {
        this.playSound('deposit');
        this.notify.notifications.push(notification);
      });

      //wheel
      socket.on("current round wheel", (round, startNext, startFrom) => {
        this.wheel.round = round;
        this.wheel.startNext = startNext;
        this.wheel.startFrom = startFrom;
        this.checkWheelStatus();
      });

      socket.on("roll round wheel", (round, startNext, startFrom) => {
        this.wheel.round = round;
        this.wheel.startNext = startNext;
        this.wheel.startFrom = startFrom;
        typeof this.wheel.onRoll !== 'function' ? void 0 : this.wheel.onRoll();
      });
      socket.on("start round wheel", (round, startNext, startFrom) => {
        this.wheel.round = round;
        this.wheel.startNext = startNext;
        this.wheel.startFrom = startFrom;
        typeof this.wheel.onStart !== 'function' ? void 0 : this.wheel.onStart();
      });
      socket.on("end round wheel", (round, startNext, startFrom) => {
        this.wheel.round = round;
        this.wheel.startNext = startNext;
        this.wheel.startFrom = startFrom;
        typeof this.wheel.onResult !== 'function' ? void 0 : this.wheel.onResult();
        if (!this.wheel.history.some((r) => +r.id == +round.id)) {
          this.wheel.history.push(round);
        }
      });

      socket.on("new bid wheel", (bid) => {
        this.wheel.round?.bids.push(bid);
      });

      socket.on("history wheel", (history) => {
        const newHistory = history;
        const oldHistory = this.wheel.history;

        const uniqueTable = {};
        let mixedHistory = [...newHistory, ...oldHistory].filter(
          ({ id }) => !uniqueTable[+id] && (uniqueTable[+id] = 1)
        );

        this.wheel.history = mixedHistory;
      });

      //coin
      socket.on("current round coin", (round, startNext, startFrom) => {
        this.coin.round = round;
        this.coin.startNext = startNext;
        this.coin.startFrom = startFrom;
        this.checkCoinStatus();
      });

      socket.on("roll round coin", (round, startNext, startFrom) => {
        this.coin.round = round;
        this.coin.startNext = startNext;
        this.coin.startFrom = startFrom;
        typeof this.coin.onRoll !== 'function' ? void 0 : this.coin.onRoll();
      });
      socket.on("start round coin", (round, startNext, startFrom) => {
        this.coin.round = round;
        this.coin.startNext = startNext;
        this.coin.startFrom = startFrom;
        typeof this.coin.onStart !== 'function' ? void 0 : this.coin.onStart();
      });
      socket.on("end round coin", (round, startNext, startFrom) => {
        this.coin.round = round;
        this.coin.startNext = startNext;
        this.coin.startFrom = startFrom;
        typeof this.coin.onResult !== 'function' ? void 0 : this.coin.onResult();
        if (!this.coin.history.some((r) => +r.id == +round.id)) {
          this.coin.history.push(round);
        }
      });

      socket.on("new bid coin", (bid) => {
        this.coin.round?.bids.push(bid);
      });

      socket.on("history coin", (history) => {
        const newHistory = history;
        const oldHistory = this.coin.history;

        const uniqueTable = {};
        let mixedHistory = [...newHistory, ...oldHistory].filter(
          ({ id }) => !uniqueTable[+id] && (uniqueTable[+id] = 1)
        );

        this.coin.history = mixedHistory;
      });


      //crash
      socket.on("current round crash", (round, startNext, startFrom) => {
        this.crash.round = round;
        this.crash.startNext = startNext;
        this.crash.startFrom = startFrom;
        this.checkCrashStatus();
      });

      socket.on("roll round crash", (round, startNext, startFrom) => {
        this.crash.round = round;
        this.crash.startNext = startNext;
        this.crash.startFrom = startFrom;
        typeof this.crash.onRoll !== 'function' ? void 0 : this.crash.onRoll();
        this.startRecalculateCrash();
      });
      socket.on("start round crash", (round, startNext, startFrom) => {
        this.crash.round = round;
        this.crash.startNext = startNext;
        this.crash.startFrom = startFrom;
        typeof this.crash.onStart !== 'function' ? void 0 : this.crash.onStart();
      });
      socket.on("end round crash", (round, startNext, startFrom) => {
        this.stopRecalculateCrash();
        this.crash.round = round;
        this.crash.startNext = startNext;
        this.crash.startFrom = startFrom;
        typeof this.crash.onResult !== 'function' ? void 0 : this.crash.onResult();
        if (!this.crash.history.some((r) => +r.id == +round.id)) {
          this.crash.history.push(round);
        }
      });

      socket.on("multiplier round crash", (crash) => {
        if (this.crash?.round) this.crash.round.crash = Number(crash);
      });

      socket.on("new bid crash", (bid) => {
        this.crash.round?.bids.push(bid);
      });

      socket.on("history crash", (history) => {
        const newHistory = history;
        const oldHistory = this.crash.history;

        const uniqueTable = {};
        let mixedHistory = [...newHistory, ...oldHistory].filter(
          ({ id }) => !uniqueTable[+id] && (uniqueTable[+id] = 1)
        );

        this.crash.history = mixedHistory;
      });

      socket.on("claim bids crash", (bids) => {
        if (this.crash.round) {
          for (let bid of bids) {
            this.crash.round.bids = this.crash.round.bids.map((b) =>
              b.id == bid.id ? bid : b
            );
          }
        }
      });

      //stats
      socket.on("current balance", (b) => {
        this.$patch((state) => {
          state.user.balance = Number(b.balance);
          state.user.bonusBalance = Number(b.bonusBalance);
        });
      });

      socket.on("current items", (items) => {
        this.inventory = items;
      });

      socket.on("livedrop", (drop) => {
        this.stats.livedrop.unshift(drop);
      });

      socket.on("livedrop data", (data) => {
        this.stats.livedrop = data;
      });

      socket.on("daily data", (data) => {
        this.stats.daily = data;
      });

      socket.on("user levels", (levels) => {
        this.stats.userlevels = levels;
      });

      socket.on(
        "current level balance",
        ({ userLevelBalance, userLevelId}) => {
          this.$patch((state) => {
            state.user.userLevelBalance = Number(userLevelBalance);
            state.user.userLevelId = Number(userLevelId);
          });
        }
      );

      socket.on("user data", (user) => {
        this.$patch((state) => {
          state.user = user;
          state.user.balance = Number(user.balance);
        });
      });


      socket.on("ref levels", (levels) => {
        this.referrals.levels = levels;
      });

      socket.on("ref data", (data) => {
        this.referrals.stat = data;
      });

      socket.on("faq", (faq) => {
        this.faq = faq;
      });

      //battlepass
      socket.on("battlepasses list", (bps) => {
        this.battlepass.battlepasses = bps;
      });

      socket.on("current bp xp", (xp) => {
        if(this.activeBattlePass){
          this.activeBattlePass.bpXp = xp;
        }
      });

      //cases
      socket.on("cases list", (cases) => {
        this.case.cases = cases;
      });

      socket.on("case coupons list", (coupons) => {
        this.case.coupons = coupons;
      });

      socket.on("case promocodes list", (promocodes) => {
        this.case.promocodes = promocodes;
      });

      






      this.socket = markRaw(socket);
    },
    initSupport() {
      if (this.support.socket);
      const socket = this.io(API_BASE + "/support", {
        withCredentials: true,
        autoConnect: false,
      });

      socket.on("rate limit", () => {
        this.$toast.error("Too Many Requests");
      });

      socket.on("user tickets", (tickets) => {
        this.support.tickets = tickets;
      });

      socket.on("closed ticket", (ticket) => {
        let ticketOld = this.support.tickets.find((t) => t.id == ticket.id);
        if (ticketOld) {
          Object.assign(ticketOld, ticket);
        } else {
          this.support.tickets.push(ticket);
        }
      });

      socket.on("new message", (message) => {
        let ticket = this.support.tickets.find(
          (t) => (t.id == message.ticketId)
        );
        if (ticket) {
          ticket.messages.push(message);
        }
      });

      this.support.socket = markRaw(socket);
    },
    connectSupport() {
      this.support.socket.connect();
    },
    initSupportWorker() {
      if (this.internal.support.socket);
      const socket = this.io(API_BASE + "/support", {
        withCredentials: true,
        autoConnect: false,
      });

      socket.on("supports", (supports) => {
        this.internal.support.supports = supports;
      });

      socket.on("closed ticket", (ticket) => {
        let ticketOld = this.internal.support.tickets.find(
          (t) => t.id == ticket.id
        );
        if (ticketOld) {
          Object.assign(ticketOld, ticket);
        } else {
          this.support.tickets.push(ticket);
        }
      });

      socket.on("all tickets", (tickets) => {
        this.internal.support.tickets = tickets;
      });

      socket.on("ticket asserted", (ticket) => {
        let newTicket = ticket;
        let ticketOld = this.internal.support.tickets.find(
          (t) => t.id == newTicket.id
        );
        Object.assign(ticketOld, newTicket);
      });

      socket.on("new ticket", (ticket) => {
        this.internal.support.tickets.push(ticket);
      });

      socket.on("new message", (message) => {
        let ticket = this.internal.support.tickets.find(
          (t) => (t.id == message.ticketId)
        );
        if (ticket) {
          ticket.messages.push(message);
        }
      });

      this.internal.support.socket = markRaw(socket);
    },
    connectSupportWorker() {
      this.internal.support.socket.connect();
    },
    initAdmin() {
      if (this.internal.admin.socket) return;
      const socket = this.io(API_BASE + "/admin", {
        withCredentials: true,
        autoConnect: false,
      });

      //limit
      socket.on("rate limit", () => {
        this.$toast.error("Too Many Requests");
      });

      socket.on("admins", (admins) => {
        this.internal.admin.admins = admins;
      });


      this.internal.admin.socket = markRaw(socket);
    },
    connectAdmin() {
      this.internal.admin.socket.connect();
    },

    // действия

    //замерить пинг
    async checkPingDelay() {
      const sendTime = await this.socket.emitWithAck("ping");
      const delay = Date.now() - (new Date(sendTime)).getTime();
      this.pingDelay = delay;
    },

    //чат
    async sendChatMessage(text, answerTo = undefined) {
      let ask = await this.socket.emitWithAck("send message", {
        text: text,
        answerTo: answerTo,
      });
      if (!ask.ok) throw new Error(ask.message);
      return ask;
    },

    
    //поддержка
    async createTicket(callback) {
      let ask = await this.support.socket
        .timeout(1000)
        .emitWithAck("create ticket", {
          text: this.support.newTicket.text,
          subject: this.support.newTicket.subject,
        });

      if (!ask.ok) throw new Error(ask.message);

      this.support.tickets.unshift(ask.ticket);
      callback(ask.ticket.id);
      return ask.ticket;
    },
    async sendTicketMessage(text, ticketId) {
      let ask = await this.support.socket.emitWithAck("send message", {
        text,
        ticketId,
      });
      if (!ask.ok) throw new Error(ask.message);
      return ask;
    },
    async closeTicket(ticketId) {
      let ask = await this.support.socket.emitWithAck("close ticket", ticketId);
      if (!ask.ok) throw new Error(ask.message);
      // let ticket = this.support.tickets.find(t => t.id == ticketId);
      // Object.assign(ticket, ask.ticket);
      return ask;
    },
    
    //уведомления
    async readAllNotify() {
      let ask = await this.socket.emitWithAck("read all");
      if (!ask.ok) throw new Error(ask.message);
      this.notify.notifications.map((n) => (n.viewed = true));
    },


    //колесо
    checkWheelStatus() {
      switch (this.wheel.round?.status) {
        case "timer":
          typeof this.wheel.onStart !== 'function' ? void 0 : this.wheel.onStart();
          break;
        case "roll":
          typeof this.wheel.onRoll !== 'function' ? void 0 : this.wheel.onRoll();
          break;
        case "closed":
          typeof this.wheel.onResult !== 'function' ? void 0 : this.wheel.onResult();
          break;
      }
    },
    async connectWheel(onRoll, onStart, onResult) {
      this.wheel.onRoll = onRoll;
      this.wheel.onStart = onStart;
      this.wheel.onResult = onResult;
    },
    async clearWheel() {
      this.wheel.onRoll = null;
      this.wheel.onStart = null;
      this.wheel.onResult = null;
    },
    async placeWheelBid(typeId) {
      if (!this.wheel.round) return;

      let ask = await this.socket.emitWithAck("place bid wheel", {
        roundId: this.wheel.round.id,
        typeId: typeId,
        seed: generateSeed(),
        items: this.wheel.myBid.items.map((i) => i.id),
        sum:
          this.wheel.myBid.items.length > 0 ? undefined : this.wheel.myBid.sum,
      });

      if (!ask.ok) throw new Error(ask.message);

      this.wheel.myBid.items = [];
    },
    async getWheelHistoryRound(id) {
      let round = await this.socket.emitWithAck("get history wheel", id);
      if (!this.wheel.history.some((r) => +r.id == +round.id)) {
        this.wheel.history.push(round);
      }
    },

    //коин
    checkCoinStatus() {
      switch (this.coin.round?.status) {
        case "timer":
          typeof this.coin.onStart !== 'function' ? void 0 : this.coin.onStart();
          break;
        case "roll":
          typeof this.coin.onRoll !== 'function' ? void 0 : this.coin.onRoll();
          break;
        case "closed":
          typeof this.coin.onResult !== 'function' ? void 0 : this.coin.onResult();
          break;
      }
    },
    async connectCoin(onRoll, onStart, onResult) {
      this.coin.onRoll = onRoll;
      this.coin.onStart = onStart;
      this.coin.onResult = onResult;
    },
    async clearCoin() {
      this.coin.onRoll = null;
      this.coin.onStart = null;
      this.coin.onResult = null;
    },
    async placeCoinBid(typeId) {
      if (!this.coin.round) return;
      let ask = await this.socket.emitWithAck("place bid coin", {
        roundId: this.coin.round.id,
        typeId: typeId,
        seed: generateSeed(),
        items: this.coin.myBid.items.map((i) => i.id),
        sum: this.coin.myBid.items.length > 0 ? undefined : this.coin.myBid.sum,
      });
      if (!ask.ok) throw new Error(ask.message);

      this.coin.myBid.items = [];
    },
    async getCoinHistoryRound(id) {
      let round = await this.socket.emitWithAck("get history coin", id);
      if (!this.coin.history.some((r) => +r.id == +round.id)) {
        this.coin.history.push(round);
      }
    },

    //краш
    checkCrashStatus() {
      switch (this.crash.round?.status) {
        case "timer":
          typeof this.crash.onStart !== 'function' ? void 0 : this.crash.onStart();
          break;
        case "roll":
          typeof this.crash.onRoll !== 'function' ? void 0 : this.crash.onRoll();
          this.startRecalculateCrash();
          break;
        case "closed":
          typeof this.crash.onResult !== 'function' ? void 0 : this.crash.onResult();
          break;
      }
    },
    async connectCrash(onRoll, onStart, onResult) {
      this.crash.onRoll = onRoll;
      this.crash.onStart = onStart;
      this.crash.onResult = onResult;
    },
    async clearCrash() {
      this.crash.onRoll = null;
      this.crash.onStart = null;
      this.crash.onResult = null;
    },
    async placeCrashBid() {
      if (!this.crash.round) return;
      let ask = await this.socket.emitWithAck("place bid crash", {
        roundId: this.crash.round.id,
        autoClaim: this.crash.myBid.autoClaim,
        seed: generateSeed(),
        items: this.crash.myBid.items.map((i) => i.id),
        sum:
          this.crash.myBid.items.length > 0 ? undefined : this.crash.myBid.sum,
      });
      if (!ask.ok) throw new Error(ask.message);

      this.crash.myBid.items = [];
    },
    async claimCrashBid() {
      if (!this.crash.round) return;
      let ask = await this.socket.emitWithAck("claim bid crash", {
        roundId: this.crash.round.id,
      });
      if (!ask.ok) throw new Error(ask.message);
    },
    async getCrashHistoryRound(id) {
      let round = await this.socket.emitWithAck("get history crash", id);
      if (!this.crash.history.some((r) => +r.id == +round.id)) {
        this.crash.history.push(round);
      }
    },
    startRecalculateCrash() {
      return;
      // clearInterval(this.crash.increaseInterval);
      // this.crash.increaseInterval = setInterval(()=>{
      //   if (this.crash?.round) this.crash.round.crash =  Number(calcIntermediateCrash(Date.now() - (new Date(this.crash.round.startedAt)).getTime(), 50, this.pingDelay).toFixed(2));
      // }, 100);
    },
    stopRecalculateCrash() {
      return;
      // clearInterval(this.crash.increaseInterval);
    },


    //статистика
    async getOtherStats(username) {
      this.stats.otherStat = undefined;
      let stat = await this.socket.emitWithAck("other stats", username);
      this.stats.otherStat = stat;
      return stat;
    },
    async reloadUserStats() {
      let ask = await this.socket.emitWithAck("profile stats");
      if (!ask.ok) throw new Error(ask.message);
      this.stats.stat = ask.stats;
    },

    //бонусы
    async checkDailyBonus() {
      let ask = await this.socket.emitWithAck("check daily bonus");
      if (!ask.ok) throw new Error(ask.message);
      this.stats.dailyCheckData = ask.checkData;
      this.user.receivedBonuses = ask.checkData.receivedBonuses;
      return ask;
    },
    async getDailyBonus() {
      let ask = await this.socket.emitWithAck("get daily bonus");
      if (!ask.ok) throw new Error(ask.message);
      this.stats.dailyCheckData = ask.checkData;
      this.user.receivedBonuses = ask.checkData.receivedBonuses;
      return ask;
    },
    async getOnceBonus(bonusName) {
      let ask = await this.socket.emitWithAck(
        "get once bonus",
        bonusName
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask;
    },

    //промокоды
    async redeemCode(code, withDeposit) {
      let ask = await this.socket.emitWithAck("redeem code", code, withDeposit);
      if (!ask.ok) throw new Error(ask.message);
      return ask;
    },

    //TODO убрать?
    async setPartnerCode(code) {
      let result = await this.socket.emitWithAck("ref code", code);
      if (!result.ok) throw new Error(result.message);

      this.user.partnerCode = code;
      return true;
    },

    //рефки
    async claimRefBalance() {
      let ask = await this.socket.emitWithAck("claim ref balance");
      if (!ask.ok) throw new Error(ask.message);
    },

    //трейд ссылка
    async saveTradeLink(link) {
      let result = await this.socket.emitWithAck("save trade link", link);

      if (!result.ok) throw new Error(result.message);
      this.user.tradeLink = link;
      return true;
    },

    //честная игра
    async getRoundFairData(id, gamemode) {
      let result = await this.socket.emitWithAck(
        "get fair data",
        id,
        gamemode
      );
      if (!result.ok) throw new Error(result.message);
      return result.data;
    },


    //вывод
    async getWithdrawData() {
      let result = await this.socket.emitWithAck(
        "check withdraw available",
        this.user.tradeLink
      );
      if (!result.ok) throw new Error(result.message);
      return result.data;
    },
    async withdrawItem(itemId, tradeLink) {
      let result = await this.socket.emitWithAck(
        "withdraw item",
        itemId,
        tradeLink
      );
      if (!result.ok) throw new Error(result.message);
      return result.data;
    },


    //магазин
    async getPrices(searchObj) {
      let result = await this.socket.emitWithAck(
        "get items list",
        searchObj
      );
      return result;
    },
    async sellItems(itemIds) {
      let result = await this.socket.emitWithAck(
        "sell items",
        itemIds
      );
      if (!result.ok) throw new Error(result.message);
      return result;
    },
    async buyItems(itemNames) {
      let result = await this.socket.emitWithAck(
        "buy items",
        itemNames
      );
      if (!result.ok) throw new Error(result.message);
      return result;
    },


    //батлпасс
    async buyBattlepass() {
      let ask = await this.socket.emitWithAck(
        "buy battlepass"
      );
      if (!ask.ok) throw new Error(ask.message);
      this.battlepass.battlepasses.push(ask.battlepass);
      return ask.battlepass;
    },
    async checkBpTasks() {
      let ask = await this.socket.emitWithAck(
        "check bp tasks"
      );
      if (!ask.ok) throw new Error(ask.message);
      this.activeBattlePass.completedTasks = ask.tasks;
      return ask.tasks;
    },
    async getBpReward(cellId) {
      let ask = await this.socket.emitWithAck(
        "get bp reward",
        cellId
      );
      if (!ask.ok) throw new Error(ask.message);
      
      this.activeBattlePass.receivedRewards.push(ask.reward);
      return ask.reward;
    },







    //cases
    async openCases(caseId, caseCount, couponId) {
      let ask = await this.socket.emitWithAck(
          "open case",
          {
            caseId,
            caseCount,
            userSeed: generateSeed(),
            couponId
          }
      );
      if (!ask.ok) throw new Error(ask.message);

      return ask.cases;
    },



    

    
    //админка

    //cases

    async getCasesList() {
      let ask = await this.internal.admin.socket.emitWithAck(
          "get cases list"
      );
      if (!ask.ok) throw new Error(ask.message);
      this.internal.admin.cases = ask.cases;
      return ask.cases;
    },

    async createCase({caseName, caseType, price, img, color, items}) {
      console.log({caseName, caseType, price, img, color, items});
      let ask = await this.internal.admin.socket.emitWithAck(
          "create case",
          {caseName, caseType, price, img, color, items}
      );
      if (!ask.ok) throw new Error(ask.message);
      this.internal.admin.cases.push(ask.cases)
      return ask.cases;
    },

    async editCase(caseId, {caseName, caseType, price, img, color, items}) {
      let ask = await this.internal.admin.socket.emitWithAck(
          "edit case",
          caseId, {caseName, caseType, price, img, color, items}
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask.cases;
    },

    async delCase(caseId) {
      let ask = await this.internal.admin.socket.emitWithAck(
          "del case",
          caseId,
      );
      if (!ask.ok) throw new Error(ask.message);
      let oldIdx = this.internal.admin.cases.findIndex(({id})=> id == caseId);
      this.internal.admin.cases.splice(oldIdx, 1);
      return ask.cases;
    },


    //тикеты

    async supportAssertTicket(ticketId) {
      let ask = await this.internal.support.socket.emitWithAck(
        "assert ticket",
        ticketId
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask;
    },
    async supportReadTicket(ticketId, userId) {
      let ask = await this.internal.support.socket.emitWithAck(
        "read ticket",
        ticketId,
        userId
      );
      if (!ask.ok) throw new Error(ask.message);

      let newTicket = ask.ticket;

      let ticket = this.internal.support.tickets.find(
        (t) => t.id == newTicket.id
      );
      Object.assign(ticket, newTicket);

      return ticket;
    },
    async supportSendMessage(text, ticketId) {
      let ask = await this.internal.support.socket.emitWithAck(
        "send sup message",
        { text, ticketId }
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask;
    },
    async supportCloseTicket(ticketId) {
      let ask = await this.internal.support.socket.emitWithAck(
        "close sup ticket",
        ticketId
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask;
    },


    //боты
    async getBotsList() {
      let ask = await this.internal.admin.socket.emitWithAck(
        "get all bots"
      );
      if (!ask.ok) throw new Error(ask.message);
      this.internal.admin.bots = ask.bots;
      return ask.bots;
    },
    async getPresetsList() {
      let ask = await this.internal.admin.socket.emitWithAck(
        "get all presets"
      );
      if (!ask.ok) throw new Error(ask.message);
      this.internal.admin.presets = ask.presets;
      return ask.presets;
    },
    async addBot(userId, presetId, immediateStart = false) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "add bot",
        userId, presetId, immediateStart
      );
      if (!ask.ok) throw new Error(ask.message);
      this.internal.admin.bots.push(ask.bot);
      return ask.bot;
    },
    async deleteBot(botId) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "delete bot",
        botId
      );
      if (!ask.ok) throw new Error(ask.message);

      let oldIdx = this.internal.admin.bots.findIndex(({id})=> id == botId);
      this.internal.admin.bots.splice(oldIdx, 1);
      
      return ask.bot;
    },
    async hideBot(botId) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "hide bot",
        botId
      );
      if (!ask.ok) throw new Error(ask.message);

      let oldIdx = this.internal.admin.bots.findIndex(({id})=> id == botId);
      if(oldIdx !== -1){
        this.internal.admin.bots.splice(oldIdx, 1, ask.bot);
      }
      return ask.bot;
    },
    async setActivityBot(botId, isWorking) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "set activity bot",
        botId, isWorking
      );
      if (!ask.ok) throw new Error(ask.message);

      let oldIdx = this.internal.admin.bots.findIndex(({id})=> id == botId);
      if(oldIdx !== -1){
        this.internal.admin.bots.splice(oldIdx, 1, ask.bot);
      }
      return ask.bot;
    },
    async setBotsPreset(botIds, presetId, immediateRestart = false) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "set preset to bots",
        botIds, presetId, immediateRestart
      );
      if (!ask.ok) throw new Error(ask.message);

      const uniqueTable = {};
      let mixedBots = [...ask.bots, ...this.internal.admin.bots].filter(
        ({ id }) => !uniqueTable[+id] && (uniqueTable[+id] = 1)
      );
      this.internal.admin.bots = mixedBots;

      return ask.bots;
    },
    async createOrUpdatePreset(presetKey, preset, immediateRestart = false) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "set preset",
        presetKey, preset, immediateRestart
      );
      if (!ask.ok) throw new Error(ask.message);

      let oldPresetIdx = this.internal.admin.presets.findIndex(({id})=> id == presetKey);
      if(oldPresetIdx !== -1){
        this.internal.admin.presets.splice(oldPresetIdx, 1, ask.preset);
      }else{
        this.internal.admin.presets.push(ask.preset);
      }
      return ask.preset;
    },
    async deletePreset(presetKey) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "delete preset",
        presetKey
      );
      if (!ask.ok) throw new Error(ask.message);

      let oldPresetIdx = this.internal.admin.presets.findIndex(({id})=> id == presetKey);
      this.internal.admin.presets.splice(oldPresetIdx, 1);
      
      return ask.preset;
    },

    //промокоды
    async getPromocodesList() {
      let ask = await this.internal.admin.socket.emitWithAck(
        "get all promocodes"
      );
      if (!ask.ok) throw new Error(ask.message);
      this.internal.admin.promocodes = ask.codes;
      return ask.codes;
    },
    async createPromocode(code, bonus, total, withDeposit) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "create promocode",
        code, bonus, total, withDeposit
      );
      if (!ask.ok) throw new Error(ask.message);
      this.internal.admin.promocodes.push(ask.code)
      return ask.code;
    },
    async deletePromocode(delCode) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "delete promocode",
        delCode
      );
      if (!ask.ok) throw new Error(ask.message);

      let oldIdx = this.internal.admin.promocodes.findIndex(({code})=> code == delCode);
      this.internal.admin.promocodes.splice(oldIdx, 1);

      return ask.code;
    },

    //статистика
    async getStatictics() {
      let ask = await this.internal.admin.socket.emitWithAck(
        "get statistics"
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask.data;
    },

    //пользователи
    async getUsersStats(search) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "find users stats",
        search
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask.users;
    },

    async getUsersBySteamId(steamid) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "find users by steamid",
        steamid
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask.users;
    },

    async getUsersBySus() {
      let ask = await this.internal.admin.socket.emitWithAck(
        "find users by sus"
      );
      if (!ask.ok) throw new Error(ask.message);
      return {users: ask.users, queryData: ask.queryData, ipBanData: ask.ipBanData};
    },

    async addUserMoney(userId, sum) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "add user money",
        userId, sum
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask.notice;
    },

    //faq
    async setFaq(key, lang, subject, title, text) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "set faq",
        key, lang, subject, title, text
      );
      if (!ask.ok) throw new Error(ask.message);

      let oldIdx = this.faq.findIndex((faq)=> faq.key == key && faq.lang == lang);
      if(oldIdx !== -1){
        this.faq.splice(oldIdx, 1, ask.faq);
      }else{
        this.faq.push(ask.faq);
      }

      return ask.faq;
    },
    async deleteFaq(key, lang) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "delete faq",
        key, lang
      );
      if (!ask.ok) throw new Error(ask.message);

      let oldIdx = this.faq.findIndex((faq)=> faq.key == key && faq.lang == lang);
      this.faq.splice(oldIdx, 1);

      return ask.faq;
    },

    //chat
    async deleteMessage(messageId) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "delete message",
        messageId
      );
      if (!ask.ok) throw new Error(ask.message);

      return ask.message;
    },

    //bans
    async getBansList() {
      let ask = await this.internal.admin.socket.emitWithAck(
        "get bans"
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask.bans;
    },
    async banUser(userId, banTo, banChat, banGame, banWithdraw) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "ban",
        userId, banTo, banChat, banGame, banWithdraw
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask.ban;
    },


    async getIpBansList() {
      let ask = await this.internal.admin.socket.emitWithAck(
        "get ip bans"
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask.bans;
    },
    async banIp(ip, text) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "ip ban",
        ip, text
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask.ban;
    },
    async unbanIp(ip) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "ip unban",
        ip
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask.ban;
    },

    async setIpBanDelay(delay) {
      let ask = await this.internal.admin.socket.emitWithAck(
        "set ipban delay",
        delay
      );
      if (!ask.ok) throw new Error(ask.message);
      return ask.data;
    },



    

    initAll(toast) {
      this.initUser();
      this.initSupport();
      this.$toast = toast;

      this.initSupportWorker();
      this.initAdmin();

      setInterval(() => {
        this.date = new Date()
      }, 1000);

      document.addEventListener("visibilitychange", ()=>{
        if (document.visibilityState === "visible") {
          console.log("visibility true");
          this.checkCoinStatus();
          this.checkCrashStatus();
          this.checkWheelStatus();
        }
      });

    },
  },
});
