<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import {prizeTypes} from "@/components/elements/battlepass/prizeTypes";
import couponItem from "@/components/elements/battlepass/couponItem.vue";

export default {
  name: "use_coupon_popup",
  data() {
    let interaction = useGameStore();
    return {
      interaction: interaction,
    };
  },
  props:{
    totalPrice: Number,
    caseName: String,
  },
  emits: ['close', 'coupon_selected'],
  components: {
    couponItem

  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    selectCoupon(coupon) {
      this.$emit("coupon_selected", coupon);
    }
  },

  computed: {
    unusedCoupons() {
      return this.interaction.case.coupons.filter(coupon => !coupon.used);
    },
  },

  emits: [
    "close"
  ],
  mounted() {
    this.interaction.setBodyScroll(false);
  },
  unmounted() {
    this.interaction.setBodyScroll(true);
  }
}
</script>


<template>
  <div class="popup-container" :class="{ noneAnimation: !interaction.settingsSite.animations }">
    <div class="popupBlock">
      <div class="popupContent">
        <div class="popupNameBlock">
          <div class="text">Выбор купона для кейса</div>
          <div class="caseName">«{{ caseName }}»</div>
        </div>
        <div class="selectCouponBlock">
          <couponItem v-for="coupon in unusedCoupons" :key="coupon.id" :coupon="coupon" :totalPrice="totalPrice" @select="selectCoupon(coupon)"/>
        </div>
      </div>
      <div class="popup-header-close" @click="closePopup()">
        <img src="/src/assets/img/battlepass/close.png" alt="close">
      </div>
    </div>
    <div class="popup-bg" @click="closePopup()"></div>
  </div>
</template>

<style scoped>

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  transition: all 0.25s ease;
}

.popupBlock {
  z-index: 99;
  width: 719px;
  height: fit-content;
  min-height: 500px;
  max-height: 90vh;
}

.popupContent {
  width: 100%;
  height: 100%;
  font-family: VelaSans;
  background-image: url(/src/assets/img/battlepass/bg.svg);
  justify-content: space-between;
  transition: all 0.25s ease;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 20px 0px 20px;
  border-radius: 20px;
  overflow: hidden;
  color: white;
}

.selectCouponBlock {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  height: 100%;
  max-height: 350px;
  padding: 20px 0px;
  overflow-y: scroll;
}

.popupNameBlock {

}

.text {
  font-size: 24px;
  font-weight: 600;
}

.caseName {
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 98;
  background: url(/src/assets/img/header/bg.svg);
  /* backdrop-filter: blur(20px); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.75;
  transition: all 0.25s ease;
}

.popup-header-close {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 1.93vw;
  height: 1.93vw;
  padding: 0.5vw;
  border-radius: 0.4vw;
  background: linear-gradient(-45deg, rgba(255, 73, 73, 0.5), rgba(255, 73, 73, 0.15));
  cursor: pointer;
  transition: all 0.25s ease;
  z-index: 1;
}

.popup-header-close img{
  width: 14px;
  height: 14px;
}

.popup-header-close:hover {
    background-color: #4b506d;
}










/* COUPON ITEM STYLES*/

@media (max-width: 1280px) {
  .popupBlock {
    min-height: 400px;
    max-width: calc(100% - 10vw);
  }

  .popup-header-close {
    right: 10px;
    top: 10px;
    width: 23px;
    height: 23px;
    padding: 3px;
    border-radius: 4px;
  }
}


@media (max-width: 768px) {
  .couponItem {
    width: calc(50% - 10px);
  }

}
@media (max-width: 640px) {

  .selectCouponBlock {
    flex-direction: column;
    align-items: center;
  }
  .couponItem {
    width: 220px;
  }

  .text {
    text-align: center;
    line-height: 1.2;
  }
}


@media (max-width: 480px) {

}
</style>
