<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import elements_header_right from "@/components/elements/elements_header_right.vue";
import elements_header_right_authorization from "@/components/elements/elements_header_right_authorization.vue";
import elements_live_drop from "@/components/elements/elements_live_drop.vue";
import settings from "@/components/popup/settings.vue";
export default {
    name: "window_header",
    data() {
        let interaction = useGameStore();
        return {
            interaction: interaction,
        }
    },
    components: {
        elements_header_right,
        elements_header_right_authorization,
        settings,
        elements_live_drop,
    },
    computed:{
        isInternal(){
            return this.$route.matched.some((route)=>{
                return route.name === 'internal'
            })
        },
    },
    watch: {
        'interaction.mobileMenuStatus'(newStatus) {
            this.interaction.setBodyScroll(!newStatus);
        }
    }
};
</script>


<template>
    <teleport to="body">
        <transition name="fadeMove" mode="out-in">
            <settings v-if="interaction.settingsStatus" />
        </transition>
    </teleport>
    <div class="header-container" :class="{ main: $route.name == 'main' }">
        <div class="header-left">
            <div class="header-block-logo" @click="$router.push({ name: 'main' })">
                <div class="header-logo">
                    <img src="../assets/img/header/logo.svg" alt="logo">
                </div>
            </div>
            <div class="header-block-link">
                <div class="header-link" @click="$router.push({ name: 'internal' })" :class="{ active: $route.name == 'internal' }" v-if="interaction.isAdmin && !isInternal">
                    АДМИНИСТРАЦИЯ
                </div>
                <div class="header-link" @click="$router.push({ name: 'battlepass' })" :class="{ active: $route.name == 'battlepass' }">
                    {{ $t('battlepass') }}
                </div>
                <div class="header-link" @click="$router.push({ name: 'daily' })" :class="{ active: $route.name == 'daily' }">
                    {{ $t('header.dailyBonuses') }}
                </div>
                <div class="header-link" @click="$router.push({ name: 'referral' })"
                    :class="{ active: $route.name == 'referral' }">
                    {{ $t('header.referral') }}
                </div>
                <div class="header-link" @click="$router.push({ name: 'support' })"
                    :class="{ active: $route.matched[this.$route.matched.length - 2]?.name == 'support' }">
                    {{ $t('header.support') }}
                </div>
                <div class="header-link" @click="$router.push({ name: 'fair_game' })"
                    :class="{ active: $route.name == 'fair_game' }">
                    {{ $t('header.fairGame') }}
                </div>
            </div>
        </div>
        <div class="header-left-mobile">
            <div class="header-mobile-block" :class="{ active: interaction.mobileMenuStatus }"
                @click="interaction.cnahgeMobileMenuStatus()">
                <div class="header-mobile-line"></div>
                <div class="header-mobile-line"></div>
            </div>
            <div class="header-mobile-main" :class="{ active: interaction.mobileMenuStatus }">
                <div class="mobile-link" @click="$router.push({ name: 'battlepass' }), interaction.mobileMenuStatus = false">
                    {{ $t('battlepass') }}</div>
                <div class="mobile-link" @click="$router.push({ name: 'daily' }), interaction.mobileMenuStatus = false">
                    {{ $t('header.dailyBonuses') }}</div>
                <div class="mobile-link" @click="$router.push({ name: 'referral' }), interaction.mobileMenuStatus = false">
                    {{ $t('header.referral') }}</div>
                <div class="mobile-link" @click="$router.push({ name: 'fair_game' }), interaction.mobileMenuStatus = false">
                    {{ $t('header.fairGame') }}</div>
                <div class="mobile-link" @click="$router.push({ name: 'support' }), interaction.mobileMenuStatus = false">
                    {{ $t('header.support') }}</div>
                <a href="/TermsOfUse.docx" class="mobile-link v2" @click="interaction.mobileMenuStatus = false">{{$t('footer.termsOfUse')}}</a>
                <a href="/PrivacyPolicy.docx" class="mobile-link v2" @click="interaction.mobileMenuStatus = false">{{$t('footer.policy')}}</a>
                <div class="mobile-icon-lnik">
                    <div class="icon-lnik">
                        <a href="https://instagram.com/_cssweets_csgo?igshid=NTc4MTIwNjQ2YQ==" target="_blank">
                            <img src="/menu/mobile_instagram.svg" alt="instagram" />
                        </a>
                    </div>
                    <div class="icon-lnik">
                        <a href="https://t.me/CSSWEETS1" target="_blank">
                            <img src="/menu/mobile_telegram.svg" alt="telegram" />
                        </a>
                    </div>
                    <div class="icon-lnik">
                        <a href="https://discord.gg/dKSkUeyVaJ" target="_blank">
                            <img src="/menu/mobile_discord.svg" alt="discord" />
                        </a>
                    </div>
                    <div class="icon-lnik">
                        <a href="https://vk.com/public221074435" target="_blank">
                            <img src="/menu/mobile_vk.svg" alt="vk" />
                        </a>
                    </div>
                </div>

                <div class="header-block-logo" @click="$router.push({ name: 'main' }), interaction.mobileMenuStatus = false">
                    <div class="header-logo">
                        <img src="/src/assets/img/header/logo.svg" alt="logo">
                    </div>
                </div>

            </div>
        </div>
        <transition name="fade" mode="out-in" appear>
            <elements_header_right v-if="interaction.authorization" />
            <elements_header_right_authorization v-else />
        </transition>
    </div>
    <transition name="fadeDropDawn" mode="out-in" appear :duration="{ enter: 500, leave: 250 }">
        <div class="screen-live-drop" v-if="$route.name == 'main'">
            <div class="game-modes-title">
                <div class="game-modes-img">
                    <img src="/src/assets/img/main/ak.svg" alt="ak">
                </div>
                <div class="game-modes-name live-drop-dot">
                    Live drop
                </div>
            </div>
            <elements_live_drop />
        </div>
    </transition>
</template>

<style scoped>






.fadeDropDawn-enter-active {
    transform: translate(0%, 0%);
    opacity: 1;
    transition: all 0.25s ease;
}

.fadeDropDawn-leave-active {
    transform: translate(0%, 0%);
    opacity: 0;
    transition: all 0.25s ease;
}

.fadeDropDawn-enter-from {
    transform: translate(0%, 0%);
    opacity: 1;
    transition: all 0.25s ease;
}

.fadeDropDawn-leave-to {
    transform: translate(0%, 0%);
    opacity: 1;
    transition: all 0.25s ease;
}







.fadeMove-enter-active {
    transform: translate(0%, 0%);
    opacity: 1;
    transition: all 0.25s ease;
}

.fadeMove-leave-active {
    transform: translate(0%, 0%);
    opacity: 0;
    transition: all 0.25s ease;
}

.fadeMove-enter-from {
    transform: translate(0%, 0%);
    opacity: 0;
    transition: all 0.25s ease;
}

.fadeMove-leave-to {
    transform: translate(0%, 0%);
    opacity: 0;
    transition: all 0.25s ease;
}



.fade-enter-active {
    transform: translate(0%, 0%);
    opacity: 1;
    transition: all 0.25s ease;
}

.fade-leave-active {
    transform: translate(0%, 0%);
    opacity: 0;
    transition: all 0.25s ease;
}

.fade-enter-from {
    transform: translate(0%, 0%);
    opacity: 0;
    transition: all 0.25s ease;
}

.fade-leave-to {
    transform: translate(0%, 0%);
    transition: all 0.25s ease;
}


.header-container.main {
    margin: 0 0 0px 0;
}

.header-container {
    width: 100%;
    height: 5vw;
    min-height: 2vw;
    margin: 0 0 2vw 0;
    justify-content: space-between;
    color: #FFFFFF;
    position: sticky;
    top: 0px;
    z-index: 5;
    background-color: #27293A;
    transition: all 0.25s ease;
}

.header-container,
.header-left,
.header-block-logo,
.header-block-link,
.header-right,
.header-title {
    flex-direction: row;
}

.header-left {
    min-width: 29vw;
}

.header-logo {
    width: 9.0vw;
    margin: 0 0.5vw 0 0;
    cursor: pointer;
}

.header-title {
    font-size: 1.2vw;
    font-weight: 500;
    cursor: pointer;
}

.header-title span {
    font-weight: 800;
}

.header-block-link {
    margin: 0 0 0 3vw;
    align-items: center;
}

.header-link.active {
    color: white;
    text-shadow: 0 0 0.2vw rgba(255, 255, 255, 0.7);
}

.header-link {
    text-transform: uppercase;
    margin: 0.7vw;
    transform: translate(0, 0.2vw);
    font-family: "VelaSans", sans-serif;
    color: #7B7FA3;
    font-size: 0.7vw;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.25s ease;
}

/* .header-link::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, -1px);
    border-radius: 50px;
    width: 0%;
    height: 2px;
    background-color: #5FE895;
    transition: all 0.25s ease;
}
.header-link:hover::after{
    width: 100%;
} */
.header-link:hover {
    color: white;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
}




.header-block-balance {
    flex-direction: row;
    background-color: #363A50;
    border-radius: 0.6vw;
    padding: 0.5vw 0.6vw;
}

.balance {
    color: #fff;
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 800;
    margin: 0 0.6vw 0 0;
    transform: translate(0px, 0.2vw);
}

.add-icon {
    width: 2.8vw;
    height: 2.8vw;
    padding: 0.5vw;
    border-radius: 0.5vw;
    background-color: #5FE895;
    transition: all 0.25s ease;
    cursor: pointer;
}

.add-icon:hover {
    background-color: #67ffa4;
}

.profile-img {
    border-radius: 0.6vw;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-block {
    margin: 0 0.5vw 0 -.7vw;
    border-radius: 0.7vw;
    width: 3vw;
    height: 3vw;
    border: 0.2vw solid #4F536D;
    background-color: #4F536D;
}

.profile-block-lvl {
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 2.4vw;
    width: 2.4vw;
    max-width: 2.4vw;
    transform: translate(15%, 50%);
    border-radius: 0.7vw;
    padding: 0px 0.4vw;
    background-color: #4F536D;
    color: #5FE895;
    font-family: "VelaSans", sans-serif;
    font-size: 0.6vw;
    font-weight: 800;
}

.profile-icon {
    margin: 0 0 0 0.7vw;
    border-radius: 0.5vw;
    width: 2.4vw;
    height: 2.4vw;
    padding: 0.6vw;
    background-color: #363A50;
    transition: all 0.25s ease;
    cursor: pointer;
}

.profile-icon:hover {
    background-color: #40445d;
}

.notifications-amount {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.9vw;
    height: 0.9vw;
    transform: translate(30%, -30%);
    border-radius: 50%;
    background-color: #5FE895;
    color: #1A6C3B;
    font-family: "VelaSans", sans-serif;
    font-size: 0.6vw;
    font-weight: 800;
}

.profile-icon.logout img {
    transform: translate(-0.1vw, 0);
}

.header-left-mobile {
    display: none;
}

.header-mobile-block.active .header-mobile-line:nth-child(2) {
    top: 50%;
    width: 100%;
    transform: rotate(-45deg);
}

.header-mobile-block.active .header-mobile-line:nth-child(1) {
    top: 50%;
    width: 100%;
    transform: rotate(45deg);
}

.header-mobile-block {
    width: 30px;
    height: 20px;
    justify-content: space-between;
    align-items: flex-start;
    transition: all 0.25s ease;
}

.header-mobile-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 50px;
    background-color: rgba(97, 102, 133, 1);
    transform-origin: 50% center;
    transition: all 0.25s ease;
}

.header-mobile-line:nth-child(2) {
    width: 70%;
    top: calc(50% - 0px);
}

.header-mobile-main.active {
    top: 60px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);
    background-color: #27293A;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.25s ease;
}

.header-mobile-main.active .mobile-link {
    transform: translate(0px, 0px);
    opacity: 1;
    pointer-events: auto;
}

.header-mobile-main.active .mobile-link:nth-child(1) {
    transition: all 0.25s 0.30s ease;
}

.header-mobile-main.active .mobile-link:nth-child(2) {
    transition: all 0.25s 0.35s ease;
}

.header-mobile-main.active .mobile-link:nth-child(3) {
    transition: all 0.25s 0.40s ease;
}

.header-mobile-main.active .mobile-link:nth-child(4) {
    transition: all 0.25s 0.45s ease;
}

.header-mobile-main.active .mobile-link:nth-child(5) {
    transition: all 0.25s 0.50s ease;
}

.mobile-link:nth-child(1) {
    transition: all 0.25s 0.25s ease;
}

.mobile-link:nth-child(2) {
    transition: all 0.25s 0.20s ease;
}

.mobile-link:nth-child(3) {
    transition: all 0.25s 0.15s ease;
}

.mobile-link:nth-child(4) {
    transition: all 0.25s 0.05s ease;
}

.mobile-link:nth-child(5) {
    transition: all 0.25s 0.0s ease;
}

.header-mobile-main {
    position: fixed;
    top: -100px;
    left: 0;
    width: 100vw;
    height: calc(0vh - 100px);
    z-index: 0;
    background-color: rgba(39, 41, 58, 1);
    opacity: 0;
    pointer-events: none;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 0 10px 20px;
    overflow-y: auto;
    transition: all 0.25s 0.5s ease;
}

.header-mobile-main.active .mobile-link.v2 {
    transition: all 0.25s 0.6s ease;
}

.mobile-link.v2 {
    margin: 5px 0 5px 0;
    color: #7E83A5;
    font-size: 14px;
    font-family: 'VelaSans', sans-serif;
    text-shadow: none;
    font-style: normal;
    font-weight: 500;
    line-height: 137.6%;
    letter-spacing: -0.28px;
}

.mobile-link:hover {
    text-shadow: 0px 0px 5px white;
}

.mobile-link {
    color: rgba(255, 255, 255, 1);
    text-transform: uppercase;
    font-family: "VelaSans", sans-serif;
    color: #DBE0FF;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 137.6%;
    letter-spacing: 0.72px;
    text-transform: uppercase;
    margin: 0 0 15px 0;
    text-shadow: 0px 0px 0px white;
    transform: translate(-250px, 0px);
    transition: all 0.5s, text-shadow 0.25s ease;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
}


.screen-live-drop {
    width: 100%;
    height: 4vw;
    margin: 0 0 2vw 0;
    flex-direction: row;
    transition: all 0.25s ease;
}

.game-modes-name.live-drop-dot::after {
    content: "";
    width: 0.35vw;
    height: 0.35vw;
    position: absolute;
    right: 0;
    transform: translate(0.6vw, 0.1vw);
    border-radius: 50%;
    background-color: #4DE388;
    box-shadow: 0 0 0.6vw rgba(77, 227, 136, 0.7);
}

.game-modes-title {
    width: fit-content;
    min-width: 10vw;
    margin: 0 0 0 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.game-modes-img {
    width: 1vw;
    height: 1vw;
    margin: 0 0.5vw 0 0;
}

.game-modes-name {
    color: #7B7FA3;
    font-family: "VelaSans", sans-serif;
    font-size: 1.3vw;
    font-weight: 600;
}

.game-modes-container {
    width: calc(100% + 0.8vw);
    transform: translate(-0.4vw, 0px);
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}


.header-mobile-main.active .mobile-hr {
    opacity: 1;
    transition: all 0.25s 0.5s ease;
}

.mobile-hr {
    width: 80vw;
    height: 1px;
    margin: 20px 0;
    color: rgba(126, 131, 165, 0.23);
    opacity: 0;
    transition: all 0.25s ease;
}

.header-mobile-main.active .mobile-icon-lnik {
    opacity: 1;
    transform: translateX(0%) scale(1);
    transition: all 0.25s 0.7s ease;
}

.mobile-icon-lnik {
    margin: 20px 0 50px 0;
    flex-direction: row;
    opacity: 0;
    transform: translateX(-100%) scale(0);
    transition: all 0.25s ease;
}

.header-mobile-main.active .icon-lnik {
    opacity: 1;
    transform: translateX(0%) scale(1);
    transition: all 0.25s 0.7s ease;
}

.icon-lnik {
    width: 30px;
    height: 30px;
    margin: 0 15px 0 0;
    opacity: 0;
    transform: translateX(-100%) scale(0);
    transition: all 0.25s ease;
}

.icon-lnik a{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width: 1640px) {}

@media (max-width: 1440px) {}

@media (max-width: 1280px) {}

@media (max-width: 1024px) {}

@media (max-width: 980px) {}

@media (max-width: 1024px) {
    .header-left {
        display: none;
    }

    .header-left-mobile {
        display: flex;
    }

    .header-container {
        height: 60px;
    }

    .header-logo {
        width: 245px;
        margin: 0 10px 0 0;
        cursor: pointer;
    }

    .header-title {
        font-size: 24px;
        font-weight: 500;
        cursor: pointer;
    }

    .header-title span {
        font-weight: 800;
    }

    .game-modes-title {
        display: none;
    }

    .screen-live-drop {
        height: 70px;
    }




    .header-mobile-main.active .header-block-logo {
        transform: translateX(0%) scale(1);
        opacity: 1;
        transition: all 0.25s 0.9s ease;
    }

    .header-block-logo {
        opacity: 0;
        transform: translateX(-100%) scale(0);
        transition: all 0.25s ease;
    }

    .header-container.main {
        margin: 0 0 10px 0;
    }
}

@media (max-width: 640px) {}

@media (max-width: 480px) {}

@media (max-width: 420px) {}

@media (max-width: 380px) {}</style>

