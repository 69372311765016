<script>
import { useGameStore } from '@/stores/interface-interaction.js';

export default {
  name: "coupon_item",

  data() {
    let interaction = useGameStore();
    return {
      interaction: interaction,
    };
  },
  props: {
    totalPrice: Number,
    coupon: {
      type: Object,
      required: true,
    }
  },
  emits:['select'],
  components: {
  },
  methods: {
    select(){
      this.$emit('select');
    }
  },
  computed: {

    isAllowed(){
      return this.totalPrice <= this.coupon.maxPrice;
    },
    sumWithCoupon(){
      const discount = Number(this.coupon.discount);
      const discountSum = this.totalPrice / 100 * discount;
      return +(this.totalPrice - discountSum).toFixed(2);
    },

  }

};
</script>


<template>
  <div class="couponItem" :class="{allowed: isAllowed}">
    <div class="title"> Купон до {{ coupon.maxPrice }} монет</div>
    <div class="description">{{ coupon.discount }} %</div>
    <div class="button" @click="select" :class="{ buttonIsNotAllowed : !isAllowed }">
      <div class="buttonText">Применить</div>
      <div class="buttonPrice">
        <div class="icon">
          <img src="">
        </div>
        <div class="price">{{ sumWithCoupon.toFixed(2) }}</div>
        <div class="priceDeleted">{{ totalPrice }}</div>
      </div>
    </div>
  </div>

</template>

<style scoped>

.couponItem {
  width: calc(33% - 10px);
  height: 150px;
  border-radius: 20px;
  background: linear-gradient(45deg, rgba(47, 49, 69, 1), rgba(47, 49, 69, 1));
}

.title {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.description {
  font-size: 24px;
  font-weight: 800;
}

.button {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 30px;
  background: rgba(95, 232, 149, 0.1);
  border: 1px dashed #5FE895;
  padding: 5px 10px;
  gap: 10px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.15s;
}

.buttonIsNotAllowed{
  background: linear-gradient(-45deg, rgba(255, 73, 73, 0.5), rgba(255, 73, 73, 0.15));
  pointer-events: none;
}

.button:hover {
  background: rgba(95, 232, 149, 0.3);
}

.buttonPrice {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 0;
}

.allowecCoupon{
  background: linear-gradient(-45deg, rgba(255, 73, 73, 0.5), rgba(255, 73, 73, 0.15));
  pointer-events: none;
}

.icon {
  width: 15px;
  height: 15px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  margin: 0px 5px 0px 0px;
  background-image: url(/game/case/coin.png);
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}

.price {
  margin: 0px 5px 0px 0px;
  font-weight: 800;
}

.priceDeleted {
  width: fit-content;
}

.priceDeleted::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: white;
  transform: rotate(-10deg);
}

</style>
