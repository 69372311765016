<script>
import elements_chat from "@/components/elements/elements_chat.vue";
import elements_chat_open from "@/components/elements/elements_chat_open.vue";
import elements_inventory from "@/components/elements/elements_inventory.vue";
import { useGameStore } from '@/stores/interface-interaction.js';
import component_shop from "@/components/component_shop.vue";

export default {
  name: "component_chat",
  data() {
    let interaction = useGameStore();
    return {
      interaction: interaction,
    };
  },
  components: {
    elements_chat,
    elements_inventory,
    elements_chat_open,
    component_shop,
  },
};
</script>

<template>
  <transition name="hideChatAnimation" mode="out-in" appear>
    <div class="chat-container" v-if="!interaction.chatStatus" :class="{ main: $route.name == 'main' }">
      <transition name="open" mode="out-in">
        <component_shop v-if="interaction.shopStatus == true" />
      </transition>
      <div class="chat-top-block">
        <div class="chat-change-block">
          <div class="chat-text chat" @click="interaction.currentTab = 'elements_chat'"
            :class="{ active: interaction.currentTab == 'elements_chat' }">
            {{$t('chat.chat')}}
          </div>
          <div class="chat-text inventory" @click="interaction.currentTab = 'elements_inventory'"
            :class="{ active: interaction.currentTab == 'elements_inventory' }">
            {{$t('inventory.inventory')}}
          </div>
        </div>
        <div class="chat-hide" @click="interaction.toggleChatStatus()">
          <img src="/src/assets/img/chat/arrowGrey.svg" alt="hide">
        </div>
        <div class="chat-hide mobile" @click="interaction.toggleChatStatus()">
          <img src="/src/assets/img/chat/arrowGrey.svg" alt="hide">
        </div>
      </div>
      <div class="chat-container-elements">
        <transition name="fade" mode="out-in">
          <component :is="interaction.currentTab"></component>
        </transition>
      </div>
    </div>
    <elements_chat_open v-else />
  </transition>
</template>

<style scoped>
.hideChatAnimation-enter-active {
  opacity: 0;
  transition: all 0.25s ease;
}

.hideChatAnimation-leave-active {
  opacity: 0;
  transition: all 0.25s ease;
}

.hideChatAnimation-enter-from {
  opacity: 0;
  transition: all 0.25s ease;
}

.hideChatAnimation-leave-to {
  opacity: 0;
  transition: all 0.25s ease;
}







.open-enter-active {
  opacity: 1;
  transition: all 0.25s ease;
}

.open-leave-active {
  transform: translateX(-1vw);
  opacity: 0;
  transition: all 0.25s ease;
}

.open-enter-from {
  transform: translateX(-1vw);
  opacity: 0;
  transition: all 0.25s ease;
}

.open-leave-to {
  transform: translateX(-1vw);
  opacity: 0;
  transition: all 0.25s ease;
}




.fade-enter-active {
  transform: translate(0%, 0%);
  opacity: 1;
  transition: all 0.25s ease;
}

.fade-leave-active {
  transform: translate(50%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-enter-from {
  transform: translate(10%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-leave-to {
  transform: translate(50%, 0%);
  transition: all 0.25s ease;
}

.chat-container-elements {
  width: 100%;
  height: 100%;
  overflow: hidden;
}


















.chat-container {
  width: 19vw;
  min-width: 19vw;
  min-height: 40vw;
  height: 40vw;
  background-color: #363A50;
  box-shadow: 0 0 0.5vw rgba(0, 0, 0, 0.2);
  border-radius: 1.2vw;
  justify-content: space-between;
  padding: 0.8vw 0 1vw 0;
  pointer-events: auto;
  position: sticky;
  top: 7vw;
  z-index: 2;
  transition: all 0.25s ease;
}

.chat-top-block {
  width: calc(100% - 2vw);
  height: 2.2vw;
  min-height: 2.2vw;
  margin: 0 0 0.8vw 0;
  justify-content: space-between;
  flex-direction: row;
}

.chat-change-block {
  flex-direction: row;
}

.chat-text {
  text-transform: uppercase;
  margin: 0 0.5vw 0 0;
  color: #FFFFFF;
  font-family: "VelaSans", sans-serif;
  font-size: 0.9vw;
  font-weight: 700;
  cursor: pointer;
  background-color: transparent;
  border-radius: 0.4vw;
  padding: 0.3vw 0.7vw;
  transition: all 0.25s ease;
}

.chat-text:hover {
  background-color: #5fe89611;
}

.chat-text.active {
  color: #5FE895;
  background-color: #5FE8951F;
  border-radius: 0.5vw;
  padding: 0.3vw 0.7vw;
}

.chat-hide {
  width: 2vw;
  height: 2vw;
  padding: 0.4vw;
  border-radius: 0.4vw;
  background-color: #444861;
  transition: all 0.25s ease;
  cursor: pointer;
}

.chat-hide:hover {
  background-color: #40445d;
}

.chat-hide img {
  transition: all 0.25s ease;
}

.chat-hide:hover img {
  transform: translate(0.2vw, 0);
}


.chat-text:active {
  background-color: #5fe89663;
}

.chat-hide.mobile {
  display: none;
}


@media (max-width: 1640px) {}

/* @media (max-width: 1440px) {
  .chat-container.main {
    top: calc(7vw + 4vw);
  }

  .chat-container {
    position: fixed;
    top: 7vw;
    right: 30px;
    min-height: 350px;
  }
} */

/* @media (max-width: 1280px) {
  .chat-container {
    right: 20px;
  }
} */

@media (max-width: 1024px) {
  .chat-hide {
    display: none;
  }

  .chat-hide.mobile {
    display: flex;
  }
}

@media (max-width: 980px) {}

@media (max-width: 1024px) {
  .chat-container {
    position: fixed;
    top: 0;
    left: 0;
    right: unset;
    width: 100vw;
    height: 100vh;
    padding: 10px;
    z-index: 6;
    border-radius: 0;
  }

  .chat-container.main {
    top: 0px;
  }

  .chat-top-block {
    width: 100%;
    height: 70px;
    margin: 0;
  }

  .chat-text {
    margin: 0 20px 0 0;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 137.6%;
    letter-spacing: -0.72px;
    border-radius: 12px;
    padding: 10px 15px;
  }

  .chat-text.active {
    border-radius: 12px;
    padding: 10px 15px;
  }

  .chat-text:hover {
    border-radius: 12px;
    padding: 10px 15px;
  }

  .chat-hide {
    width: 44px;
    height: 44px;
    padding: 10px;
    border-radius: 12px;
  }


}

@media (max-width: 640px) {}

@media (max-width: 480px) {}

@media (max-width: 420px) {}

@media (max-width: 380px) {}
</style>
