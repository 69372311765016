<script>
export default {
  name: "elements_game_wheel_hover",
  props:{
    item: Object,
    title: String,
    description: String,
  },
  data() {
    return{

    }
  },
  components: {

  },
  computed:{

  },
  methods: {

  },
  mounted() {



    let itemHover = this.$refs.itemHover;
    let item = this.$refs.item;

    let bindPopupOptions = {
        offsetX: 10,
        offsetY: 30,
        offsetXInvert: false,
        offsetYInvert: false,
    }



    item.onpointermove = (e) => {

      let hoverRect = itemHover.getBoundingClientRect();

      let needPositionX = e.clientX + (bindPopupOptions.offsetXInvert ? -hoverRect.width + bindPopupOptions.offsetX : bindPopupOptions.offsetX);
      let needPositionY = e.clientY + (bindPopupOptions.offsetYInvert ? -hoverRect.height + bindPopupOptions.offsetY : bindPopupOptions.offsetY);

      let screenWidth = document.documentElement.clientWidth;
      let screenHeight = document.documentElement.clientHeight;

      if (needPositionX + hoverRect.width > screenWidth) {
        needPositionX = screenWidth - hoverRect.width;
      }
      if (needPositionX < 0) {
        needPositionX = 0;
      }

      if (needPositionY + hoverRect.height > screenHeight) {
        needPositionY = screenHeight - hoverRect.height;
      }
      if (needPositionY < 0) {
        needPositionY = 0;
      }


      let percPositionX = `${needPositionX / screenWidth * 100}%`;
      let percPositionY = `${needPositionY / screenHeight * 100}%`;


      itemHover.style.left = percPositionX;
      itemHover.style.top = percPositionY;
    };

    item.onpointerenter = (e) => {
      itemHover.style.opacity = 1;
    };

    item.onpointerleave = (e) => {
      itemHover.style.opacity = 0;
    };


    document.addEventListener("scroll", ()=>{itemHover.style.opacity = 0;});












    
    // let itemHover = this.$refs.itemHover;
    // let item = this.$refs.item;

    // function move (e){
    //     let x = e.clientX;
    //     let y = e.clientY;

    //     itemHover.style.left = `${x + 15}px`;
    //     itemHover.style.top = `${y + 15}px`;
    //     itemHover.style.opacity = 1;
    // }
    // function out (e){
    //   itemHover.style.opacity = 0;
    // }

    // item.addEventListener ("mousemove", move);
    // item.addEventListener ("mouseout", out);
  }
};
</script>


<template>
  <div class="block-mini-wheel-info" ref="item">
      <img src="/game/wheel/info.svg" alt="i"/>
      <teleport to="body">
          <transition name="fadeHoverInfo" mode="out-in">
              <div class="wheel-info-popup-block" ref="itemHover">
                <span> 
                  {{ title }}
                </span>
                <span>
                  {{ description }}
                </span>
            </div>
          </transition>
      </teleport>
    </div>
</template>
<style scoped>
.fadeHoverInfo-enter-active {
  transform: translate(0%, 0%);
  opacity: 1;
  transition: all 0.25s ease;
}

.fadeHoverInfo-leave-active {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fadeHoverInfo-enter-from {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fadeHoverInfo-leave-to {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}


.block-mini-wheel-info{
    width: 1.0vw;
    height: 1.0vw;
    margin: 0 0 0 0.4vw;
    background-color: rgba(92, 96, 126, 1);
    border-radius: 50%;
    cursor: pointer;
}
.block-mini-wheel-info img{
    width: 0.7vw;
    height: 0.7vw;
}

.wheel-info-popup-block{
    position: fixed;
    width: fit-content;
    height: fit-content;
    background: #363A50;
    border: 0.15vw solid #494D65;
    border-radius: 0.5vw;
    padding: 0.7vw;
    opacity: 0;
    z-index: 9999;
    pointer-events: none;
    transition: all 0.25s ease;
}
.wheel-info-popup-block span:nth-child(1){
    display: flex;
    width: 100%;
    margin: 0 0 0.4vw 0;
    color: rgba(255, 255, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 600;
    flex-direction: row;
    justify-content: flex-start;
}
.wheel-info-popup-block span:nth-child(2){
    display: flex;
    max-width: 11.5vw;
    height: fit-content;
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.6vw;
    font-weight: 400;
    letter-spacing: 0.08em;
}


@media (max-width: 1024px) {

  .block-mini-wheel-info{
        width: 18px;
        height: 18px;
        margin: 0 0 0 7px;
  }
  .block-mini-wheel-info img{
      width: 13px;
      height: 13px;
  }


    .wheel-info-popup-block{
      border: 1px solid #494D65;
      border-radius: 10px;
      padding: 12px;
  }
  .wheel-info-popup-block span:nth-child(1){
      margin: 0 0 8px 0;
      font-size: 16px;
  }
  .wheel-info-popup-block span:nth-child(2){
      max-width: 300px;
      height: fit-content;
      font-size: 12px;
  }

}
</style>
