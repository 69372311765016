<script>

export default {
  name: "elements_gameIcon",
  props: {
    name: String,
    img: String,
    imgActive: String,
    isActive: Boolean,
    battlepass: Boolean
  },
  data() {
    return{

    }
  },
  components: {

  },
  methods: {

  },
  mounted() {

  }
};
</script>


<template>
    <div class="container-icon" :class="{ active: isActive }">
        <div class="block-icon" :class="{ battlepass: battlepass }">
            <img :src="`${isActive ? imgActive : img}`" alt="">
        </div>
        <div class="block-name">
            {{ name }}
        </div>
    </div>
</template>

<style scoped>
.container-icon{
    width: 100%;
    height: fit-content;
    padding: 0.7vw 0 0.7vw 0;
    margin: 0 0 0 0;
    cursor: pointer;
    transition: all 0.25s ease;
}
.container-icon::after{
    content: "";
    position: absolute;
    left: 0;
    height: 0%;
    width: 0.2vw;
    border-top-right-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
    background-color: #5FE895;
    transition: all 0.35s ease;
}
.container-icon.active .block-icon{
    filter: drop-shadow(0 0 5px #5FE895);
}
.container-icon.active::after{
    height: 90%;
}
.block-icon{
    width: 1.2vw;
    height: 1.2vw;
    transition: all 0.25s ease;
}
.block-icon.battlepass {
    width: 1.6vw;
}
.container-icon.active .block-name{
    color: #FFF;
    font-weight: 600;
}
.block-name{
    color: #616685;
    font-family: "VelaSans", sans-serif;
    font-size: 1vw;
    font-weight: 800;
}
.container-icon:hover::after{
    height: 90%;
}



</style>
