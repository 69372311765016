import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";


import toaster from "./toaster/index";
import "./assets/main.css";

import cronLight from '@vue-js-cron/light'
import '@vue-js-cron/light/dist/light.css'



import router from './router.js';
import i18n from './i18n.js';
import VueYandexMetrika from 'vue-yandex-metrika'      
import { createHead, VueHeadMixin } from '@unhead/vue'

import * as Sentry from "@sentry/vue";
 

window.global = window;
const pinia = createPinia();
const app = createApp(App);

// Sentry.init({
//     app,
//     dsn: "https://99df049ce98b9cf762a5960f2e267ad9@o4504537608290304.ingest.sentry.io/4506155838930944",
//     integrations: [
//       new Sentry.BrowserTracing({
//         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//         // tracePropagationTargets: ["localhost", /^https:\/\/cssweets\.org\/api/],
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        
//       }),
//       new Sentry.Replay(),
//     ],
//     denyUrls: ["localhost"],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });


app.use(i18n);
app.use(pinia);
app.use(router);

app.use(toaster);
app.use(cronLight);


app.use(createHead());
app.mixin(VueHeadMixin);

app.use(VueYandexMetrika, {
    id: "94811830",
    router: router,
    env: process.env.NODE_ENV,
    options: { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true },
})






app.mount("#app");
