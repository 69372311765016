<script>
import { useGameStore } from '@/stores/interface-interaction.js';

import component_menu from "@/components/component_menu.vue";
import component_chat from "@/components/component_chat.vue";
import component_footer from "@/components/component_footer.vue";
import elements_case_item from "@/components/elements/cases/elements_case_item.vue";
import useCouponPopup from "@/components/popup/useCoupon_popup.vue";

export default {
  name: "block_cases_open",

  data() {
    let interaction = useGameStore();
    return {
      interaction: interaction,
      selected: false,
      openCount: 1,
      openCasesResult: [],
      couponPopupActive: false,
      showElementsCaseItem: true
    };
  },
  components: {
    component_menu,
    component_chat,
    component_footer,
    elements_case_item,
    useCouponPopup,
  },
  methods: {

    changeCount(i){
      this.openCount = i
      if (this.openCasesResult.length > 0){
        this.openCasesResult = []
        this.showElementsCaseItem = true
      }
    },

    async openCases(couponId = undefined) {
      try {
        this.showElementsCaseItem = false;
        const openCaseResult = await this.interaction.openCases(this.caseToOpen.id, this.openCount, couponId);
        this.openCasesResult = openCaseResult
        this.$toast.success("Кейс открыт");
        if(couponId){
          this.couponPopupActive = false;
        }
      } catch (e) {

        console.log(e)
        this.$toast.error(this.$t(e.message))
      }
    },

    back(){
      this.$router.push({name:'cases'})
    },

  },
  computed:{

    caseWidth() {
      const maxOpenCount = 5; // Maximum value for openCount
      const clampedOpenCount = Math.min(this.openCount, maxOpenCount); // Limit openCount to maximum value

      return Math.min((100 / clampedOpenCount) - 1);
    },

    totalPrice(){
      const result = this.caseToOpen?.price * this.openCount
      return result
    },

    caseName(){
      return this.caseToOpen?.caseName
    },
    
    caseItems(){
      return this.caseToOpen?.items ?? [];
    },
    
    caseToOpen(){
      const caseId = this.$route.query?.caseId
      const cases = this.interaction.case.cases
      const findCase = cases.find((caseitem)=> {
        return caseitem.id == caseId
      })
      return findCase
    }
  },

  watch:{
    'openCount'() {

    },
  },

  mounted() {

  }
};
</script>


<template>
  <div class="wrapper">
    <component_menu/>
    <div class="screen-main-cases" :class="{active: interaction.chatStatus}">
      <div class="nameGame-block">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="8" stroke="#5FE895" stroke-width="2"/>
        </svg>
        Cases
      </div>
      <div class="case_line">
        <div class="back">
          <div class="buttonCaseBox">
            <div class="back_icon" @click="back()">
              <div class="case_back"></div>
            </div>
            <div class="checkboxAnimationCaseBox">
              <div class="checkboxAnimationText">Анимации выкл</div>
              <label class="toggle">
                <input class="toggle-checkbox" type="checkbox" v-model="allowedOnly">
                <div class="toggle-switch"></div>
                <div class="tooltip-text">
                  <div class="tooltip_title">Анимация прокрутки временно недоступна</div>
                  <div class="tooltip_disc">Мы до сих пор работаем над улучшением режима и совсем скоро добавим анимацию прокрутки ленты.</div>
                </div>
              </label>
            </div>
          </div>

        </div>

        <div class="count_box">
          <div class="case_box" v-for="i in openCount" :key="i" :style="{'--width-case': caseWidth + '%'}">
            <template v-if="showElementsCaseItem">
              <elements_case_item v-for="item in caseItems.map((item)=>item.template)" :key="item.id" :caseItem="item" />
            </template>
            <elements_case_item :caseItem="openCasesResult[i-1].drop" v-if="openCasesResult[i-1]"/>
            <div class="lineWinItem">
              <img src="/game/case/line_center.svg" alt="">
            </div>
          </div>
        </div>


        <div class="openCaseBox">
          <div class="button_box">
            <button class="button" @click="openCases()">
              <div class="buttonText">Открыть</div>
              <div class="iconPrice">
                <div class="icon">
                  <img src="/game/battlepass/coin.png">
                </div>
                <div class="price"> {{ totalPrice }} </div>
              </div>
            </button>
            <button class="button coupon" @click="couponPopupActive = !couponPopupActive" >Открыть за купоны</button>
          </div>
          <div class="case_count">
            <div class="case_title">Открыть одновременно</div>
            <div class="case_value-box">
              <div class="case_value" v-for="i in 10" :key="i" @click.stop="changeCount(i)" :class="{active: openCount == i}">x{{ i }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="case_items_container">
        <div class="case_items_title">Содержимое кейса</div>
        <div class="case_items">
          <elements_case_item bottomContainer v-for="item in caseItems.map((item)=>item.template)" :key="item.id" :caseItem="item" />
        </div>
      </div>

      <Teleport to="body">
        <Transition name="fadeMove" mode="out-in">
          <useCouponPopup :totalPrice="totalPrice" :caseName="caseName" v-if="couponPopupActive" @close="couponPopupActive = !couponPopupActive" @coupon_selected="(coupon)=>openCases(coupon.id)"/>
        </Transition>
      </Teleport>

      <component_footer/>
    </div>
    <component_chat/>
  </div>
</template>

<style scoped>
.wrapper{
  width: 100%;
  height: fit-content;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.2s ease;
}

.screen-main-cases{
  color: #FFF;
  font-family: "VelaSans", sans-serif;
  font-size: 2.50vw;
  font-weight: 700;
}
.screen-main-cases.active{
  width: calc((100% - 6vw - 0px - 1.5vw));
  min-width: calc((100% - 6vw - 0px - 1.5vw));
  margin: 0 0 0 1.5vw;
  transition: background-color 0.2s ease;
}

.case_line{
  min-height: 27.34vw;
  height: 100%;
  width: 100%;
  border-radius: 0.52vw;
  justify-content: space-between;
  color: #ffffff;
}

.back{
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.04vw 0 0 1.04vw;
}

.case_title{
  width: 20%;
  font-size: 14px;
  font-weight: 500;
  font-family: 'VelaSans', sans-serif;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: calc(14px / 100 * 3);
  color: rgba(219, 224, 255, 0.6)
}

.case_count{
  flex-direction: row;
  gap: 0.52vw;
}

.case_items_title{
  font-size: 1.25vw;
  font-weight: 600;
  font-family: "VelaSans", sans-serif;
  color: rgba(219, 224, 255, 1);
  letter-spacing: calc(1.25vw / 100 * 2);
  margin: 0 0 40px 0;
}

.icon{
  width: 1.2vw;
  height: 0;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.icon>img{
  width: 1.2vw;
  height: 1.2vw;
  position: absolute;
}

.iconPrice{
  flex-direction: row;
  gap: 6px;
}

.back_icon{
  background: linear-gradient(45deg, rgba(69, 75, 109, 1), rgba(80, 90, 147, 1));
  padding: 8px;
  border-radius: 5px;
  justify-content: flex-start;
  width: fit-content;
}

.case_back{
  width: 15px;
  height: 15px;
  background-image: url(/game/case/arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.case_box{
  height: 15vw;
  flex-direction: row;
  background: linear-gradient(45deg, rgba(47, 49, 69, 1), rgba(47, 49, 69, 1));
  border-radius: 25px;
  overflow: hidden;
  gap: 10px;
  width: var(--width-case);
}

.case_value-box{
  flex-direction: row;
  gap: 0.21vw;
  font-size: 0.73vw;
}

.case_value{
  width: 52px;
  height: 52px;
  font-size: 0.73vw;
  font-weight: 700;
  font-family: "VelaSans", sans-serif;
  color: rgba(255, 255, 255, 0.4);
  background: linear-gradient(45deg, rgba(69, 75, 109, 0.3), rgba(80, 90, 147, 0.3));
  padding: 0.26vw 0.52vw;
  border-radius: 0.42vw;
  transition: background 0.2s ease;
  cursor: pointer;
}

.case_value:hover{
  background: linear-gradient(45deg, rgba(69, 75, 109, 1), rgba(80, 90, 147, 1));
  color: rgba(255, 255, 255, 1);
}

.case_value.active{
  background: linear-gradient(45deg, rgba(69, 75, 109, 1), rgba(80, 90, 147, 1));
  color: rgba(255, 255, 255, 1);
}

.openCaseBox{
  width: 100%;
  flex-direction: row;
  gap: 0.52vw;
  background-image: url(/game/case/bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: space-between;
  background-position: center;
  padding: 30px;
  border-radius: 25px;
  margin: 0 0 80px 0;
}

.button_box{
  flex-direction: row;
  gap: 15px;
}

.buttonCaseBox{
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.checkboxAnimationCaseBox{
  flex-direction: row;
  gap: 18px;
}

.checkboxAnimationText{
  width: 100px;
  font-size: 15px;
  font-weight: 500;
  font-family: "VelaSans", sans-serif;
  text-transform: uppercase;
  color: rgba(174, 186, 255, 0.5);
  text-align: end;
}

.tooltip-text {
  font-family: 'VelaSans', sans-serif;
  background-color: rgba(54, 58, 80, 1);
  border: 2px solid rgba(73, 77, 101, 1);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-transform: none;
  padding: 20px 18px 18px 22px;
  border-radius: 10px;
  width: 256px;
  position: absolute;
  top: 37px;
  left: 0;
  z-index: 50;
  transform: translateX(-86%);
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.tooltip_title{
  text-transform: uppercase;
  font-family: 'VelaSans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 20px 0;
  color: rgba(219, 224, 255, 1);
}

.tooltip_disc{
  font-family: 'VelaSans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: rgba(219, 224, 255, 1);
}

.checkboxAnimationCaseBox:hover .tooltip-text {
  display: flex;
  transition: opacity 0.2s ease;
  opacity: 1;
}

.toggle {
  display: flex;
  cursor: pointer;
  pointer-events: none;
}

.toggle-switch {
  display: inline-block;
  background: linear-gradient(45deg, rgba(69, 75, 109, 1), rgba(80, 90, 147, 1));
  border-radius: 16px;
  width: 36px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.toggle-switch:before, .toggle-switch:after {
  content: "";
}
.toggle-switch:before {
  display: block;
  background-color: rgba(45, 51, 83, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 3px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
  background-color: rgba(45, 51, 83, 1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.toggle-checkbox:checked + .toggle-switch {
  background: linear-gradient(45deg, rgba(69, 75, 109, 0.5), rgba(80, 90, 147, 0.5));
}
.toggle-checkbox:checked + .toggle-switch:before {
  left: 17px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.button{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 60px;
  border: none;
  border-radius: 20px;
  padding: 23px 25px 23px 30px;
  background-color: rgba(77, 197, 125, 1);
  cursor: pointer;
  font-family: 'VelaSans', sans-serif;
  font-size: 18px;
  font-weight: 800;
  color: #fff;
  align-items: center;
  text-transform: uppercase;
  transition: all 0.2s ease;
}

.button:hover{
  background-color: rgba(77, 197, 125, 0.5);
}

.lineWinItem{
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  width: 100%;
}

.lineWinItem>img{
  width: 450px;
}

.buttonText{
  font-family: 'VelaSans', sans-serif;
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: calc(18px /100 * 3);
}

.button.coupon{
  padding: 23px 25px;
}

.price{
  font-family: 'VelaSans', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.case_items_container{
  width: 100%;
  margin: 0 0 50px 0;
}

.case_items{
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.screen-main-cases{
  width: calc((100% - 6vw - 19vw - 3vw));
  min-width: calc((100% - 6vw - 19vw - 3vw));
  margin: 0 1.5vw 0 1.5vw;
  height: fit-content;
  justify-content: flex-start;
  transition: all 0.2s ease;
}

.count_box{
  flex-direction: row;
  flex-wrap: wrap-reverse;
  gap: 1.04vw;
  width: 100%;
  margin: 1.30vw 0;
}

.nameGame-block{
  width: 100%;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  color: #FFF;
  text-align: center;
  font-family: "VelaSans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 137.6%;
  letter-spacing: -0.72px;
  margin: 0 0 0.52vw 0;
}
.nameGame-block svg{
  margin: 0 8px 0 0;
}

/* @media (max-width: 1440px) {

.screen-main-cases{
  width: calc((100% - 6vw - 0px - 1.5vw));
  min-width: calc((100% - 6vw - 0px - 1.5vw));
  margin: 0 0 0 1.5vw;
  transition: all 0.2s ease;
}
} */
@media (max-width: 1680px) {
  .iconPrice {
    gap: 0.3vw;
  }
  .price {
    font-size: 1vw;
  }

  .case_box {
    height: 18vw;
    padding: 20px;
  }

  .button {
    font-size: 0.94vw;
    gap: 3.1vw;
    padding: 1.2vw 1.3vw 1.2vw 1.56vw;
    border-radius: 1vw;
  }

  .button.coupon {
    padding: 1.2vw 1.3vw;
  }

  .buttonText {
    font-size: 0.94vw;
  }

  .case_title {
    font-size: 0.73vw;
  }

  .case_value {
    width: 2.7vw;
    height: 2.7vw;
  }
  
}

@media (max-width: 1440px) {

  .case_box {
    padding: 0;
    height: auto;
    background: none;
  }
  
}

@media (max-width: 1024px) {
  .screen-main-cases, .screen-main-cases.active{
    width: calc((100% - 0px - 0px - 0vw));
    min-width: calc((100% - 0px - 0px - 0vw));
    margin: 0 0 0 0vw;
    transition: all 0.2s ease;
  }
  .nameGame-block{
    display: flex;
  }
  .case_items_container{
    margin: 0 0 120px 0;
  }

  .case_items_title {
    font-size: 17px;
  }

  .openCaseBox {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .case_count {
    flex-direction: column;
  }
  .case_title {
    width: 100%;
    font-size: 13px;
  }

  .case_value {
    width: 35px;
    height: 35px;
    font-size: 11px;
  }

  .button, .buttonText {
    font-size: 14px;
  }

  .button {
    padding: 10px 15px
  }

  .button.coupon {
    padding: 10px 15px;
  }

  .price {
    font-size: 15px;
  }

  .icon {
    width: 12px;
  }

  .icon img {
    width: 12px;
    height: 12px;
  }
}

@media (max-width: 640px) {
  .case_box {
    width: calc(33.333% - 1.04vw);
  }
}

@media (max-width: 480px) {

  .count_box {
    gap: 10px;
    margin: 10px 0px;
  }

  .case_box {
    width: calc(50% - 10px);
  }

  .openCaseBox {
    padding: 5vw;
    width: calc(100% - 0vw);
  }

  .case_count {
    width: calc(100% + 0vw);
  }

  .case_value-box {
    justify-content: flex-start;
    width: calc(100% + 0vw);
    overflow-x: auto;
  }

  .case_value {
    min-width: 35px;
  }

  .button_box{
    flex-direction: column;
    gap: 8px;
  }

  .button.coupon , .button {
    padding: 10px 15px;
    width: 195px;
  }

  .button.coupon {
    justify-content: center;
    align-items: center;
  }
}
</style>
