<script>
import { useGameStore } from '@/stores/interface-interaction.js';

import component_menu from "@/components/component_menu.vue";
import component_chat from "@/components/component_chat.vue";
import component_footer from "@/components/component_footer.vue";
import {parseItemData} from "@/scripts/parseItemData";

export default {
  name: "block_cases_open",

  data() {
    let interaction = useGameStore();
    return {
      interaction: interaction,
    };
  },

  props: {
    caseItem: {
      required: true,
      type: Object,
    },
    bottomContainer: Boolean
  },

  components: {

  },
  methods: {

  },
  computed:{
    itemData(){
      return parseItemData(this.caseItem, this.interaction)
    },

    rarityColorSet() {

      let color = this.itemData.color;
      let opacityColor = color + '99';
      return { color, opacityColor };
    },

    itemIcon() {
      return this.itemData.img + (this.iconErr ? '' : '/100fx100f');
    },

  },
  mounted() {

  }
};
</script>


<template>
  <div class="item" :class="{bottom : bottomContainer}">
    <div class="item_box">
      <div class="item_state">{{ itemData?.statusShort }}</div>
      <div class="item_price">{{ itemData?.price }}</div>
    </div>
    <div class="caseimg">
      <img class="case_img" :src="itemData?.img"/>
    </div>
    <div class="item_info">
      <marquee class="item_name">{{ itemData?.secondName }}</marquee>
      <marquee class="item_type">{{ itemData?.mainName }}</marquee>
    </div>
    <div class="item-rarity-line"
         :style="{ '--rarity-color': rarityColorSet.color, '--rarity-color-shadow': rarityColorSet.opacityColor }"></div>
  </div>
</template>

<style scoped>

.case_img{
  width: 115px;
  height: 87px;
}
.caseimg{
  width: 100%;
}

.item{
  max-width: 185px;
  max-height: 216px;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(68, 72, 97, 1);
  border-radius: 15px;
  padding: 20px;
}

.item_box{
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.item_state{
  font-family: "VelaSans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  color: rgba(219, 224, 255, 0.5);
}

.item_price{
  font-family: "VelaSans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  color: rgba(219, 224, 255, 1);
}

.item-rarity-line {
  position: absolute;
  bottom: 0;
  height: 0.1vw;
  width: 80%;
  --rarity-color: #FF4949;
  --rarity-color-shadow: rgba(255, 73, 73, 0.6);

  background: var(--rarity-color);
  -webkit-box-shadow: 0px 0px 1.5vw 0.5vw var(--rarity-color-shadow);
  -moz-box-shadow: 0px 0px 1.5vw 0.5vw var(--rarity-color-shadow);
  box-shadow: 0px 0px 1.5vw 0.5vw var(--rarity-color-shadow);
}

.item_info{
  width: 100%;
  height: 100%;
  justify-content: flex-end;
}

.item_name{
  font-family: "VelaSans", sans-serif;
  font-weight: 600;
  font-size: 17px;
  color: rgba(219, 224, 255, 1);
}

.item_type{
  font-family: "VelaSans", sans-serif;
  font-weight: 600;
  font-size: 17px;
  color: rgba(219, 224, 255, 0.5);
}

.nameGame-block svg{
  margin: 0 8px 0 0;
}


@media (max-width: 1024px) {
  .item.bottom {
    max-width: unset;
    width: calc(20% - 10px);
  }
}

@media (max-width: 768px) {
  .item {
    padding: 10px;
  }
}

@media (max-width: 640px) {
  .item.bottom {
    max-width: unset;
    width: calc(33.333% - 10px);
  }
}

@media (max-width: 480px) {
  .item.bottom {
    max-width: unset;
    width: calc(50% - 10px);
  }
}

</style>
