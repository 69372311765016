<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import component_menu from "../components/component_menu.vue";
import component_chat from "../components/component_chat.vue";

export default {
  name: "window_main",
  data() {
    let interaction = useGameStore();
    return{
        interaction: interaction,
    }
  },
  components: {
    component_menu,
    component_chat,
  },
  methods: {

  },
  mounted() {

  }
};
</script>


<template>
  <div class="window-container">
    <!-- <component_menu/> -->
    <router-view  v-slot="{ Component }">
      <transition name="fadePageWrapper" mode="out-in" appear :duration="250">
        <component :is="Component" />
      </transition>
    </router-view>
      <!-- <component_chat/> -->
  </div>
</template>

<style scoped>
.fadePageWrapper-enter-active {
  transform: translate(0%, 0%);
  opacity: 1;
  transition: all 0.25s ease;
}

.fadePageWrapper-leave-active {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fadePageWrapper-enter-from {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fadePageWrapper-leave-to {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}




.window-container{
    width: 100%;
    min-height: 600px;
    height: fit-content;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    transition: all 0.75s ease;
}
</style>

