<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';

// import required modules
import { Pagination, Autoplay, Parallax } from 'swiper/modules';


import { useGameStore } from '@/stores/interface-interaction.js';
export default {
  name: "interface_slide",
  props:{
    title: String,
    subTitle: String,
    img: String,
    arraySlide: Array,
  },
  data() {
    let interaction = useGameStore();
      return {
        interaction: interaction,
      };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    
  },
  mounted() {

  },
  setup() {
    return {
      modules: [Pagination, Autoplay, Parallax],
    };
  },
};
</script>


<template>
      <swiper 
        :pagination="true" 
        :modules="modules" 
        :loop="true"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        :speed="1000"
        :parallax="true"
        data-swiper-parallax="50%"
        class="mySwiper">
        <swiper-slide v-for="item in arraySlide" :key="item.id">
          <a :href='`${item.link}`'>
            <div class="slider-content">
                <div class="slider-title" data-swiper-parallax="-200">
                  {{$t(item.title)}}
                </div>
                <div class="slider-text" data-swiper-parallax="-600">
                  {{$t(item.subTitle)}}
                </div>
            </div>
            <div class="slider-bg" data-swiper-parallax="0">
                <img :src='`${item.src}`' :alt='`${item.src}`'>
            </div>
          </a>
        </swiper-slide>
      </swiper>
</template>


<style>
.mySwiper{
  width: 100%;
  height: 100%;
  border-radius: 1.5vw;
}
.swiper-wrapper{
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}



.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
  position: absolute;
  width: fit-content;
  right: 3.75vw;
  left: unset;
  bottom: 1.5vw;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
  margin: 0 0.1vw;
}





.swiper-pagination-bullet{
  width: 0.5vw;
  height: 0.5vw;
  border-radius: 50%;
  margin: 0 0.1vw;
  background-color: rgba(255, 255, 255, 0.7);
  transition: all 0.25s ease;
}

.swiper-pagination-bullet-active{
  width: 1vw;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.5vw;
}

.swiper-slide{
  /* border-radius: 1.5vw; */
  overflow: hidden;
}

@media (max-width: 1024px) {
      .swiper-slide{
      border-radius: 20px;
      overflow: hidden;
    }
    .swiper-pagination-bullet{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 2px;
      transition: all 0.25s ease;
    }
    .swiper-pagination-bullet-active{
      width: 25px;
      border-radius: 10px;
    }
    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
    margin: 0 2px;
  }
}


</style>

<style scoped>  
.slider-item{
    width: 100%;
    height: 100%;
    transition: all 0.25s ease;
  }
  a{
    display: flex;
    width: 100%;
    height: 100%;
  }
  .slider-content{
    width: calc(100% - 3vw);
    max-width: 50%;
    height: calc(100% - 3vw);
    padding: 1.5vw;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 2;
    transition: all 0.25s ease;
  }
  .slider-title{
    white-space: pre-wrap;
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    font-size: 2vw;
    font-weight: 700;
    line-height: 2vw;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 0.25s ease;
  }
  .slider-text{
    white-space: pre-wrap;
    margin: 0.7vw 0 0 0;
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    font-size: 0.9vw;
    font-weight: 500;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 0.25s ease;
  }

  .slider-bg{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.25s ease;
  }
  .slider-bg img{
    height: 100%;
    object-fit: cover;
    transition: all 0.25s ease;
  }




  @media (max-width: 1024px) {
    .slider-title{
      font-size: 22px;color: #FFF;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: -0.44px;
    }
    .slider-text{
      font-size: 14px;
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }

    .slider-content{
      width: calc(100% - 30px);
      max-width: calc(75% - 30px);
      height: calc(100% - 30px);
      padding: 15px;
    }
}
</style>
