<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import ru_icon from "/src/assets/img/settings/ru.svg";
import en_icon from "/src/assets/img/settings/en.svg";

export default {
    name: "settings_popup",
    data() {
        let interaction = useGameStore();
        return {
            changesStatus: false,
            changesTitle: 'русский',
            interaction: interaction,
            locales: [
                { locale: "ru", title: "Русский", icon: ru_icon },
                { locale: "en", title: "english", icon: en_icon },
            ]
        };
    },
    components: {

    },
    methods: {
        toggleChangesStatus() {
            this.changesStatus = !this.changesStatus;
        },
        setLocale(locale) {
            this.interaction.setLocale(locale.locale);
            this.$i18n.locale = locale.locale;
        },
    },
    computed: {
        audioVolume: {
            get: function () {
                if (this.interaction.settingsSite.sounds) {
                    return this.interaction.settingsSite.audioVolume;
                }
                else return 0
            },
            set: function (newValue) {
                this.interaction.setAudioVolume(newValue);
                if (newValue > 0) {
                    this.interaction.settingsSite.sounds = true;
                }
                else return this.interaction.settingsSite.sounds = false;
            }
        },
        selectedLocale() {
            return this.locales.find(l => l.locale == this.interaction.settingsSite.locale) ?? this.locales[0];
        }
    },
    watch: {
        audioVolume: function () {
            this.interaction.playSound('testAudio');
        }
    },
    mounted() {
        this.interaction.setBodyScroll(false);
    },
    unmounted() {
        this.interaction.setBodyScroll(true);
    }
};
</script>


<template>
    <div class="popup-container" :class="{ noneAnimation: !interaction.settingsSite.animations }">
        <div class="popup-block">
            <div class="popup-header">
                <div class="popup-header-title">
                    {{ $t('settings.settings') }}
                </div>
                <div class="popup-header-close" @click="interaction.settingsStatus = false">
                    <img src="/src/assets/img/shop/close.svg" alt="close">
                </div>
            </div>
            <div class="popup-languag">
                {{ $t('settings.language') }}
            </div>
            <div class="popup-changes">
                <div class="changes-blink-box" :class="{ active: changesStatus == true }" @click="toggleChangesStatus()">
                    <div class="changes-container">
                        <div class="changes-img">
                            <img :src="selectedLocale.icon" :alt="selectedLocale.locale">
                        </div>
                        <div class="changes-title">
                            {{ selectedLocale.title }}
                        </div>
                    </div>
                    <div class="changes-arrow" :class="{ active: changesStatus == true }">
                        <img src="/src/assets/img/shop/arrow.svg" alt="arrow">
                    </div>
                </div>
                <div class="changes-other-blocks" :class="{ active: changesStatus == true }">

                    <!-- <div class="other-item" @click="changesTitle = 'русский', changesStatus = false">
                    <input type="radio" id="ru" checked="true" name="changes">
                    <label for="ru">русский</label>
                </div>
                <div class="other-item" @click="changesTitle = 'english', changesStatus = false">
                    <input type="radio" id="en" name="changes">
                    <label for="en">english</label>
                </div> -->

                    <div class="other-item" v-for="locale in locales" :key="locale.locale"
                        @click="setLocale(locale), changesStatus = false">
                        <input type="radio" :id="locale.locale" name="changes">
                        <label :for="locale.locale">{{ locale.title }}</label>
                    </div>

                </div>
            </div>
            <div class="settings-item-block" :class="{ active: interaction.settingsSite.sounds }"
                @click="interaction.changeSettings('sounds')">
                <div class="block-true_false">
                    <img src="/src/assets/img/settings/arrow.svg" alt="arrow">
                </div>
                <div class="block-settings-name">
                    {{ $t('settings.sounds') }}
                </div>
            </div>
            <div class="settings-item-block" :class="{ active: interaction.settingsSite.animations }"
                @click="interaction.changeSettings('animations')">
                <div class="block-true_false">
                    <img src="/src/assets/img/settings/arrow.svg" alt="arrow">
                </div>
                <div class="block-settings-name">
                    {{ $t('settings.animations') }}
                </div>
            </div>
            <div class="settings-item-block" :class="{ active: interaction.settingsSite.streamer }"
                @click="interaction.changeSettings('streamer')">
                <div class="block-true_false">
                    <img src="/src/assets/img/settings/arrow.svg" alt="arrow">
                </div>
                <div class="block-settings-name">
                    {{ $t('settings.streamer') }}
                </div>
            </div>
            <div class="settings-item-block regulateSound">
                <div class="block-settings-name">
                    {{ $t('settings.volume') }} {{ audioVolume }}
                </div>
                <div class="sound-value-container">
                    <input type="range" min="0" max="10" class="slider" id="myRange" v-model="audioVolume">
                </div>
            </div>
        </div>
        <div class="popup-bg" @click="interaction.toggleSettingsStatus()"></div>
    </div>
</template>

<style scoped>
.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    transition: all 0.25s ease;
}

.popup-block {
    width: 21.5vw;
    min-height: 24vw;
    height: fit-content;
    background-color: rgba(54, 58, 80, 1);
    border-radius: 1vw;
    padding: 1.5vw;
    z-index: 99;
    justify-content: flex-start;
    transition: all 0.25s ease;
}

.popup-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: url(/src/assets/img/header/bg.svg);
    backdrop-filter: blur(20px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.25s ease;
}


.popup-header {
    width: 100%;
    margin: 0 0 1.2vw 0;
    justify-content: space-between;
    flex-direction: row;
}

.popup-header-title {
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    font-size: 1vw;
    font-weight: 800;
}

.popup-header-close {
    width: 1.6vw;
    height: 1.6vw;
    padding: 0.5vw;
    border-radius: 0.4vw;
    background-color: rgba(68, 72, 97, 1);
    cursor: pointer;
    transition: all 0.25s ease;
}

.popup-header-close:hover {
    background-color: #4b506d;
}

.popup-languag {
    width: 100%;
    margin: 0 0 0.7vw 0;
    justify-content: flex-start;
    flex-direction: row;
    text-transform: uppercase;
    color: rgba(219, 224, 255, 0.4);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 600;
}



.popup-changes {
    width: 100%;
    height: 3.5vw;
    margin: 0 0 1.5vw 0;
    justify-content: space-between;
    flex-direction: row;
    background-color: rgba(63, 67, 91, 1);
    border-radius: 0.5vw;
}

.changes-blink-box.active {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    transition: all 0.5s ease;
}

.changes-blink-box {
    width: 100%;
    height: 100%;
    padding: 0 1.2vw;
    justify-content: space-between;
    flex-direction: row;
    z-index: 2;
    background-color: rgba(63, 67, 91, 1);
    border-radius: 0.5vw;
    cursor: pointer;
    transition: all 0.5s 0.5s ease;
}

.changes-container {
    justify-content: flex-start;
    flex-direction: row;
}

.changes-img {
    width: 1.1vw;
    height: 1.1vw;
    margin: 0 0.7vw 0 0;
}

.changes-title {
    text-transform: uppercase;
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 800;
    transition: all 0.25s ease;
}

.changes-arrow.active {
    transform: rotate(180deg);
}

.changes-arrow {
    width: 0.6vw;
    height: 0.3vw;
    transition: all 0.25s ease;
}





.changes-other-blocks.active .other-item:nth-child(1) {
    transition: all 0.60s 0.45s ease;
}

.changes-other-blocks.active .other-item:nth-child(2) {
    transition: all 0.55s 0.35s ease;
}

.changes-other-blocks.active .other-item:nth-child(3) {
    transition: all 0.40s 0.25s ease;
}

.other-item:nth-child(1) {
    transition: all 0.25s 0.0s ease;
}

.other-item:nth-child(2) {
    transition: all 0.25s 0.15s ease;
}

.other-item:nth-child(3) {
    transition: all 0.25s 0.25s ease;
}

.changes-other-blocks.active {
    height: 8vw;
    bottom: -8vw;
    opacity: 1;
    transition: all 0.5s ease;
}

.changes-other-blocks {
    position: absolute;
    width: 100%;
    height: 0px;
    bottom: 0px;
    opacity: 0;
    background-color: rgba(63, 67, 91, 1);
    border-bottom-left-radius: 0.5vw;
    border-bottom-right-radius: 0.5vw;
    box-shadow: 0px 0.6vw 1.6vw rgba(0, 0, 0, 0.15);
    border-top: 0.15vw solid rgba(41, 45, 64, 1);
    padding: 0.5vw 0.7vw;
    justify-content: flex-start;
    overflow: hidden;
    z-index: 1;
    transition: all 0.5s 0.4s ease;
}

.changes-other-blocks.active .other-item {
    opacity: 1;
    transform: translate(0%, 0%);
}

.other-item {
    width: 100%;
    height: 2.5vw;
    margin: 0.5vw 0;
    padding: 0 0.5vw;
    transform: translate(-0%, -200%);
    justify-content: flex-start;
    flex-direction: row;
    background-color: #4b506d;
    border-radius: 0.5vw;
    opacity: 0;
    cursor: pointer;
    transition: all 0.25s ease;
}

.other-item label {
    margin: 0 0 0 1.1vw;
    text-transform: uppercase;
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 800;
    cursor: pointer;
}

.other-item input {
    width: 0.7vw;
    height: 0.7vw;
    border: none;
    cursor: pointer;
    opacity: 0;
}

input:checked+label::before {
    background-color: none;
}

input:checked+label::after {
    background-color: #ffffff;
}

.other-item:nth-child(1) input+label::before {
    position: absolute;
    background: url(/src/assets/img/settings/ru.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    transform: translate(calc(-100% - 0.7vw), 0%);
    content: '';
    display: inline-block;
    width: 1.1vw;
    height: 1.1vw;
    flex-shrink: 0;
    flex-grow: 0;
    cursor: pointer;
}

.other-item:nth-child(2) input+label::before {
    position: absolute;
    background: url(/src/assets/img/settings/en.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    transform: translate(calc(-100% - 0.7vw), 0%);
    content: '';
    display: inline-block;
    width: 1.1vw;
    height: 1.1vw;
    flex-shrink: 0;
    flex-grow: 0;
    cursor: pointer;
}


.settings-item-block {
    width: 100%;
    height: 3.5vw;
    margin: 0 0 0.5vw 0;
    padding: 0 0.7vw;
    justify-content: flex-start;
    flex-direction: row;
    cursor: pointer;
    background-color: rgba(63, 67, 91, 1);
    border-radius: 0.5vw;
}

.block-true_false {
    width: 2vw;
    height: 2vw;
    margin: 0 0.5vw 0 0;
    border: 0.15vw solid rgba(95, 103, 140, 1);
    border-radius: 0.4vw;
}

.settings-item-block.active .block-true_false img {
    width: 0.7vw;
    opacity: 1;
    transform: translate(0px, 0px);
}

.block-true_false img {
    width: 0.1vw;
    opacity: 0;
    transform: translate(0px, 0.2vw);
    transition: all 0.25s ease;
}

.block-settings-name {
    text-transform: uppercase;
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 800;
}


.settings-item-block.regulateSound {
    justify-content: space-between;
}

.sound-value-container {
    width: 10vw;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 0.2vw;
    border-radius: 0.2vw;
    background: rgba(219, 224, 255, 1);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.7vw;
    height: 0.7vw;
    border-radius: 50%;
    background: #5FE895;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 1.4vw;
    height: 1.4vw;
    border-radius: 50%;
    background: #5FE895;
    cursor: pointer;
}


@media (max-width: 1280px) {
    .popup-header {
        margin: 0 0 20px 0;
    }

    .popup-block {
        width: 320px;
        border-radius: 20px;
        padding: 20px;
    }

    .popup-header-title {
        font-size: 24px;
    }

    .popup-header-close {
        width: 36px;
        height: 36px;
        padding: 10px;
        border-radius: 8px;
    }

    .popup-languag {
        margin: 0 0 14px 0;
        font-size: 14px;
    }

    .popup-changes {
        height: 60px;
        margin: 0 0 20px 0;
        border-radius: 10px;
    }

    .changes-blink-box {
        padding: 15px;
        border-radius: 10px;
    }

    .changes-img {
        width: 22px;
        height: 22px;
        margin: 0 14px 0 0;
    }

    .changes-title {
        font-size: 14px;
    }

    .changes-arrow {
        width: 12px;
        height: 6px;
    }

    .settings-item-block {
        height: 60px;
        margin: 0 0 10px 0;
        border-radius: 10px;
        padding: 15px;
    }

    .block-true_false {
        width: 40px;
        height: 40px;
        margin: 0 10px 0 0;
        border-radius: 8px;
    }

    .settings-item-block.active .block-true_false img {
        width: 15px;
    }

    .block-settings-name {
        font-size: 14px;
    }

    .sound-value-container {
        width: 120px;
    }

    .slider {
        height: 4px;
        border-radius: 5px;
    }

    .slider::-webkit-slider-thumb {
        width: 18px;
        height: 18px;
    }

    .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
    }

    .changes-other-blocks.active {
        height: 120px;
        bottom: -120px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .other-item {
        height: 50px;
        margin: 5px 0;
        padding: 0 10px;
        border-radius: 10px;
    }

    .other-item input {
        width: 15px;
        height: 15px;
    }

    .other-item label {
        margin: 0 0 0 22px;
        font-size: 14px;
    }

    input+label::before {
        width: 22px;
        height: 22px;
        transform: translate(calc(-100% - 15px), 0%);
    }

    .other-item:nth-child(1) input + label::before {
        transform: translate(calc(-100% - 14px), 0%);
        width: 22px;
        height: 22px;
    }
    .other-item:nth-child(2) input + label::before {
        transform: translate(calc(-100% - 14px), 0%);
        width: 22px;
        height: 22px;
    }
}
</style>
