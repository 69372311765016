<script>
import { useGameStore } from '@/stores/interface-interaction.js';

export default {
  name: "cases_container",
  props:{
    newGameMode: Boolean,
  },
  data() {
    let interaction = useGameStore();
    return{
        interaction: interaction,
    }
  },
  components: {

  },
  methods: {

  },
  mounted() {

  },
  computed: {
  },

  watch: {

  },
};
</script>

<template>
  <div class="casesContainer">
    <div class="casesBackgroundBlock">
        <div class="casesBackground">
            <img src="/game/case/caseBackground.png">
        </div>
        <div class="caseBackgroundGlow">
            <img src="/game/case/caseBackgroundGlow.png">
        </div>
        <div class="caseGlow">
            <img src="/game/case/glow.png">
        </div>
        <div class="caseGlow">
            <img src="/game/case/glow.png">
        </div>
        <div class="caseImage">
            <img src="/game/case/caseImage.png">
        </div>
        <div class="darknessBlock">
            <div class="left"></div>
            <div class="right"></div>
        </div>
    </div>
    <div class="casesTitleBlock">
        <div class="newGameMode" :class="{ active: newGameMode }">new</div>
        <div class="title">Cases</div>
    </div>
  </div>
</template>

<style scoped>

    .casesContainer {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 34px;
        width: 100%;
        height: 258px;
        border-radius: 1.2vw;
        overflow: hidden;
        cursor: pointer;
        /* margin: 0.4vw 0px 0px 0px; */
    }

    .casesBackgroundBlock {
        position: absolute;
        left: 0;
        top: 0;
        justify-content: center;
        align-self: center;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .casesBackground {
        position: absolute;
        left: 10%;
        width: 1552px;
        height: 253px;
        transition: 1s ease-in-out;
    }
    .casesContainer:hover .casesBackground{
        left: 0%;
    }

    .caseBackgroundGlow {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
    }

    .caseImage {
        position: absolute;
        bottom: -55px;
        width: 550px;
        height: 250px;
        right: 10%;
        z-index: 2;
    }

    .caseImage img {
        object-fit: contain;
    }
    .caseGlow {
        position: absolute;
        right: -5%;
        bottom: 0;
        width: 800px;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        transition: 0.25s;
        z-index: 1;
    }

    .casesContainer:hover .caseGlow {
        opacity: 1;
    }

    /* .casesBackgroundBlock img {

    } */

    .darknessBlock {
        flex-direction: row;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .left {
        position: absolute;
        left: 0;
        width: 350px;
        height: 100%;
        background: linear-gradient(to right, #30344A, transparent);
    }

    .right {
        position: absolute;
        right: 0;
        width: 350px;
        height: 100%;
        background: linear-gradient(to left, #30344A, transparent);
    }

    .casesTitleBlock {
        align-items: flex-start;
    }

    .newGameMode {
        display: none;
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
        text-transform: uppercase;
        color: #6BE09A;
        padding: 12px 12px;
        border-radius: 14px;
        background-color: rgba(107, 224, 154, 0.2);
    }

    .newGameMode.active {
        display: flex;
    }

    .title {
        font-size: 44px;
        font-weight: 700;
        color: #DBE0FF;
    }



  @media (max-width: 1640px) {

  }
  @media (max-width: 1440px) {

    .caseImage {
        right: 0;
    }

    .caseGlow {
        right: -15%;
    }

  }
  @media (max-width: 1280px) {
    .caseBackgroundGlow {
        justify-content: flex-end;
    }

    .caseBackgroundGlow img{
        height: 100%;
        object-fit: cover;
    }
  }
  @media (max-width: 1024px) {
    .casesContainer {
        width: calc(50% - 8px);
        height: calc(200px - 8px);
        padding: 10px;
        justify-content: flex-start;
        border-radius: 25px;
    }

    .casesTitleBlock {
        flex-direction: column-reverse;
        gap: 0px;
    }

    .newGameMode {
        font-size: 10px;
        padding: 4px 16px 2px 16px;
        border-radius: 6px;
    }

    .title {
        font-size: 25px;
    }

    .caseImage {
        width: 440px;
        height: 210px;
        left: calc(10vw - 20px);
    }

    .casesBackground {
        position: absolute;
        bottom: 10px;
        height: 100px;
    }
    .casesBackground img {
        height: 100%;
        object-fit: contain;
    }

    .caseGlow {
        right: unset;
        left: -80px;
    }
  }
  @media (max-width: 980px) {

  }
  @media (max-width: 1024px) {
   
  }

  @media (max-width: 768px) {
    .caseImage {
        left: calc(20vw - 130px);
    }  
  }
  @media (max-width: 640px) {

  }
  @media (max-width: 480px) {

  }
  @media (max-width: 420px) {

  }
  @media (max-width: 380px) {

  }
</style>
