<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import elements_live_drop_item from "@/components/elements/elements_live_drop_item.vue";
export default {
  name: "elements_live_drop",
  data() {
    let interaction = useGameStore();
      return {
        interaction: interaction,
      };
  },
  components: {
    elements_live_drop_item,
  },
  methods: {

  },
  mounted() {

  },
  computed:{
    dropList(){
      return this.interaction.stats.livedrop;
    }
  }
};
</script>

<template>
    <div class="live-drop-container">
      <transition-group name="fade">
        <elements_live_drop_item v-for="drop in dropList"
        :key="drop.roundId"
        :drop="drop"
        />
      </transition-group>
      <div class="live-drop-shadow"></div>
    </div>
</template>

<style scoped>
.fade-enter-active {
  transform: translate(-110%, 0%);
  opacity: 1;
  transition: all 0.25s ease;
}

.fade-leave-active {
  transform: translate(-110%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-enter-from {
  transform: translate(-110%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-leave-to {
  transform: translate(-110%, 0%);
  transition: all 0.25s ease;
}



.live-drop-container{
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
}
.live-drop-shadow{
  position: absolute;
  right: 0;
  width: 10vw;
  height: 100%;
  background: linear-gradient(270.39deg, rgba(39, 41, 58, 1) 0.34%, rgba(39, 41, 58, 0) 99.66%);
  pointer-events: none;
}


@media (max-width: 1024px) {
  .live-drop-shadow{
    display: none;
  }
}
</style>