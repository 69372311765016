<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import gsap from 'gsap';

export default {
  name: "elements_game_modes",
  props:{
    img: String,
    title: String,
    number: Number,
  },
  data() {
    let interaction = useGameStore();
    return{
        animatedNumber: this.number,
        interaction: interaction,
    }
  },
  components: {

  },
  methods: {

  },
  mounted() {

  },
  computed: {
    animatedProps: function() {
      return this.number;
    },
    formatedNumber(){
      return `${Number(this.animatedNumber).toFixed(2)}`
    },
  },
  watch: {
    animatedProps: function(newValue) {
      gsap.to(this.$data, { duration: 0.5, animatedNumber: newValue });
    }
  },
};
</script>

<template>
  <div class="game-modes-item" :class="{big: this.interaction.chatStatus}">
    <div class="modes-item-new">
      new
    </div>
    <div class="modes-item-img">
      <img :src="`${img}`" alt="game modes">
    </div>
    <div class="modes-item-titel">
      {{title}}
    </div>
    <div class="modes-item-text">
      <span class="Bcoin">{{formatedNumber}}</span>
    </div>
  </div>
</template>

<style scoped>
.game-modes-item.support .modes-item-text{
  display: none;
}
.game-modes-item.support{
  border: 0.2vw solid #30344A;
  background-color: transparent;
}
.game-modes-item.new .modes-item-new{
  display: flex;
}
.modes-item-new{
  display: none;
  position: absolute;
  top: 0;
  left: 1vw;
  padding: 0.1vw 0.4vw;
  background: linear-gradient(90deg, #5AD189 0%, #3DB56C 100%);
  border-bottom-left-radius: 0.4vw;
  border-bottom-right-radius: 0.4vw;
  color: #DBE0FF;
  font-family: "VelaSans", sans-serif;
  font-size: 0.6vw;
  font-weight: 700;
}
/* .game-modes-item.big{
  height: 15vw;
} */
.game-modes-item{
  width: calc(33.3% - 0.4vw);
  height: calc(13vw - 0vw);
  margin: 0;
  background-color: #30344A;
  border-radius: 1.2vw;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.25s ease;
}
.game-modes-item:hover .modes-item-img{
  transform: scale(1.2);
}
.modes-item-img{
  position: absolute;
  min-width: 16vw;
  width: 100%;
  height: 100%;
  transition: all 0.25s ease;
}
.modes-item-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modes-item-titel{
  position: absolute;
  top: 2vw;
  left: 1vw;
  color: #DBE0FF;
  font-family: "VelaSans", sans-serif;
  font-size: 1.7vw;
  font-weight: 700;
}

.modes-item-text::before{
  content: "";
  display: flex;
  width: 0.9vw;
  height: 0.9vw;
  min-width: 0.9vw;
  min-height: 0.9vw;
  margin: 0 0.2vw 0 0;
  background-image: url(/coin.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.modes-item-text{
  position: absolute;
  top: 4.3vw;
  left: 1vw;
  flex-direction: row;
}
.modes-item-text span{
  color: #AEB7DD;
  font-family: "VelaSans", sans-serif;
  font-size: 1vw;
  font-weight: 700;
}

  @media (max-width: 1640px) {

  }
  @media (max-width: 1440px) {

  }
  @media (max-width: 1280px) {
  }
  @media (max-width: 1024px) {

  }
  @media (max-width: 980px) {

  }
  @media (max-width: 1024px) {
    .game-modes-item{
      width: calc(50% - 8px);
      height: calc(200px - 8px);
      margin: 0px;
      border-radius: 25px;
    }
    .game-modes-item.new .modes-item-new{
      display: none;
    }
    .modes-item-img{
      min-width: 200px;
    }
    .modes-item-titel{
      top: 10px;
      left: 10px;
      font-size: 25px;
    }
    .modes-item-text{
      top: 45px;
      left: 10px;
    }
    .modes-item-text span{
      font-size: 15px;
    }
    .modes-item-text::before {
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
      margin: 0 4px 0 0;
    }
  }
  @media (max-width: 640px) {

  }
  @media (max-width: 480px) {

  }
  @media (max-width: 420px) {

  }
  @media (max-width: 380px) {

  }
</style>
