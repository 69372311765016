<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import gsap from 'gsap';
import noUiSlider from 'nouislider';
import '@/css/nouislider.css';

import { debounce } from '@/scripts/utils.js';

import elements_inventory_item from "@/components/elements/elements_inventory_item.vue";
import interface_slider from "@/components/interface/interface_slider.vue";

export default {
    name: "component_shop",
    data() {
        let interaction = useGameStore();
        let sorts = [
            { title: 'byUpPrice', value: 'asc' },
            { title: 'byDownPrice', value: 'desc' }
        ];
        return {
            shopCost: 0,
            sliderValues: [100, 1000],
            filterPopup: false,
            allFilterStatus: true,
            showOnlySelectedItems: false,
            interaction: interaction,
            sorts: sorts,
            sort: sorts[0],
            itemsList: [],
            searchString: '',
            searching: false
        };
    },
    components: {
        elements_inventory_item,
        interface_slider
    },
    methods: {
        toggleFilterPopup() {
            this.filterPopup = !this.filterPopup;
        },
        toggleAllFilterStatus() {
            this.allFilterStatus = !this.allFilterStatus;
        },
        async updateItemsList() {
            let search = {
                minPrice: this.sliderMin * 1000 / this.interaction.BALANCE_BY_USD,
                maxPrice: this.sliderMax * 1000 / this.interaction.BALANCE_BY_USD,
                searchString: this.searchString != "" ? this.searchString : undefined,
                sortOrder: this.sort.value,
            }
            this.searching = true;
            this.itemsList = await this.interaction.getPrices(search);
            this.searching = false;
        },
        async buySelectedItems() {

            try {
                let res = await this.interaction.buyItems(this.selectedItems.map(i => i.name));
                this.itemsList.map(i => i.shopSelected = false);
            } catch (e) {
                this.$toast.error(this.$t(e.message));
            }

        },

        toggleEnadleItem() {
            this.showOnlySelectedItems = !this.showOnlySelectedItems;
        },
        clearSelectedItems() {
            return this.itemsList.map(i => i.shopSelected = false);
        },

    },
    created() {
        this.debouncedUpdateItemsList = debounce(this.updateItemsList, 1200);
    },
    beforeMount() {
        this.updateItemsList();
    },
    computed: {

        sliderMin: {
            get() {
                return this.sliderValues[0];
            },
            set(val) {
                this.sliderValues[0] = val;
            }

        },
        sliderMax: {
            get() {
                return this.sliderValues[1];
            },
            set(val) {
                this.sliderValues[1] = val;
            }

        },
        selectedItems() {
            let items = this.itemsList.filter(i => i.shopSelected);
            return items;
        },
        selectedCost() {
            let sum = this.selectedItems.reduce((acc, cur) => {
                return acc + cur.price;
            }, 0) / 1000 * this.interaction.BALANCE_BY_USD;
            return sum;
        },

        showItems() {
            if (this.showOnlySelectedItems) {
                return this.selectedItems;
            } else {
                return this.itemsList
            }
        },
    },
    watch: {
        sliderMin() {
            this.debouncedUpdateItemsList();
        },
        sliderMax() {
            this.debouncedUpdateItemsList();
        },
        sort() {
            this.debouncedUpdateItemsList();
        },
        searchString() {
            this.debouncedUpdateItemsList();
        },
        selectedCost: function (newValue) {
            gsap.to(this.$data, { duration: 0.25, shopCost: newValue });
        },
        selectedItems(newValue) {
            if (newValue.length === 0) {
                this.showOnlySelectedItems = false;
            }
        }
    },
};
</script>

<template>
    <div class="shop-main">
        <div class="shop-header">
            <div class="shop-header-title">
                {{$t('chat.chooseSkin')}}
            </div>
            <div class="shop-header-close" @click="interaction.shopStatus = false">
                <img src="/src/assets/img/shop/close.svg" alt="close">
            </div>
        </div>
        <div class="shop-filter" @click="toggleAllFilterStatus()" :class="{ active: allFilterStatus == true }">
            {{$t('chat.filer')}}
        </div>

        <transition name="fadeUp" mode="out-in">
            <div class="shop-filter-container" v-if="allFilterStatus == true">
                <div class="shop-search">
                    <input type="text" :placeholder="$t('chat.search')" v-model="searchString">
                </div>
                <div class="shop-search-parametrs">
                    <div class="search-parametrs-container">
                        <div class="search-parametrs-min">
                            <input v-model="sliderMin" />
                            <!-- {{ sliderMin }} -->
                        </div>
                        <div class="search-parametrs-line"></div>
                        <div class="search-parametrs-max">
                            <input v-model="sliderMax" />
                            <!-- {{ sliderMax }} -->
                        </div>
                    </div>
                    <interface_slider v-model="sliderValues"></interface_slider>
                </div>
                <div class="shop-filter-block">
                    <div class="shop-filter-img" @click="toggleFilterPopup()">
                        <img src="/src/assets/img/shop/arrows.svg" alt="arrow">
                    </div>
                    <div class="shop-filter-text" @click="toggleFilterPopup()">
                        {{ $t(sort.title) }}
                    </div>
                    <div class="shop-filter-arrow" @click="toggleFilterPopup()" :class="{ active: filterPopup == true }">
                        <img src="/src/assets/img/shop/arrow.svg" alt="arrow">
                    </div>
                    <div class="shop-filter-popup" :class="{ active: filterPopup == true }">

                        <div class="shop-popup-item" v-for="sortType in sorts" :key="sortType.value"
                            @click="sort = sortType, toggleFilterPopup()">
                            <input type="radio" :id="`sort-${sortType.value}`" :value="sortType.value"
                                :checked="sort.value == sortType.value" name="sortType">
                            <label :id="`sort-${sortType.value}`">{{ $t(sortType.title) }}</label>
                        </div>
                    </div>
                </div>
                <transition name="ShowChangeItem" mode="out-in">
                    <div class="shop-enable-container" name="fade" mode="out-in" v-if="selectedItems.length > 0">
                        <div class="shop-enable-block" @click="toggleEnadleItem()"
                            :class="{ active: showOnlySelectedItems == true }">
                            {{ showOnlySelectedItems ? $t('chat.lockChoose') : $t('chat.seeChoose') }} {{
                                selectedItems.length }}
                        </div>
                        <div class="shop-enable-cancel" @click="clearSelectedItems()">
                            {{$t('chat.cancel')}}
                        </div>
                    </div>
                    <div class="shop-enable-container" name="fade" mode="out-in" v-else>
                        <div class="shop-enable-change">
                            {{$t('chat.choosed')}} {{ selectedItems.length }}
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
        <div class="shop-container-items" :class="{ active: allFilterStatus == false, change: selectedItems.length > 0 }">

            <transition-group name="animateFadeItems">
                <elements_inventory_item v-for="item in showItems" :key="item.name" :item="{ ...item, template: item }"
                    @click="item.shopSelected = !item.shopSelected" :class="{ active: item.shopSelected }">
                </elements_inventory_item>
            </transition-group>

        </div>
        <transition name="fade" mode="out-in">
            <div class="shop-panel-buy" v-if="selectedItems.length > 0">
                <div class="panel-buy">
                    <div class="panel-buy-title">
                        {{$t('items.total')}}:
                    </div>
                    <div class="panel-buy-cost">
                        {{ shopCost.toFixed(2) }}
                    </div>
                </div>
                <div class="btn-buy" @click="buySelectedItems">
                    {{$t('items.byu')}}
                </div>
            </div>
        </transition>
    </div>
</template>

<style scoped>
.animateFadeItems-enter-active {
    transform: scale(1.0);
    opacity: 1;
    transition: all 0.25s ease;
}

.animateFadeItems-leave-active {
    position: absolute;
    transform: scale(0.5);
    opacity: 0;
    transition: all 0.0s ease;
}

.animateFadeItems-enter-from {
    transform: scale(0.5);
    opacity: 0;
    transition: all 0.25s ease;
}

.animateFadeItems-leave-to {
    transform: scale(0.5);
    opacity: 0;
    transition: all 0.25s ease;
}







.ShowChangeItem-enter-active {
    opacity: 1;
    transition: all 0.25s ease;
}

.ShowChangeItem-leave-active {
    opacity: 0;
    transition: all 0.25s ease;
}

.ShowChangeItem-enter-from {
    opacity: 0;
    transition: all 0.25s ease;
}

.ShowChangeItem-leave-to {
    opacity: 0;
    transition: all 0.25s ease;
}




.fade-enter-active {
    transform: translate(0%, 0%);
    opacity: 1;
    transition: all 0.25s ease;
}

.fade-leave-active {
    transform: translate(0%, 100%);
    opacity: 0;
    transition: all 0.25s ease;
}

.fade-enter-from {
    transform: translate(0%, 100%);
    opacity: 0;
    transition: all 0.25s ease;
}

.fade-leave-to {
    transform: translate(0%, 100%);
    opacity: 0;
    transition: all 0.25s ease;
}


.fadeUp-enter-active {
    transform: translate(0%, 0%);
    opacity: 1;
    transition: all 0.25s ease;
}

.fadeUp-leave-active {
    transform: translate(0%, -10px);
    margin: -11.5vw 0 0 0;
    opacity: 0;
    transition: all 0.25s ease;
}

.fadeUp-enter-from {
    transform: translate(0%, -10px);
    margin: -11.5vw 0 0 0;
    opacity: 0;
    transition: all 0.25s ease;
}

.fadeUp-leave-to {
    transform: translate(0%, -10px);
    margin: -11.5vw 0 0 0;
    opacity: 0;
    transition: all 0.25s ease;
}




.shop-main {
    position: absolute;
    top: 0;
    left: calc(-100% - 1vw);
    /* transform: translate(calc(-20vw), 0%); */
    width: 19vw;
    height: 100%;
    padding: 0vw 1vw 1vw 1vw;
    border-radius: 1vw;
    background-color: rgba(54, 58, 80, 1);
    box-shadow: 0 0 0.5vw rgba(0, 0, 0, 0.2);
    justify-content: flex-start;
    z-index: 1;
    overflow: hidden;
    transition: all 0.25s 0.0s ease;
}

.shop-header {
    width: 100%;
    height: 3vw;
    min-height: 3vw;
    align-items: flex-start;
    padding: 1vw 0 0 0;
    justify-content: space-between;
    flex-direction: row;
    background-color: rgba(54, 58, 80, 1);
    z-index: 3;
}

.shop-header-title {
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    font-size: 0.9vw;
    font-weight: 700;
}

.shop-header-close {
    width: 1.9vw;
    height: 1.9vw;
    padding: 0.6vw;
    border-radius: 0.4vw;
    background-color: rgba(68, 72, 97, 1);
    cursor: pointer;
    transition: all 0.25s ease;
}

.shop-header-close:hover {
    background-color: #4b506d;
}

.shop-filter {
    width: 100%;
    height: 1.3vw;
    background-color: rgba(54, 58, 80, 1);
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 700;
    margin: 0 0 0.7vw 0;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;
    z-index: 3;
}

.shop-filter.active::after {
    transform: rotate(180deg);
}

.shop-filter::after {
    content: "";
    width: 0.5vw;
    height: 0.5vw;
    background: url(../assets/img/shop/arrow.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    margin: 0 0 0 0.6vw;
    transition: all 0.25s ease;
}

.shop-filter-container {
    width: 100%;
    transition: all 0.25s ease;
}

.shop-search {
    width: 100%;
    margin: 0 0 0.7vw 0;
}

.shop-search input::placeholder {
    color: rgba(137, 143, 176, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 600;
}

.shop-search-parametrs input,
.shop-search input {
    width: 100%;
    height: 2.5vw;
    padding: 0 1vw;
    color: rgba(137, 143, 176, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 600;
    background-color: rgba(68, 72, 97, 1);
    border: none;
    border-radius: 0.5vw;
}


.shop-search-parametrs input::placeholder {
    color: rgba(137, 143, 176, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 600;
}

.shop-search-parametrs input {
    width: 100%;
    height: 100%;
    padding: 0 0.2vw;
    color: rgb(255, 255, 255);
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 600;
    border: none;
    border-radius: 0.5vw;
}







.shop-search-parametrs {
    width: 100%;
    margin: 0 0 0.7vw 0;
}

.search-parametrs-container {
    width: 100%;
    margin: 0 0 0.7vw 0;
    justify-content: space-between;
    flex-direction: row;
}

.search-parametrs-min::before,
.search-parametrs-max::before {
    content: "";
    display: flex;
    width: 0.9vw;
    height: 0.9vw;
    min-width: 0.9vw;
    min-height: 0.9vw;
    margin: 0 0.2vw 0 0;
    background-image: url(/coin.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.search-parametrs-min {
    min-width: 4.5vw;
    color: rgba(255, 255, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 600;
    padding: 0.6vw 0.5vw;
    background-color: rgba(68, 72, 97, 1);
    border-radius: 0.4vw;
    flex-direction: row;
}

.search-parametrs-line {
    min-width: 1vw;
    width: calc(100% - 2vw);
    max-width: 4vw;
    margin: 0 1vw;
    height: 0.1vw;
    background-color: rgba(68, 72, 97, 1);
}

.search-parametrs-max {
    min-width: 4.5vw;
    color: rgba(255, 255, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 600;
    padding: 0.6vw 0.5vw;
    background-color: rgba(68, 72, 97, 1);
    border-radius: 0.4vw;
    flex-direction: row;
}


.slider {
    margin: 0px 0px 0px 0px;
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 0.2vw;
    border: none;
    outline: none;
    border: 0.1vw solid rgba(68, 72, 97, 1);
    background-color: rgba(68, 72, 97, 1);
    border-radius: 0.3vw;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}


.shop-filter-block {
    width: 100%;
    margin: 0 0 0.5vw 0;
    justify-content: flex-start;
    flex-direction: row;
}

.shop-filter-text {
    margin: 0 0.4vw 0 0.6vw;
    color: rgba(255, 255, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 600;
    cursor: pointer;
}

.shop-filter-img {
    width: 0.7vw;
    cursor: pointer;
}

.shop-filter-arrow {
    width: 0.5vw;
    transition: all 0.25s ease;
    transform: rotate(0deg);
    cursor: pointer;
}

.shop-filter-arrow.active {
    transform: rotate(180deg);
}

.shop-filter-arrow img {
    transform: translate(0px, 0.1vw);
}


.shop-filter-popup.active {
    height: fit-content;
    opacity: 1;
    pointer-events: auto;
}

.shop-filter-popup {
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: 1.5vw;
    padding: 0.7vw 0.7vw 0.2vw 0.5vw;
    border-radius: 0.4vw;
    background-color: rgba(68, 72, 97, 1);
    box-shadow: 0 0 0.2vw rgba(0, 0, 0, 0.2);
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    z-index: 6;
    transition: all 0.5s ease;
}

.shop-filter-popup.active .shop-popup-item {
    transform: translate(0%, 0%);
    opacity: 1;
}

.shop-filter-popup.active .shop-popup-item:nth-child(1) {
    transition: all 0.60s 0.45s ease;
}

.shop-filter-popup.active .shop-popup-item:nth-child(2) {
    transition: all 0.55s 0.35s ease;
}

.shop-filter-popup.active .shop-popup-item:nth-child(3) {
    transition: all 0.40s 0.25s ease;
}

.shop-filter-popup.active .shop-popup-item:nth-child(4) {
    transition: all 0.35s 0.2s ease;
}


.shop-popup-item {
    width: 100%;
    transform: translate(-100%, -100%);
    margin: 0 0 0.5vw 0;
    justify-content: flex-start;
    flex-direction: row;
    opacity: 0;
    transition: all 0.25s 0.25s, opacity 0.1s ease;
}

.shop-popup-item label {
    margin: 0 0 0 0.5vw;
    color: rgba(255, 255, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 600;
    cursor: pointer;
}

.shop-popup-item input {
    width: 0.7vw;
    height: 0.7vw;
    border: none;
    cursor: pointer;
    opacity: 0;
}

input:checked+label::before {
    background-color: none;
}

input:checked+label::after {
    background-color: #ffffff;
}

input+label::after {
    position: absolute;
    left: 0;
    transform: translate(-260%, 95%);
    content: '';
    display: inline-block;
    width: 0.4vw;
    height: 0.4vw;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 50%;
    cursor: pointer;
}

input+label::before {
    position: absolute;
    left: 0;
    transform: translate(calc(-100% - 0.5vw), 30%);
    content: '';
    display: inline-block;
    width: 0.7vw;
    height: 0.7vw;
    flex-shrink: 0;
    flex-grow: 0;
    border: 0.1vw solid #ffffff;
    border-radius: 50%;
    cursor: pointer;
}

.shop-enable-container {
    width: 100%;
    min-height: 2vw;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 0 1vw 0;
    color: rgba(137, 143, 176, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 700;
}

.shop-enable-change {
    color: rgba(137, 143, 176, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 700;
    padding: 0.2vw 0.5vw;
}

.shop-enable-block.active {
    color: rgba(255, 123, 123, 1);
    background: rgba(255, 123, 123, 0.1);
}

.shop-enable-block {
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 0.5vw 0 0;
    color: rgba(95, 232, 149, 1);
    background: rgba(95, 232, 149, 0.1);
    border-radius: 0.3vw;
    padding: 0.3vw 0.5vw;
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 700;
    transition: all 0.25s ease;
    cursor: pointer;
    user-select: none;
}

.shop-enable-block.active:hover {
    background: rgba(255, 123, 123, 0.2);
}

.shop-enable-block:hover {
    background: rgba(95, 232, 149, 0.2);
}

.shop-enable-cancel {
    flex-direction: row;
    justify-content: flex-start;
    color: rgba(137, 143, 176, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 700;
    transition: all 0.25s ease;
    cursor: pointer;
}

.shop-enable-cancel:hover {
    color: rgb(157, 163, 198);
}





.shop-button-buy {
    width: 100%;
    height: 3vw;
    min-height: 3vw;
    margin: 1vw 0 0 0;
    background-color: rgba(68, 72, 97, 1);
    border-radius: 0.5vw;
    color: rgba(161, 165, 205, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 800;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.25s ease;
}

.shop-button-buy:hover {
    background-color: #4b506d;
}

.shop-container-items.change {
    height: calc(100% - 21vw);
}

.shop-container-items.active {
    height: calc(100% - 5vw);
}

.shop-container-items.active.change {
    height: calc(100% - 10vw);
}

.shop-container-items {
    width: calc(100% + 1vw);
    height: calc(100% - 12vw);
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    overflow-y: overlay;
    overflow-x: hidden;
    will-change: height;
    transition: all 0.25s ease;
}

.shop-container-items .inventory-item {
    margin: 0 0.5vw 0.5vw 0.5vw;
}


.shop-panel-buy {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5vw;
    min-height: 5vw;
    padding: 1vw;
    margin: 1vw 0 0 0;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(54, 58, 80, 1);
    z-index: 5;
}

.panel-buy {
    width: fit-content;
    align-items: flex-start;
}

.panel-buy-title {
    color: rgba(137, 143, 176, 1);
    font-family: "VelaSans", sans-serif;
    text-transform: uppercase;
    font-size: 0.7vw;
    font-weight: 800;
}

.panel-buy-cost {
    color: white;
    font-family: "VelaSans", sans-serif;
    font-size: 1vw;
    font-weight: 600;
    display: flex;
    flex-direction: row;
}

.panel-buy-cost::before {
    content: "";
    display: flex;
    width: 0.9vw;
    height: 0.9vw;
    min-width: 0.9vw;
    min-height: 0.9vw;
    margin: 0 0.2vw 0 0;
    background-image: url(/coin.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.panel-buy-cost span {
    color: white;
    font-family: "VelaSans", sans-serif;
    font-size: 1vw;
    font-weight: 600;
    display: flex;
    flex-direction: row;
}

.btn-buy {
    width: 50%;
    height: 100%;
    padding: 0 1vw;
    background-color: rgba(77, 197, 125, 1);
    border-radius: 0.5vw;
    color: white;
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 800;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.25s ease;
}

.btn-buy:hover {
    background-color: #37995e;
}


@media (max-width: 1024px) {
    .shop-main {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: calc(100vh + 80px);
        padding: 0px 10px 80px 10px;
        border-radius: 0;
        background-color: rgba(54, 58, 80, 1);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    }

    
    .shop-header {
        width: 100%;
        height: 55px;
        min-height: 55px;
        align-items: center;
        padding: 15px 0 0px 0;
    }

    .shop-header-title {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 137.6%;
        letter-spacing: 0.72px;
        text-transform: uppercase;
    }

    .shop-header-close {
        width: 38px;
        height: 38px;
        padding: 12px;
        border-radius: 8px;
    }

    .shop-filter {
        width: 100%;
        height: 24px;
        font-family: "VelaSans", sans-serif;
        margin: 0 0 10px 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 137.6%;
        user-select: none;
    }

    .shop-filter::after {
        width: 10px;
        height: 10px;
        margin: 0 0 0 10px;
    }

    .shop-search {
        margin: 0 0 6px 0;
    }

    .shop-search input {
        width: 100%;
        height: 48px;
        padding: 0 15px;
        border-radius: 10px;

        color: #898FB0;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 137.6%;
    }

    .shop-search-parametrs input {
        width: 100%;
        height: fit-content;
        padding: 0;
        border-radius: 10px;

        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 137.6%;
    }

    .shop-search-parametrs input::placeholder,
    .shop-search input::placeholder {
        color: #898FB0;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 137.6%;
    }

    .search-parametrs-min,
    .search-parametrs-max {
        min-width: 48px;
        font-weight: 600;
        padding: 12px;
        border-radius: 8px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
    }

    .search-parametrs-line[data-v-150035d6] {
        min-width: 20px;
        width: calc(100% - 40px);
        max-width: 80px;
        margin: 0 20px;
        height: 2px;
    }

    .search-parametrs-min::before,
    .search-parametrs-max::before {
        width: 13px;
        height: 13px;
        min-width: 13px;
        min-height: 13px;
        margin: 0 8px 0 0;
    }

    .shop-search-parametrs {
        margin: 0 0 15px 0;
    }

    .search-parametrs-container {
        margin: 0 0 0 0;
    }

    .slider {
        display: none;
    }

    .shop-filter-block {
        margin: 0 0 10px 0;
    }

    .shop-filter-img {
        width: 14px;
    }

    .shop-filter-arrow {
        width: 10px;
    }

    .shop-filter-text {
        margin: 0 9px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 137.6%;
        letter-spacing: -0.28px;
        user-select: none;
    }

    .shop-filter-popup {
        position: absolute;
        width: fit-content;
        height: fit-content;
        top: 25px;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    }

    .shop-popup-item {
        margin: 0 0 10px 0;
    }

    .shop-popup-item input {
        width: 14px;
        height: 14px;
    }

    .shop-popup-item label {
        margin: 0 0 0 10px;
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 137.6%;
        letter-spacing: -0.24px;
    }

    input+label::before {
        transform: translate(calc(-100% - 10px), 30%);
        width: 14px;
        height: 14px;
        border: 1px solid #ffffff;
    }

    input+label::after {
        transform: translate(-260%, 90%);
        width: 8px;
        height: 8px;
    }

    .shop-enable-container {
        margin: 0 0 20px 0;
        color: #898FB0;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 137.6%;
    }

    .shop-enable-change {
        color: #898FB0;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 137.6%;
        padding: 0px 0px;
    }

    .shop-container-items {
        width: 100%;
        height: 100%;
        justify-content: flex-start;
    }

    .shop-container-items .inventory-item {
        margin: 0 4px 4px 4px;
    }

    .inventory-item {
        width: calc(120px - 8px);
        height: 120px;
    }



    .shop-panel-buy {
        position: fixed;
        bottom: 0;
        height: 70px;
        min-height: 70px;
        padding: 10px;
        margin: 0 0 0 0;
    }

    .btn-buy {
        padding: 0 20px;
        border-radius: 8px;
        font-size: 14px;
    }

    .panel-buy-title {
        font-size: 14px;
    }

    .panel-buy-cost {
        font-size: 16px;
        font-weight: 700;
    }

    .panel-buy-cost::before {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        margin: 0 4px 0 0;
    }


    .shop-container-items.change {
        height: calc(100% - 370px);
    }

    .shop-container-items.active.change {
        height: calc(100% - 170px);
    }


    .shop-enable-container {
        width: 100%;
        min-height: 35px;
    }

    .shop-enable-block {
        flex-direction: row;
        justify-content: flex-start;
        margin: 0 10px 0 0;
        color: rgba(95, 232, 149, 1);
        background: rgba(95, 232, 149, 0.1);
        border-radius: 10px;
        padding: 6px 9px;
        font-size: 14px;
    }

    .shop-enable-cancel {
        font-size: 14px;
    }





    .fadeUp-enter-active {
        transform: translate(0%, 0%);
        opacity: 1;
        transition: all 0.25s ease;
    }

    .fadeUp-leave-active {
        transform: translate(0%, -10px);
        margin: -180px 0 0 0;
        opacity: 0;
        transition: all 0.25s ease;
    }

    .fadeUp-enter-from {
        transform: translate(0%, -10px);
        margin: -180px 0 0 0;
        opacity: 0;
        transition: all 0.25s ease;
    }

    .fadeUp-leave-to {
        transform: translate(0%, -10px);
        margin: -180px 0 0 0;
        opacity: 0;
        transition: all 0.25s ease;
    }
}
</style>