<script>
import elements_chat_item from "@/components/elements/elements_chat_item.vue";
import { useGameStore } from '@/stores/interface-interaction.js';
export default {
  name: "elements_chat",
  data() {

    let game = useGameStore();
    return {
      game: game,
      mesText: '',
      answerTo: undefined
    }
  },
  components: {
    elements_chat_item,
  },
  methods: {
    async sendMessage() {
      try {
        if (this.game.authorization && this.game.user) {
          await this.game.sendChatMessage(this.mesText, this.answerTo);
          this.mesFull = '';
        } else {
          throw ({ message: 'games.notauthorized' });
        }
      } catch (e) {
        this.$toast.error(this.$t(e.message));
      }

    },
    setAnswer(answerTo) {
      console.log(answerTo);
      this.answerTo = answerTo;
    },
    mute(authorId) {
      if (authorId == this.game.user?.id) {
        this.$toast.error(this.$t("toasts.notSelfMute"));
        return
      }
      this.game.chat.muted.push(authorId);
    }
  },
  computed: {
    sortedMessages() {
      let messages = this.game.chat.messages.filter(m => !this.game.chat.muted.includes(m.authorId));
      messages.reverse();

      messages.sort((a, b) => {
        let aC = (new Date(a.createdAt)).getTime();
        let bC = (new Date(b.createdAt)).getTime();
        return bC - aC;
      })

      return messages;
    },
    mesFull: {
      get() {
        if (this.answerTo) {
          return `@${this.answerTo ?? ''}, ${this.mesText}`;
        } else {
          return this.mesText;
        }

      },
      set(value) {
        value = String(value);
        if (value.includes('@') && value.includes(',')) {

          let answerTo = value.substring(value.indexOf('@') + 1, value.indexOf(','))

          this.answerTo = answerTo;
          this.mesText = value.substring(value.indexOf(',') + 2);


        } else {
          this.answerTo = undefined;
          this.mesText = value;
        }




      }
    }
  },
  mounted() {
    this.game.setBodyScroll(false);
  },
  unmounted() {
    this.game.setBodyScroll(true);
  }
};
</script>

<template>
  <div class="chat-social-main">
    <div class="chat-social-container">
      <transition-group name="fade">
        <elements_chat_item v-for="mes in sortedMessages" :key="mes.id" :message="mes"
          @setAnswer="setAnswer($event)" @mute="mute(mes.authorId)"
          :class="{ 'streamer-blur': game.settingsSite.streamer }" />
      </transition-group>
    </div>
    <div class="container-push-message mobile">
      <div class="chat-info-block mobile">
        <div class="chat-online">
          {{ game.chat.usersOnline }} {{$t('chat.online')}}
        </div>
      </div>
      <div class="chat-input-message mobile">
        <div class="chat-input">
          <input type="text" :placeholder="$t('chat.message')" @keyup.enter="sendMessage" v-model="mesFull">
        </div>
        <div class="chat-send" @click="sendMessage">
          <img src="/src/assets/img/chat/arrowWhite.svg" alt="hide">
        </div>
      </div>
    </div>
    <div class="chat-info-block">
      <div class="chat-online">
        {{ game.chat.usersOnline }} {{$t('chat.online')}}
      </div>
    </div>
    <div class="chat-input-message">
      <div class="chat-input">
        <input type="text" :placeholder="$t('chat.message')" @keyup.enter="sendMessage" v-model="mesFull">
      </div>
      <div class="chat-send" @click="sendMessage">
        <img src="/src/assets/img/chat/arrowWhite.svg" alt="hide">
      </div>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active {
  transform: translate(0%, 0%);
  opacity: 1;
  transition: all 0.25s ease;
}

.fade-leave-active {
  transform: translate(0px, 100%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-enter-from {
  transform: translate(0px, 100%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-leave-to {
  transform: translate(0px, 100%);
  transition: all 0.25s ease;
}




.chat-social-main {
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
}

.chat-info-block {
  width: calc(100% - 2vw);
  height: 1vw;
  margin: 1vw 0 0.7vw 0;
  justify-content: space-between;
  flex-direction: row;
}

.chat-rules {
  font-family: "VelaSans", sans-serif;
  font-size: 0.8vw;
  font-weight: 700;
  color: #A1A5CD;
}

.chat-online {
  font-family: "VelaSans", sans-serif;
  font-size: 0.8vw;
  font-weight: 700;
  flex-direction: row;
  color: #4CEC8C;
}

.chat-online::before {
  content: "";
  width: 0.2vw;
  height: 0.2vw;
  margin: 0 0.2vw 0 0;
  border-radius: 50%;
  background: #4CEC8C;
}

.chat-input-message {
  width: calc(100% - 2vw);
  height: 3vw;
  padding: 0 0.5vw 0 0.8vw;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 0.7vw;
  background-color: #444861;
}

.chat-send {
  width: 2.1vw;
  height: 2.1vw;
  padding: 0.5vw;
  border-radius: 0.4vw;
  background-color: #638CF3;
  transition: all 0.25s ease;
  cursor: pointer;
}

.chat-send:hover {
  background-color: #638cf388;
}

.chat-send img {
  transition: all 0.25s ease;
}

.chat-send:hover img {
  transform: translate(0.2vw, 0);
}

.chat-input {
  width: calc(100% - 2.8vw);
  margin: 0 0.5vw 0 0;
}

input {
  width: 100%;
  height: 100%;
  font-family: "VelaSans", sans-serif;
  font-size: 0.9vw;
  font-weight: 700;
  color: #A1A5CD;
  outline: none;
  background: none;
  border: none;
}

input::placeholder {
  font-family: "VelaSans", sans-serif;
  font-size: 0.9vw;
  font-weight: 700;
  color: #A1A5CD;
}

.chat-social-container {
  width: calc(100% - 2vw);
  height: calc(100% - 5.7vw);
  flex-direction: column-reverse;
  overflow: auto;
  justify-content: flex-start;
}

.chat-social-container::-webkit-scrollbar-track {
  -webkit-box-shadow: 0 0 0 0 rgba(34, 60, 80, 0) inset;
  background-color: rgba(255, 255, 255, 0.0);
  border-radius: 0;
}

.chat-social-container::-webkit-scrollbar-thumb {
  border-radius: 0vw;
  background: none;
}

.container-push-message.mobile {
  display: none;
}


@media (max-width: 1024px) {

  .container-push-message.mobile {
    position: fixed;
    display: flex;
    bottom: 0;
    width: calc(100% - 0px);
    padding: 0 10px 10px 10px;
    background-color: #363A50;
    transition: all 0.25s ease;
  }

  .chat-info-block.mobile {
    display: flex;
  }

  .chat-input-message.mobile {
    display: flex;
  }

  .chat-info-block {
    display: none;
  }

  .chat-input-message {
    display: none;
  }

  .chat-social-main {
    justify-content: flex-start;
    height: 100%
  }

  .chat-social-container {
    width: 100%;
    height: calc(100% - 120px);
  }

  .chat-info-block {
    /* position: fixed;
    bottom: 60px;
    width: calc(100% - 20px); */
    width: 100%;
    height: 20px;
    margin: 20px 0 15px 0;
    justify-content: space-between;
    flex-direction: row;
    transition: all 0.25s ease;
  }

  .chat-rules {
    color: #A1A5CD;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 137.6%;
  }

  .chat-online {
    color: #4CEC8C;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 137.6%;
  }

  .chat-online::before {
    content: "";
    width: 4px;
    height: 4px;
    margin: 0 5px 0 0;
  }


  .chat-input-message {
    /* position: fixed;
    bottom: 10px;
    width: calc(100% - 20px); */
    width: 100%;
    height: 60px;
    padding: 0 8px;
    border-radius: 14px;
    transition: all 0.25s ease;
  }

  input,
  input::placeholder {
    color: #A1A5CD;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.36px;
  }

  .chat-send {
    width: 39px;
    height: 39px;
    padding: 10px;
    border-radius: 8px;
  }
}
</style>