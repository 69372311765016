

<script>
import screen_main from "./components/screen_main.vue";
import { useGameStore } from '@/stores/interface-interaction.js';
export default {
  name: "App",
  components:{
    screen_main
  },
  beforeCreate(){
    let interaction = useGameStore();
    interaction.initAll(this.$toast);
    interaction.loadUser().then(r => console.log(r));
    interaction.checkSupportAuth().catch().then(()=>{
      interaction.connectAdmin();
      interaction.connectSupportWorker();
    });
    this.$i18n.locale = interaction.settingsSite.locale;

    interaction.windowWidth = window.innerWidth;
    window.onresize = () =>{
      interaction.windowWidth = window.innerWidth
    }



    this.$router.beforeEach(async (to, from, next) => {
      const isAdmin = interaction.isAdmin;
      const reqAuth = to.matched.some((record) => record.meta.requiresAdmin);
      const loginQuery = { name: 'main' };

      if (reqAuth && !isAdmin) {
        try {
          await interaction.checkSupportAuth();
          next()
        } catch {
          next(loginQuery);
        }

      } else {
        next();
      }

    });
  },
  mounted () {
    let interaction = useGameStore();
      if (!interaction.isMobile) {
        document.onclick = () =>{
          interaction.playSound('click');
        }
      }
  },
};
</script>
<template>
  <transition name="fade" mode="out-in" appear>
    <screen_main/>
  </transition>
</template>

<style scoped>
.fade-enter-active {
  transform: translate(0%, 0%);
  opacity: 1;
  transition: all 1.0s ease;
}

.fade-leave-active {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 1.0s 0.25s ease;
}

.fade-enter-from {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 1.0s 0.25s ease;
}

.fade-leave-to {
  transform: translate(0%, 0%);
  transition: all 1.0s 0.25s ease;
}
</style>
