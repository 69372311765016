<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import elements_game_modes from "@/components/elements/elements_game_modes.vue";
import cases_container from "@/components/elements/cases/cases_container.vue";
import interface_slide from "@/components/interface/interface_slide.vue";
import component_footer from "@/components/component_footer.vue";

import component_menu from "@/components/component_menu.vue";
import component_chat from "@/components/component_chat.vue";

export default {
  name: "block_mainScreen",
  data() {
    let interaction = useGameStore();
      return {
        arraySlider:[
          {
            id: 1 ,
            title: "slide.title1",
            subTitle: "slide.descr1",
            src: "/slider/slide1n.png",
            link: 'https://vk.com/topic-221074435_49390632',
          },
          {
            id: 2,
            title: "slide.title2",
            subTitle: 'slide.descr2',
            src: "/slider/slide2n.png",
            link: '/referralSystem',
          },
          {
            id: 3,
            title: "slide.title3",
            subTitle: 'slide.descr3',
            src: "/slider/slide3n.png",
            link: '/dailyBonuses#linksBounse',
          },
        ],
        sliderIndex: 1000,
        interaction: interaction,
      };
  },
  components: {
    elements_game_modes,
    component_footer,
    component_menu,
    component_chat,
    interface_slide,
    cases_container,
  },
  methods: {

    onSwiper(swiper){
        this.swiper = swiper;
    },
    prevSlide() {
        this.swiper?.slidePrev();
    },
    nextSlide() {
        this.swiper?.slideNext();
    },
  },
  mounted() {

  },
  computed:{

  },
};
</script>


<template>
  <div class="wrapper">
    <component_menu/>
    <div class="screen-main" :class="{active: interaction.chatStatus}">
      <div class="screen-top">
        <div class="screen-slider">
          <transition name="changeSlide" appear>
            <interface_slide 
            :arraySlide="arraySlider"
            @swiper="onSwiper"
            />
          </transition>
          <div class="slider-arrow-container">
            <div class="arrow left" @click="prevSlide()">
              <img src="/src/assets/img/main/arrow.svg" alt="arrow"/>
            </div>
            <div class="slide-minis" ref="pointSlider">
            </div>
            <div class="arrow right" @click="nextSlide()">
              <img src="/src/assets/img/main/arrow.svg" alt="arrow"/>
            </div>
          </div>
        </div>
        <div class="screen-bonus" @click="$router.push({name:'daily'})">
          <div class="bonus-title">
            <span>{{$t('slide.screen1')}}</span>
            <span class="purple">{{$t('slide.screen2')}}</span>
            <span>{{$t('slide.screen3')}}</span>
          </div>
          <!-- <div class="bonus-text">
            {{$t('slide.sdescr1')}}<br>{{$t('slide.sdescr2')}}
          </div> -->
        </div>
      </div>
      <div class="battlepassContainer" @click="$router.push({name:'battlepass'})">
        <div class="battlepassTextBlock">
          <div class="battlepassText1">Battle</div>
          <div class="battlepassText2">PASS</div>
          <div class="battlepassText3">Открывай ячейки и получай призы!</div>
        </div>
      </div>
      <div class="screen-game-modes">
        <div class="game-modes-title">
          <div class="game-modes-img">
            <img src="/src/assets/img/main/iconGame.svg" alt="game">
          </div>
          <div class="game-modes-name">
            {{ $t('slide.gamemod') }}
          </div>
        </div>
        <div class="game-modes-container">
          <elements_game_modes
            @click="$router.push({name:'crash'})" 
            :img = "`/main/crashbg.svg`"
            title = "Crash"
            :number = interaction.crashSummary.bidSum
            :class = "``"
            />
          <elements_game_modes 
            @click="$router.push({name:'wheel'})"
            :img = "`/main/whellbg.svg`"
            title = "Wheel"
            :number = interaction.wheelSummary.generalSum
            :class = "``"
            />
          <elements_game_modes 
            @click="$router.push({name:'coin'})"
            :img = "`/main/coinbg.svg`"
            title = "Coin"
            :number = interaction.coinSummary.generalSum
            :class = "``"
            />
          <!-- <elements_game_modes 
            @click="$router.push({name:'boost'})"
            :img = "`/main/boostbg.svg`"
            title = "Boost"
            :number = 0
            />
          <elements_game_modes 
            @click="$router.push({name:'sapper'})"
            :img = "`/main/sapperbg.svg`"
            title = "Sapper"
            :number = 0
            />
          <elements_game_modes 
            @click="$router.push({name:'support'})"
            :img = "`/main/supportbg.svg`"
            title = "Поддержка"
            :number = 0 
            :class = "`support`"
            /> -->
            <cases_container newGameMode @click="$router.push({name:'cases'})"/>
        </div>
      </div>
      <div class="screen-bottom">
        <component_footer/>
      </div>      
    </div>
    <component_chat/>
  </div>
</template>

<style scoped>
.changeSlide-enter-active {
  opacity: 1;
  transition: all 0.25s ease;
}

.changeSlide-leave-active {
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.25s ease;
}

.changeSlide-enter-from {
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.25s ease;
}

.changeSlide-leave-to {
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.25s ease;
}

.battlepassContainer {
  max-width: 70vw;
  width: 100%;
  height: 17vw;
  border-radius: 1.5vw;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 3.02vw 2.6vw 2.6vw 2.6vw;
  background-image: url(/game/battlepass/battlepass.png);
  background-size: cover;
  background-position: center;
  margin: 1vw 0px 0px 0px;
  cursor: pointer;
}

.battlepassTextBlock {
  flex-direction: column;
  align-items: flex-start;
}

.battlepassText1 {
  font-family: "HalvarBreit", sans-serif;
  font-size: 1.3vw;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: calc(1.3vw / 100 * 10);
  margin: 0px 0px 0.75vw 0px;
  color: #DBE0FF;
}
.battlepassText2 {
  font-family: "HalvarBreitB", sans-serif;
  left: -0.1vw;
  font-size: 3.75vw;
  line-height: 1;
  color: #DBE0FF;
  margin: 0px 0px 0.5vw 0px;
}
.battlepassText3 {
  font-size: 0.94vw;
  font-weight: 600;
  color: #DBE0FF;
}



  .wrapper{
    width: 100%;
    height: fit-content;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    transition: all 0.5s ease;
  }


  .screen-main.active{
    width: calc((100% - 6vw - 0px - 1.5vw));
    min-width: calc((100% - 6vw - 0px - 1.5vw));
    margin: 0 0 0 1.5vw;
    transition: all 0.5s 0.1s ease;
  }
  /*
  .screen-main.active .screen-top .screen-slider, .screen-main.active .screen-top .screen-bonus{
    height: 20vw;
  }
  .screen-main.active .screen-top .screen-bonus:hover .bonus-img{
    transform: translate(5%, 35%);
  }
  .screen-main.active .screen-game-modes .game-modes-container .game-modes-item{
    height: 16vw;
  }
  .screen-main.active .screen-top .screen-bonus .bonus-img{
    width: 22vw;
    transform: translate(5%, 40%);
  } */
  .screen-main{
    width: calc((100% - 6vw - 19vw - 3vw));
    min-width: calc((100% - 6vw - 19vw - 3vw));
    margin: 0 1.5vw;
    height: fit-content;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.5s ease;
  }
  .screen-top{
    max-width: 70vw;
    width: 100%;
    border-radius: 1.5vw;
    overflow: hidden;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }
  .screen-slider{
    width: calc(70% - 1vw);
    margin: 0 1vw 0 0;
    height: 16vw;
    /* background: linear-gradient(35.71deg, #2BD177 21.94%, #3FD182 66.76%, #72F1AC 98.7%); */
    border-radius: 1.5vw;
    overflow: hidden;
    transition: all 0.25s ease;
  }
  .screen-bonus{
    width: 30%;
    min-width: 15vw;
    height: 16vw;
    background: linear-gradient(100.02deg, #CD84FD 0.81%, #6355B0 99.5%);
    background-image: url(/src/assets/img/main/bonus.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1.5vw;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.25s ease;
  }
  .screen-bonus:hover .bonus-img{
    transform:translate(10%, 35%);
  }
  .bonus-title{
    position: absolute;
    align-items: flex-start;
    top: 1.5vw;
    left: 1.5vw;
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    font-size: 2vw;
    font-weight: 900;
    line-height: 2vw;
  }
  .bonus-title span {
    font-weight: 800;
  }
  .purple {
    color: #9F7FE9;
  }
  .bonus-text{
    position: absolute;
    top: 6vw;
    left: 1.5vw;
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    font-size: 1vw;
    font-weight: 900;
  }
  .bonus-img{
    position: absolute;
    width: 14vw;
    bottom: 0;
    right: 0;
    transform:translate(10%, 40%);
    transition: all 0.25s ease;
  }

  .slider-item{
    width: 100%;
    height: 100%;
  }

.slider-arrow-container{
  position: absolute;
  right: 2vw;
  bottom: 1vw;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2;
}
.arrow{
  width: 1.5vw;
  height: 1.5vw;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0.4vw;
  cursor: pointer;
  transition: all 0.25s ease;
}
.arrow:hover{
  background-color: rgba(255, 255, 255, 1.0);
}
.arrow img{
  width: 0.7vw;
  height: 0.7vw;
}
.arrow.left{
  transform: rotate(180deg);
}
.arrow.right{
  transform: rotate(0deg);
}
.slide-minis{
  min-width: 2.3vw;
  flex-direction: row;
  margin: 0 0.3vw;
}
.slide-minis span.active{
  width: 1vw;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.5vw;
}
.slide-minis span{
  width: 0.5vw;
  height: 0.5vw;
  border-radius: 50%;
  margin: 0 0.1vw;
  background-color: rgba(255, 255, 255, 0.7);
  transition: all 0.25s ease;
}


  
  .slider-content{
    width: calc(100% - 2vw);
    height: calc(100% - 2vw);
    padding: 1vw;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 2;
  }
  .slider-title{
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    font-size: 2vw;
    font-weight: 700;
    line-height: 2vw;
  }
  .slider-text{
    margin: 0.7vw 0 0 0;
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    font-size: 0.9vw;
    font-weight: 500;
  }

  .slider-bg{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .slider-bg img{
    height: 100%;
    object-fit: cover;
  }



  .screen-game-modes{
    max-width: 70vw;
    width: 100%;
    height: fit-content;
    margin: 3vw 0 50px 0;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .game-modes-title{
    width: 100%;
    margin: 0 0 1.3vw 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .game-modes-img{
    width: 1vw;
    height: 1vw;
    margin: 0 0.5vw 0 0;
  }
  .game-modes-name{
    color: #7B7FA3;
    font-family: "VelaSans", sans-serif;
    font-size: 1.3vw;
    font-weight: 600;
  }
  .game-modes-container{
    width: 100%;
    gap: 0.4vw;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }


  .screen-bottom{
    max-width: 70vw;
    width: 100%;
  }

  @media (max-width: 1640px) {

  }
  @media (max-width: 1440px) {
    /* .screen-main{
      width: calc((100% - 6vw - 0px - 1.5vw));
      min-width: calc((100% - 6vw - 0px - 1.5vw));
      margin: 0 0 0 1.5vw;
      transition: all 0.5s ease;
    } */
    /* .screen-main.active .screen-top .screen-slider, .screen-main.active .screen-top .screen-bonus{
    height: 16vw;
    }
    .screen-main.active .screen-game-modes .game-modes-container .game-modes-item{
      height: calc(13vw - 0.8vw);
    }
    .screen-main.active .screen-top .screen-bonus .bonus-img{
      width: 14vw;
    } */
  }
  @media (max-width: 1280px) {

  }
  @media (max-width: 1024px) {
    .battlepassContainer {
      max-width: unset;
      width: 100%;
      height: 200px;
      border-radius: 20px;
      padding: 10px 20px;
      margin: 10px 0px 0px 0px;
    }

    .battlepassText1 {
      font-size: 18px;
      margin: 0px 0px 2px 0px;
    }
    .battlepassText2 {
      font-size: 55px;
      margin: 0px 0px 10px 0px;
    }
    .battlepassText3 {
      font-size: 15px;
    }

    .game-modes-container {
      gap: 10px;
    }
  }
  @media (max-width: 980px) {

  }
  @media (max-width: 1024px) {
    .screen-main, .screen-main.active{
      width: calc((100% - 0px - 0px - 0vw));
      min-width: calc((100% - 0px - 0px - 0vw));
      margin: 0 0 0 0vw;
      transition: all 0.5s ease;
    }
    .screen-top, .screen-bottom, .screen-game-modes{
      max-width: 100%;
    }







    .screen-top{
      border-radius:20px;
      margin: 30px 0 0 0;
      flex-direction: column;
    }
    .screen-slider{
      width: 100%;
      height: 260px;
      margin: 0 0 10px 0;
      border-radius: 20px;
    }

    .slider-arrow-container{
      right: 20px;
      bottom: 20px;
    }
    .slide-minis{
      min-width: 3.3vw;
      flex-direction: row;
    }
    .slide-minis span.active{
      width: 25px;
      border-radius: 10px;
    }
    .slide-minis span {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 2px;
      transition: all 0.25s ease;
    }

    .slider-arrow-container .arrow{
      display: none;
    }
    .screen-bonus{
      width: 100%;
      height: 200px;
      border-radius: 20px;
    }
    .bonus-title{
      top: 10px;
      left: 20px;
      color: #FFF;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: -0.44px;
    }
    .bonus-text{
      max-width: 55%;
      top: 70px;
      left: 20px;
      font-size: 14px;
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }

    .screen-game-modes{
      margin: 30px 0 90px 0;
    }
    .game-modes-title{
      margin: 0 0 12px 0;
    }
    .game-modes-name{
      color: #7B7FA3;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 137.6%;
      letter-spacing: -0.4px;
    }
    .game-modes-img{
      width: 16px;
      height: 11px;
    }

    .slider-content{
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      padding: 10px;
    }

  }
  @media (max-width: 640px) {

  }
  @media (max-width: 480px) {
    .battlepassContainer {
      background: linear-gradient(0deg, #1b1e2d, transparent), url(/game/battlepass/battlepass.png);
      background-size: cover;
      background-position: center;
    }
  }
  @media (max-width: 420px) {

  }
  @media (max-width: 360px) {
    .screen-slider{
      width: 100%;
      height: 300px;
      margin: 0 0 10px 0;
      border-radius: 20px;
    }
  }
</style>
