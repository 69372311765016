export function parseItemData(item, interaction) {
    if(!item){
        return undefined;
    }
    if(!item.template){
        item = { ...item, template: item }
    }
    const price = +(item.template.price/1000 * interaction.BALANCE_BY_USD).toFixed(2);

    const fullName = item.name ?? '???';

    const mainName = fullName?.split(" | ")[0] ?? "";
    const secondName = fullName.split(" | ")[1]?.replace(/ *\([^)]*\) */g, "") ?? "";

    const status = getStatus(fullName);
    const statusShort = getStatusShort(item.name);

    const img = item.template.img;

    const color = item.template.rarity_color ?? '#B0C3D9';


    return {
        price,
        fullName,
        mainName,
        secondName,
        status,
        statusShort,
        img,
        color
    }
}

function getStatus(fullName){
    let match = fullName.split(" | ")[1]?.match(/\((?<status>.*?)\)/);    
    return (match && match.groups['status']) ?? "";
}

function getStatusShort(name){
    let enMatch = name.split(" | ")[1]?.match(/\((?<status>.*?)\)/);
    let match = (enMatch && enMatch.groups['status']) ?? "";

        switch (String(match).toLowerCase()) {
            case "factory new":
                return "FN"
            case "minimal wear":
                return "MW"
            case "field-tested":
                return "FT"
            case "well-worn":
                return "WW"
            case "battle-scarred":
                return "BS"

            default:
                return "";
        }
}
