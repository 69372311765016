<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import elements_gameIcon from "../components/elements/elements_gameIcon.vue";
export default {
  name: "component_menu",
  data() {
    let interaction = useGameStore();
    return{
        interaction: interaction,
    }
  },
  components: {
    elements_gameIcon,
  },
  methods: {

  },
  computed:{
    isInternal(){
        return this.interaction.isAdmin && this.$route.matched.some((route)=>{
            return route.name === 'internal'
        })
    },
  },
  mounted() {

  }
};
</script>


<template>
    <div class="menu-conteiner" v-if="!isInternal">
        <div class="menu-game">
            <elements_gameIcon @click="$router.push({name:'crash'})"    :isActive = "$route.name == 'crash'" name="Crash" img="/menu/crash.svg" imgActive="/menu/crashActive.svg"/>
            <elements_gameIcon @click="$router.push({name:'wheel'})"    :isActive = "$route.name == 'wheel'" name="Wheel" img="/menu/whell.svg" imgActive="/menu/whellActive.svg"/>
            <elements_gameIcon @click="$router.push({name:'coin'})"     :isActive = "$route.name == 'coin'" name="Coin" img="/menu/coin.svg" imgActive="/menu/coinActive.svg"/>
            <elements_gameIcon @click="$router.push({name:'cases'})"    :isActive = "$route.name == 'cases' || $route.name == 'open'" name="Cases" img="/menu/cases1.svg" imgActive="/menu/cases1Active.svg"/>
            <elements_gameIcon @click="$router.push({name:'battlepass'})"    :isActive = "$route.name == 'battlepass'" name="Battlepass" img="/menu/battlepass.svg" imgActive="/menu/battlepassActive.svg" battlepass/>
<!--            <elements_gameIcon @click="$router.push({name:'sapper'})"   :isActive = "$route.name == 'sapper'" name="Sapper" img="/menu/sapper.svg" imgActive="/menu/sapperActive.svg"/>-->
        </div>
        <div class="menu-social">
            <a href="https://instagram.com/_cssweets_csgo?igshid=NTc4MTIwNjQ2YQ==" class="social">
                <img src="/src/assets/img/menu/instagram.svg" alt="instagram">
            </a>
            <a href="https://t.me/CSSWEETS1" class="social">
                <img src="/src/assets/img/menu/telegram.svg" alt="telegram">
            </a>
            <a href="https://vk.com/public221074435" class="social">
                <img src="/src/assets/img/menu/vk.svg" alt="vk">
            </a>
            <a href="https://discord.gg/dKSkUeyVaJ" class="social">
                <img src="/src/assets/img/menu/discord.svg" alt="discord">
            </a>
        </div>
    </div>
    <div class="menu-conteiner" v-else>
        <div class="menu-game internal">

            <router-link :to="{name: 'internal_bots'}" active-class="active">
                        <elements_gameIcon @click="$router.push({name:'internal_bots'})"              :isActive = "$route.name == 'internal_bots'"         name="Боты"         img="/menu/bots.svg"       imgActive="/menu/botsActive.svg"/>
            </router-link>
            <router-link :to="{name: 'internal_promocode'}" active-class="active">
                        <elements_gameIcon @click="$router.push({name:'internal_promocode'})"         :isActive = "$route.name == 'internal_promocode'"    name="Промо-код"   img="/menu/promocode.svg"   imgActive="/menu/promocodeActive.svg"/>
            </router-link>
            <router-link :to="{name: 'internal_support'}" active-class="active">
                        <elements_gameIcon @click="$router.push({name:'internal_support'})"  :isActive = "$route.name == 'internal_support'"       name="Тикеты"      img="/menu/support.svg"    imgActive="/menu/supportActive.svg"/>
            </router-link>
            <router-link :to="{name: 'internal_faq'}" active-class="active">
                        <elements_gameIcon @click="$router.push({name:'internal_support'})"  :isActive = "$route.name == 'internal_faq'"       name="FAQ"      img="/menu/support.svg"    imgActive="/menu/supportActive.svg"/>
            </router-link>
            <router-link :to="{name: 'internal_statistic'}" active-class="active">
                        <elements_gameIcon @click="$router.push({name:'internal_statistic'})"         :isActive = "$route.name == 'internal_statistic'"     name="Статистика"  img="/menu/statistic.svg"  imgActive="/menu/statisticActive.svg"/>
            </router-link>
            <router-link :to="{name: 'internal_users'}" active-class="active">
                        <elements_gameIcon @click="$router.push({name:'internal_users'})"             :isActive = "$route.name == 'internal_users'"         name="Игроки"      img="/menu/users.svg"      imgActive="/menu/usersActive.svg"/>
            </router-link>

          <router-link :to="{name: 'internal_cases'}" active-class="active">
            <elements_gameIcon @click="$router.push({name:'internal_cases'})"                         :isActive = "$route.name == 'internal_cases'"         name="Кейсы"      img="/menu/cases.svg"      imgActive="/menu/casesActive.svg"/>
          </router-link>

        </div>
    </div>
</template>

<style scoped>
    .menu-conteiner{
        width: 6vw;
        min-width: 6vw;
        min-height: 40vw;
        height: 40vw;
        background-color: #363A50;
        box-shadow: 0 0 0.5vw rgba(0, 0, 0, 0.2);
        border-radius: 1vw;
        justify-content: space-between;
        padding: 1.8vw 0;
        position:-webkit-sticky;
        position:sticky;
        top: 7vw;
        z-index: 2;
        transition: all 0.45s ease;
    }
    .menu-game.internal a{
        width: 100%;
    }
    .menu-game{
        width: 100%;
        height: fit-content;
    }
    .menu-social{
        width: 100%;
        height: fit-content;
        margin: 1.5vw 0 0 0;
    }
    .social{
        width: 1.2vw;
        margin: 0.7vw 0;
        transition: all 0.2s ease;
    }
    .social:hover{
        transform: scale(1.19);
    }





@media (max-width: 1640px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1024px) {

}
@media (max-width: 980px) {

}
@media (max-width: 1024px) {
    .menu-conteiner{
        display: none;
    }
}
@media (max-width: 640px) {

}
@media (max-width: 480px) {

}
@media (max-width: 420px) {

}
@media (max-width: 380px) {

}
</style>

