<script>
import { useGameStore } from '@/stores/interface-interaction.js';
export default {
  name: "notifications_item",
  props:{
    notice: Object,
  },
  data() {
    let interaction = useGameStore();
      return {
        interaction: interaction,
      };
  },
  components: {

  },
  methods: {

  },
  mounted() {
    
  },
  computed:{
    status(){
      return this.notice.extendedData.success ?? false
    },
    date(){
      return new Date(this.notice.createdAt).toLocaleTimeString();
    },
    text(){
      return this.notice.text;
    },
    amountCost(){
      return this.notice.extendedData.sum;
    },
    depositId(){
      return this.notice.extendedData.deposit_id;
    },
    isDeposit(){
      return Boolean(this.depositId);
    },
    method(){
      return this.notice.extendedData.method ?? "";
    },
    amountBounce(){
      return this.notice.extendedData.bonus;
    },
  }
};
</script>


<template>
    <div class="notifications-Item" :class="{viewed: notice.viewed}">
        <transition name="fade" mode="out-in" appear>
            <div class="notifications-Item-block-status" v-if="status">
                <img src="/src/assets/img/notifications/ok.svg" alt="ok">
            </div>
            <div class="notifications-Item-block-status" v-else>
                <img src="/src/assets/img/notifications/no.svg" alt="no">
            </div>
        </transition>
        <div class="notifications-Item-block-text" v-if="isDeposit">
            <span>{{$t('not.not1')}} </span>
            <span>{{ amountCost * this.interaction.BALANCE_BY_USD}}</span>
            <span>{{$t('not.not2')}}</span>
        </div>
        <div class="notifications-Item-block-text" v-else>
            <span>{{$t('not.not3')}} </span>
            <span>{{ amountCost * this.interaction.BALANCE_BY_USD}}</span>
        </div>
        <div class="notifications-Item-block-date">
            {{ date }}
        </div>
    </div>
</template>

<style scoped>
.fade-enter-active {
  transform: translate(0%, 0%);
  opacity: 1;
  transition: all 0.25s ease;
}

.fade-leave-active {
  transform: translate(0%, 30%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-enter-from {
  transform: translate(0%, 30%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-leave-to {
  transform: translate(0%, 30%);
  transition: all 0.25s ease;
}


.notifications-Item.viewed{
  background: #3F435B;
}
.notifications-Item{
    width: calc(100% - 0.6vw);
    height: fit-content;
    padding: 0.7vw;
    margin: 0 0 0.5vw 0;
    background: #595f80;
    border-radius: 0.5vw;
    flex-direction: row;
    justify-content: space-between;
}
.notifications-Item-block-status{
    width: 1.4vw;
    height: 1.4vw;
    margin: 0 0.7vw 0 0;
}
.notifications-Item-block-text{
    width: calc(100% - 1.4vw - 0.7vw - 2.2vw - 0.5vw);
    height: fit-content;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 0.5vw 0 0;
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 600;
}
.notifications-Item-block-text span{
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
}
.notifications-Item-block-text span:nth-child(2){
  margin: 0 0.5vw;
}
.notifications-Item-block-text span:nth-child(2)::before{
  content: "";
  width: 0.9vw;
  height: 0.9vw;
  min-width: 0.9vw;
  min-height: 0.9vw;
  margin: 0 0.2vw 0 0;
  background-image: url(/coin.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.notifications-Item-block-date{
    width: 2.2vw;
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.5vw;
    font-weight: 600;
}



@media (max-width: 1024px) {
  .notifications-Item{
    width: calc(100% - 0px);
    padding: 10px;
    margin: 0 0 5px 0;
    border-radius: 12px;
  }
  .notifications-Item-block-status{
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
  }
  .notifications-Item-block-text{
    width: calc(100% - 85px);
    margin: 0 10px 0 0;
    color: #DBE0FF;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 137.6%;
  }
  .notifications-Item-block-date {
    width: 55px;
    color: #DBE0FF;
    text-align: right;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 137.6%;
  }


  .notifications-Item-block-text span:nth-child(2)::before {
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    margin: 0 2px 0 2px;
  }
}
</style>
