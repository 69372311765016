import gsap from 'gsap';

export function debounce(fn, wait){
    let timer;
   return function(...args){
     if(timer) {
        clearTimeout(timer); // clear any pre-existing timer
     }
     const context = this; // get the current context
     timer = setTimeout(()=>{
        fn.apply(context, args); // call the function if time expires
     }, wait);
   }
}

export function throttle(fn, wait){
    let throttled = false;
    return function(...args){
        if(!throttled){
            fn.apply(this,args);
            throttled = true;
            setTimeout(()=>{
                throttled = false;
            }, wait);
        }
    }
}

export function animate(obj, params, {duration, ease, onUpdate = null}){

    const easingFunc = gsap.parseEase(ease);
    const animation = {
      // progress: 0,
      obj: obj,
      params: params,
      startParams: {},
      duration: duration,
      ease: easingFunc,
      startAt: new Date(),
      onUpdate: onUpdate,
      rafId: 0
    };
  
    for(const paramKey in params){
        animation.startParams[paramKey] = obj[paramKey];
      }
  
    obj._animation = animation;
  
  
  
  
    animation.tick = () => {
  
      let startTime = animation.startAt;
      
      let progressSeconds = (Date.now() - startTime.getTime()) / 1000;
      let deltaSeconds = animation.duration;
      let progress = Math.max(Math.min(progressSeconds / deltaSeconds, 1), 0);
  
      let ratio = animation.ease(progress);
  
      
      for(const paramKey in params){
        const start = animation.startParams[paramKey];
        const need = animation.params[paramKey];
  
        const current = start + ((need - start) * ratio);
  
        animation.obj[paramKey] = current;
  
      }
  
      if(typeof onUpdate === 'function'){
        onUpdate();
      }
  
  
      if(progress !== 1){
        animation.rafId = window.requestAnimationFrame(animation.tick);
      }    
    }
  
    animation.progress = (progress) => {
  
      let progressTime = new Date();
      progressTime.setSeconds(progressTime.getSeconds() - animation.duration * progress);
  
      animation.startAt = progressTime;
    }
  
    animation.kill = () => {
      window.cancelAnimationFrame(animation.rafId);
      animation.obj._animation = null;
      delete animation.obj._animation;
      animation.params = null;
      animation.duration = 0;
    }
  
    animation.tick();
    return animation;
  
  }

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

