<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import elements_inventory_item from "@/components/elements/elements_inventory_item.vue";
import gsap from 'gsap';

import { debounce } from '@/scripts/utils.js';

export default {
    name: "withdraw_popup",
    data() {
        let interaction = useGameStore();
        return {
            tabIsShop: false,
            changeCost: 0,
            tweenedNumber: Number(interaction.userFullBalance),
            interaction: interaction,
            priceFilter: 100,
            searchString: "",
            searching: false,
            itemsList: [],
            withdrawItems: [],
            selectedWithdrawItem: null,
            withdrawPending: false,
        };
    },
    components: {
        elements_inventory_item,
    },
    methods: {
        getTradeLink() {
            window.open("https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url");
        },
        toggleItemSelect(item) {
            if (!item.isWithdrawal) return;
            if (this.selectedWithdrawItem?.id == item.id) {
                this.selectedWithdrawItem = null;
            } else {
                this.selectedWithdrawItem = item;
            }
        },
        async checkItemsAvailable() {
            try {
                let availableData = await this.interaction.getWithdrawData();
                this.withdrawItems = availableData.items;
            } catch (e) {
                this.$toast.error(this.$t(e.message));
            }
        },
        async updateItemsList() {
            let search = {
                maxPrice: this.priceFilter > 0 ? this.priceFilter * 1000 / this.interaction.BALANCE_BY_USD : undefined,
                searchString: this.searchString != "" ? this.searchString : undefined,
                sortOrder: "desc",
            }
            this.searching = true;
            this.itemsList = await this.interaction.getPrices(search);
            this.searching = false;
        },
        async buySelectedItemsToWithdraw() {

            try {
                let res = await this.interaction.buyItems(this.selectedShopItems.map(i => i.name));
                this.itemsList.map(i => i.shopSelected = false);
                this.checkItemsAvailable();
                this.tabIsShop = false;
            } catch (e) {
                this.$toast.error(this.$t(e.message));
            }

        },
        async withdrawItem() {

            try {
                this.withdrawPending = true;
                if (!this.selectedWithdrawItem) throw new Error($t('with.errorItem'));
                if (this.interaction.user.tradeLink == "") throw new Error($t('with.errorLink'));
                let res = await this.interaction.withdrawItem(this.selectedWithdrawItem.id, this.interaction.user.tradeLink);
                this.$toast.success(`${this.$t('with.success')} №${res.id}`);
                this.interaction.playSound('withdrows');
            } catch (e) {

                this.$toast.error(this.$t(e.message));
            } finally {
                this.withdrawPending = false;
            }

        },
    },

    created() {
        this.debouncedUpdateItemsList = debounce(this.updateItemsList, 1200);

        this.priceFilter = this.balance;
        this.updateItemsList();
        this.checkItemsAvailable();
    },
    computed: {
        balance() {
            return this.interaction.userFullBalance;
        },
        animatedNumber: function () {
            return this.tweenedNumber.toFixed(2);
        },


        shopRemainder() {
            return this.tweenedNumber - this.changeCost;
        },


        selectedShopItems() {
            let items = this.itemsList.filter(i => i.shopSelected);
            return items;
        },
        selectedShopCost() {
            let sum = this.selectedShopItems.reduce((acc, cur) => {
                return acc + cur.price;
            }, 0) / 1000 * this.interaction.BALANCE_BY_USD;
            return sum;
        }
    },
    watch: {
        balance: function (newValue, oldValue) {
            if (oldValue < newValue) this.interaction.playSound('fffffffffffffff');
            gsap.to(this.$data, { duration: 0.25, tweenedNumber: newValue });
        },
        selectedShopCost: function (newValue) {
            gsap.to(this.$data, { duration: 0.25, changeCost: newValue });
        },
        priceFilter: function () {
            this.debouncedUpdateItemsList();
        },
        searchString: function () {
            this.debouncedUpdateItemsList();
        }
    },
    mounted() {
        this.interaction.setBodyScroll(false);
    },
    unmounted() {
        this.interaction.setBodyScroll(true);
    }
};
</script>


<template>
    <div class="popup-container" :class="{ noneAnimation: !interaction.settingsSite.animations }">
        <div class="popup-block">
            <div class="popup-header">
                <div class="popup-header-title">
                    {{$t('with.withdrawSkin') }}
                </div>
                <div class="popup-header-title mobile">
                    {{$t('with.withdrawSkin1') }}
                </div>
                <div class="popup-header-close" @click="interaction.withdrowStatus = false">
                    <img src="/src/assets/img/shop/close.svg" alt="close">
                </div>
            </div>
            <div class="popup-container-pages-title_mobile">
                {{$t('with.changeMetod') }}
            </div>
            <div class="popup-container-pages">
                <div class="popup-page" @click="tabIsShop = false" :class="{ active: !tabIsShop }">
                    <img src="/popup/inventory.svg" alt="inventory" />
                    {{$t('inventory.inventory')}}
                </div>
                <div class="popup-page" @click="tabIsShop = true" :class="{ active: tabIsShop }">
                    <img src="/popup/shop.svg" alt="shop" />
                    {{$t('chat.skinShop')}}
                </div>
            </div>



            <Transition name="fadeWithdrow" mode="out-in" appear>
                <div class="popup-withdrow-changeBlock" v-if="!tabIsShop">
                    <div class="changeBlock-inventory">
                        <elements_inventory_item v-for="item in withdrawItems" :key="item.id" :item="item"
                            class="withdrowPage" @click="toggleItemSelect(item)"
                            :class="{ active: item.id == selectedWithdrawItem?.id, locked: !item.isWithdrawal }" />
                    </div>
                </div>
                <div class="popup-withdrow-changeBlock" v-else>
                    <div class="changeBlock-panel-search">
                        <div class="panel-search-container">
                            <div class="panel-search-blockImg">
                                <img src="/popup/wallet.svg" alt="wallet">
                            </div>
                            <div class="panel-search-balanceBlock">
                                <span>{{$t('with.balance') }}</span>
                                <span>{{ animatedNumber }}</span>
                            </div>
                            <div class="panel-search-balanceBlock">
                                <span>{{$t('chat.choosed')}}</span>
                                <span class="change">{{ changeCost.toFixed(2) }}</span>
                            </div>
                            <div class="panel-search-balanceBlock">
                                <span>{{$t('with.oct') }}</span>
                                <span class="remainder" :class="{ disable: shopRemainder < 0 }">
                                    {{ shopRemainder.toFixed(2) }}
                                </span>
                            </div>
                        </div>
                        <div class="panel-search-block">
                            <div class="panel-search">
                                <input type="text" :placeholder="$t('chat.search')" v-model="searchString">
                            </div>
                            <div class="panel-search-cost">
                                <input type="text" :placeholder="$t('with.price')" v-model="priceFilter">
                            </div>
                        </div>
                    </div>
                    <div class="changeBlock-shop">
                        <elements_inventory_item v-for="item in itemsList" :key="item.name"
                            :item="{ ...item, template: item }" @click="item.shopSelected = !item.shopSelected"
                            :class="{ active: item.shopSelected }"></elements_inventory_item>
                    </div>
                </div>
            </Transition>

            <div class="popup-trade-container">
                <div class="popup-trade-title">
                    <span>{{ $t('with.enterLink') }}</span>
                    <span @click="getTradeLink">{{ $t('with.where') }}</span>
                </div>
                <div class="popup-trade-block" v-if="!tabIsShop">
                    <div class="popup-trade-input">
                        <input type="text" :placeholder="$t('with.tradeLink')" v-model="interaction.user.tradeLink">
                    </div>
                    <div class="popup-trade-button" @click="withdrawItem" :class="{ pending: withdrawPending }">
                        {{$t('inventory.withdraw')}}
                    </div>
                </div>
                <div class="popup-trade-block" v-else>
                    <div class="popup-trade-input">
                        <input type="text" :placeholder="$t('with.tradeLink')" v-model="interaction.user.tradeLink">
                    </div>
                    <div class="popup-trade-button" @click="buySelectedItemsToWithdraw">
                        {{$t('items.byu')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="popup-bg" @click="interaction.toggleWithdrowStatus()">
        </div>
    </div>
</template>

<style scoped>
.fadeWithdrow-enter-active {
    opacity: 1;
    transition: all 0.25s ease;
}

.fadeWithdrow-leave-active {
    transform: translate(0%, 0%) scale(0.3);
    opacity: 0;
    transition: all 0.25s ease;
}

.fadeWithdrow-enter-from {
    transform: translate(0%, 0%) scale(0.7);
    opacity: 0;
    transition: all 0.25s ease;
}

.fadeWithdrow-leave-to {
    transform: translate(0%, 0%) scale(0.3);
    transition: all 0.25s ease;
}





.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    flex-direction: row;
    transition: all 0.25s ease;
}

.popup-block {
    width: 84vw;
    min-height: 32.5vw;
    height: fit-content;
    background-color: rgba(54, 58, 80, 1);
    border-radius: 1vw;
    margin: 0 1vw 0 0;
    padding: 1.5vw;
    z-index: 99;
    justify-content: flex-start;
    overflow: hidden;
    transition: all 0.25s ease;
}

.popup-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    backdrop-filter: blur(1vw);
    background: url(/src/assets/img/header/bg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.25s ease;
}


.popup-header {
    width: 100%;
    margin: 0 0 1.2vw 0;
    justify-content: space-between;
    flex-direction: row;
}

.popup-header-title {
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    font-size: 1vw;
    font-weight: 800;
}

.popup-header-title.mobile {
    display: none;
}

.popup-header-close {
    width: 1.6vw;
    height: 1.6vw;
    padding: 0.5vw;
    border-radius: 0.4vw;
    background-color: rgba(68, 72, 97, 1);
    cursor: pointer;
    transition: all 0.25s ease;
}

.popup-header-close:hover {
    background-color: #4b506d;
}






.popup-container-pages {
    width: 100%;
    height: 3.5vw;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 0.5vw;
    margin: 0 0 1.2vw 0;
    overflow: hidden;
}

.popup-page.active {
    width: 50%;
    background-color: rgba(72, 77, 105, 1);
}

.popup-page {
    width: 50%;
    height: 100%;
    background-color: rgba(63, 67, 91, 1);
    flex-direction: row;
    text-transform: uppercase;
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 800;
    transition: all 0.25s ease;
    cursor: pointer;
}

.popup-page:hover {
    background-color: rgba(72, 77, 105, 1);
}

.popup-page img {
    width: 1.8vw;
    height: 1.8vw;
    padding: 0.4vw;
    background-color: rgba(85, 90, 122, 1);
    border-radius: 0.4vw;
    margin: 0 0.6vw 0 0;
}





.popup-trade-container {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1.2vw 0 0 0;
}

.popup-trade-title {
    text-transform: uppercase;
    color: rgba(219, 224, 255, 0.4);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 800;
    flex-direction: row;
    justify-content: flex-start;
}

.popup-trade-title span:nth-child(2) {
    margin: 0 0 0 1vw;
    text-transform: uppercase;
    text-decoration: underline;
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 800;
    transition: all 0.25s ease;
    cursor: pointer;
}

.popup-trade-title span:nth-child(2):hover {
    color: rgb(255, 255, 255);
}

.popup-trade-block {
    width: 100%;
    height: 3.5vw;
    margin: 0.6vw 0 0 0;
    flex-direction: row;
    justify-content: space-between;
}

.popup-trade-input {
    width: 100%;
    height: 100%;
    padding: 1.1vw;
    background-color: rgba(63, 67, 91, 1);
    border-radius: 0.5vw;
}

input::placeholder {
    color: rgba(120, 125, 150, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 500;
}

input {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 500;
}

.popup-trade-button {
    min-width: 10vw;
    height: 100%;
    background-color: #4DC57D;
    border-radius: 0.5vw;
    padding: 0px 2.5vw;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    margin: 0 0 0 0.6vw;
    font-size: 0.7vw;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.25s ease;
}

.popup-trade-button:hover {
    background-color: #348855;
}

@keyframes pending {
    50% {
        filter: grayscale(1);
    }
}

.popup-trade-button.pending {
    pointer-events: none;
    animation: 2s infinite ease pending;
}











.popup-withdrow-changeBlock {
    width: 100%;
    height: 30vw;
    transition: all 0.25s ease;
}

.changeBlock-inventory {
    width: 100%;
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
}

.changeBlock-inventory::-webkit-scrollbar {
    width: 0.2vw;
}

.changeBlock-panel-search {
    width: 100%;
    height: 3.5vw;
    margin: 0 0 1vw 0;
    padding: 0.5vw;
    background-color: rgba(63, 67, 91, 1);
    border-radius: 0.5vw;
    flex-direction: row;
    justify-content: space-between;
}

.changeBlock-shop {
    width: 100%;
    height: calc(100% - 4.5vw);
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
}

.changeBlock-shop::-webkit-scrollbar {
    width: 0.2vw;
}



.panel-search-container {
    width: fit-content;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.panel-search-blockImg {
    width: 1.2vw;
    height: 1.2vw;
    margin: 0 0.7vw 0 0;
}

.remainder.disable {
    color: rgba(255, 115, 132, 1);
}

.change {
    color: rgba(126, 255, 178, 1);
}

.panel-search-balanceBlock {
    text-transform: uppercase;
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.6vw;
    font-weight: 700;
    margin: 0 1vw 0 0;
}

.panel-search-balanceBlock span:nth-child(2)::before {
    content: "";
    display: flex;
    width: 0.9vw;
    height: 0.9vw;
    min-width: 0.9vw;
    min-height: 0.9vw;
    margin: 0 0.2vw 0 0;
    background-image: url(/coin.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.panel-search-balanceBlock span:nth-child(2) {
    font-size: 0.8vw;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.panel-search-block {
    height: 100%;
    flex-direction: row;
}

.panel-search {
    width: 7.5vw;
    height: 100%;
    padding: 0.7vw;
    background: #4A4F6B;
    border-radius: 0.5vw;
}

.panel-search-cost {
    width: 100px;
    height: 100%;
    padding: 0.7vw;
    margin: 0 0 0 0.5vw;
    background: #4A4F6B;
    border-radius: 0.5vw;
}


.popup-container-pages-title_mobile {
    display: none;
}








@media (max-width: 1024px) {

    .popup-block {
        width: 100vw;
        min-height: unset;
        height: 100vh;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-y: auto;
        border-radius: unset;
        margin: 0 0 0 0;
        padding: 30px 12px 100px 12px;
    }

    .popup-header {
        margin: 0 0 20px 0;
    }


    .popup-header-title {
        display: none;
        font-size: 18px;
    }

    .popup-header-title.mobile {
        display: flex;
    }

    .popup-header-close {
        width: 35px;
        height: 35px;
        padding: 10px;
        border-radius: 8px;
    }


    .popup-container-pages-title_mobile {
        width: 100%;
        align-items: flex-start;
        display: flex;
        white-space: nowrap;
        color: #969DC2;
        font-family: "VelaSans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 137.6%;
        margin: 0 0 8px 0;
    }

    .popup-container-pages {
        width: 100%;
        height: fit-content;
        min-height: 45px;
        margin: 0px 0 10px 0;
        justify-content: space-between;
    }

    .popup-page.active {
        width: 49%;
    }

    .popup-page {
        width: 49%;
        border-radius: 8px;
        height: 45px;
        min-height: 45px;
        font-size: 11px;
    }

    .popup-page img {
        width: 25px;
        height: 25px;
        padding: 5px;
        background-color: unset;
        border-radius: unset;
        margin: 0 10px 0 0;
    }

    .popup-withdrow-changeBlock {
        height: fit-content;
    }

    .changeBlock-inventory {
        height: fit-content;
        justify-content: space-between;
    }

    .inventory-item.withdrowPage.locked {
        background: linear-gradient(179.94deg, rgba(243, 190, 111, 0.4) 0.05%, rgba(243, 190, 111, 0) 99.95%);
        border: 1px solid rgba(255, 212, 148, 0.55);
    }

    .inventory-item.withdrowPage.locked:hover {
        cursor: auto;
    }

    .inventory-item.withdrowPage.locked:hover .inventory-item-hover {
        opacity: 0;
        pointer-events: none;
    }

    .inventory-item.withdrowPage.active {
        background-color: rgba(131, 135, 161, 0.5);
        border: 1px solid rgba(131, 135, 161, 1);
    }

    .inventory-item.withdrowPage {
        background-color: #444861;
        border: 0.0vw solid rgba(131, 135, 161, 0);
        border-radius: 15px;
        height: 115px;
        min-height: 115px;
        width: 115px;
        min-width: 115px;
        padding: 10px;
        margin: 4px;
        justify-content: space-between;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.25s ease;
    }



    .fadeWithdrow-enter-active {
        opacity: 1;
        transition: all 0.25s ease;
    }

    .fadeWithdrow-leave-active {
        transform: translateY(10px);
        opacity: 0;
        transition: all 0.25s ease;
    }

    .fadeWithdrow-enter-from {
        transform: translateY(10px);
        opacity: 0;
        transition: all 0.25s ease;
    }

    .fadeWithdrow-leave-to {
        transform: translateY(10px);
        transition: all 0.25s ease;
    }






    .popup-trade-container {
        margin: 10px 0 0 0;
    }

    .popup-trade-title {
        font-size: 14px;
        padding: 0 0 0 15px;
    }

    .popup-trade-title span:nth-child(2) {
        margin: 0 0 0 20px;
        font-size: 14px;
    }

    .popup-trade-block {
        margin: 8px 0 40px 0;
        height: fit-content;
        flex-direction: column;
    }

    .popup-trade-input {
        padding: 15px;
        border-radius: 12px;
        margin: 0 0 12px 0;
    }

    input::placeholder {
        font-size: 14px;
    }

    input {
        font-size: 14px;
        color: #DBE0FF;
    }

    .popup-trade-button {
        width: 100%;
        height: 54px;
        min-height: 54px;
        border-radius: 12px;
        font-size: 14px;
        padding: 0;
        margin: 0;
    }





    .changeBlock-shop {
        height: fit-content;
        justify-content: space-between;
    }

    .changeBlock-panel-search {
        height: fit-content;
        margin: 0 0 20px 0;
        padding: 15px;
        border-radius: 10px;
    }

    .panel-search-block {
        display: none;
    }

    .panel-search-container {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .panel-search-blockImg {
        width: 18px;
        height: 18px;
        margin: 0 12px 0 0;
    }

    .panel-search-balanceBlock {
        max-width: 100px;
        display: flex;
        font-size: 10px;
        margin: 0 20px 0 0;
    }

    .panel-search-balanceBlock span:nth-child(1) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        line-height: 8px;
    }

    .panel-search-balanceBlock span:nth-child(2) {
        font-size: 16px;
    }

    .panel-search-balanceBlock span:nth-child(2)::before {
        width: 14px;
        height: 14px;
        min-width: 14px;
        min-height: 14px;
        margin: 0 5px 0 0;
    }

}
</style>