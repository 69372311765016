<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import elements_game_wheel_hover from "@/components/elements/elements_game_wheel_hover.vue";

import gsap from 'gsap';
import Interface_history_list from '../interface/interface_history_list.vue';

export default {
    name: "replenishment_popup",
    data() {
        let interaction = useGameStore();
        return {
            money: 0,
            animatedMoney: 0,
            animatedCoin: 0,
            inputFocusMoney: false,
            interaction: interaction,
            promoCode: '',
            selectedPayType: "",
            bonus: 0
        };
    },
    components: {
        elements_game_wheel_hover
    },
    methods: {
        copySiteName() {
            try {
                navigator.clipboard.writeText(this.interaction.SITE_COPY_NAME);
                this.$toast.success(this.$t('toasts.copySite'));
            } catch (e) {
                console.warn(e)
            }
        },
        async redeemCode() {
            try {
                let response = await this.interaction.redeemCode(this.promoCode, true);
                this.bonus += parseInt(response.code.bonus); 
                this.$toast.success(this.$t('toasts.successPromocode'));
            } catch (e) {
                this.$toast.error(this.$t(e.message))
                console.log(e)
            }
        },
        async tryPay(payCallback) {
            try {
                await payCallback(this.money);
            } catch (e) {
                this.$toast.error(this.$t(e.message))
            }
        },
        setFocus(input) {
            setTimeout(() => input.focus(), 10);
        },
        
    },
    computed: {
        coin: {
            get() {
                return this.money * this.interaction.BALANCE_BY_USD;
            },
            set(value) {
                this.money = value / this.interaction.BALANCE_BY_USD;
            }
        },
       

        userLevels() {
            return this.interaction.stats.userlevels ?? [];
        },
        userLevel() {
            let level = this.userLevels.find(l => +l.id == (+this.interaction.user.userLevelId));
            return level;
        },
        selectedPayMethods() {
            if (this.selectedPayType == "") {
                return this.interaction.paymentMethods;
            } else {
                return this.interaction.paymentMethods.filter(m => m.type == this.selectedPayType);
            }
        }
    },
    watch: {
        coin: function (newValue) {
            gsap.to(this.$data, { duration: 0.15, animatedCoin: newValue });
        },
        money: function (newValue) {
            gsap.to(this.$data, { duration: 0.15, animatedMoney: newValue });
        }
    },
    mounted() {
        this.interaction.setBodyScroll(false);
        this.bonus = parseInt(this.userLevel.depositBonus) + parseFloat(this.interaction.user.depositBonus);
    },
    unmounted() {
        this.interaction.setBodyScroll(true);
    }
};
</script>


<template>
    <div class="popup-container" :class="{ noneAnimation: !interaction.settingsSite.animations }">
        <div class="popup-block">
            <div class="popup-header">
                <div class="popup-header-title">
                    {{ $t('rep.addBalance') }}
                </div>
                <div class="popup-header-close" @click="interaction.replenishmentStatus = false">
                    <img src="/src/assets/img/shop/close.svg" alt="close">
                </div>
            </div>
            <div class="popup-wallet-container">
                <div class="popup-wallet-container-left-blocks"
                    :style="{ '--before-content': `'${$t('rep.selectMethod')}'` }">
                    <div class="popup-wallet-block all" @click="selectedPayType = ''"
                        :class="{ active: selectedPayType == '' }">
                        {{ $t('rep.all') }}
                    </div>
                    <!-- <div class="popup-wallet-block cards" @click="selectedPayType = 'cards'" :class="{active: selectedPayType == 'cards'}">
                    <div class="wallet-block-img">
                        <img src="/src/assets/img/replenishment/card.svg" alt="card">
                    </div>  
                    <span>Банковские карты</span>
                </div> -->
                    <div class="popup-wallet-block money" @click="selectedPayType = 'money'"
                        :class="{ active: selectedPayType == 'money' }">
                        <div class="wallet-block-img">
                            <img src="/src/assets/img/replenishment/wallet.svg" alt="wallet">
                        </div>
                        <span>{{ $t('rep.money') }}</span>
                    </div>
                    <div class="popup-wallet-block skins" @click="selectedPayType = 'skins'"
                        :class="{ active: selectedPayType == 'skins' }">
                        <div class="wallet-block-img">
                            <img src="/src/assets/img/replenishment/ak.svg" alt="skins">
                        </div>
                        <span>{{ $t('rep.skins') }}</span>
                    </div>
                    <div class="popup-wallet-block cripta" @click="selectedPayType = 'crypto'"
                        :class="{ active: selectedPayType == 'crypto' }">
                        <div class="wallet-block-img">
                            <img src="/src/assets/img/replenishment/cripta.svg" alt="crypto">
                        </div>
                        <span>{{ $t('rep.crypto') }}</span>
                    </div>
                </div>
                <!-- <div class="popup-wallet-block usd" @click="interaction.testAddBalance()">
                    $usd
                </div> -->
            </div>
            <div class="popup-pay-container">


                <transition-group name="payments">

                    <div class="pay-item" v-for="item in selectedPayMethods" :key="item.title"
                        @click="tryPay(item.callback)">
                        <div class="pay-item-logo">
                            <img :src="item.icon" :alt="item.title">
                        </div>
                        <div class="pay-item-title">
                            {{ item.title }}
                        </div>
                    </div>

                </transition-group>



            </div>
            <div class="popup-exhange-container">
                <div class="exhange-block">
                    <div class="exhange-title">
                        {{ $t('rep.enterSum') }}
                    </div>
                    <div class="exhange-input dollar">
                        <img src="/src/assets/img/replenishment/dollar.svg" alt="money">

                        <input inputmode="numeric" type="number" v-model="money" step="1" v-show="inputFocusMoney"
                            ref="inputMoney" />

                        <input inputmode="numeric" type="number" :value="animatedMoney" step="1" v-show="!inputFocusMoney"
                            @focus="inputFocusMoney = true, setFocus($refs.inputMoney)" />

                    </div>
                </div>
                <div class="exhange-arrow">
                    <img src="/src/assets/img/replenishment/bigarrow.svg" alt="arrow">
                </div>
                <div class="exhange-block">
                    <div class="exhange-title">
                        {{ $t('rep.youGive') }}
                    </div>
                    <div class="exhange-input">
                        <img src="/coin.svg" alt="coin">

                        <input inputmode="numeric" type="number" v-model="coin" step="1" v-show="!inputFocusMoney"
                            ref="inputCoin" />

                        <input inputmode="numeric" type="number" :value="animatedCoin" step="1" v-show="inputFocusMoney"
                            @focus="inputFocusMoney = false, setFocus($refs.inputCoin)" />

                    </div>
                </div>
            </div>
        </div>
        <div class="popup-block-bounce">
            <div class="popup-header">
                <div class="popup-header-title">
                    {{ $t('support2.bonus') }}
                </div>
            </div>
            <div class="popup-pikcha">
                <div class="popup-pikcha-text">
                    <span>
                        <p>{{ $t('rep.stay') }} &#160;</p> {{ this.interaction.SITE_COPY_NAME }}
                    </span>
                    {{ $t('rep.nikSteam') }}<br>
                    {{ $t('rep.bonus') }}<br>
                </div>
                <div class="popup-pikcha-copy-block">
                    <div class="popup-pikcha-copy-block-text">
                        {{ this.interaction.SITE_COPY_NAME }}
                    </div>
                    <div class="bounce-input-button copy-block" @click="copySiteName">
                        <img src="/src/assets/img/replenishment/copy.svg" alt="copy">
                    </div>
                </div>
            </div>
            <div class="popup-bounce-block-input promocode">
                <div class="bounce-input-title">
                    {{ $t('rep.promoCode') }}
                    <elements_game_wheel_hover :title="``" :description="$t('profil.promocodeRules')" />
                </div>
                <div class="bounce-input-block">
                    <input type="text" v-model="promoCode" @keyup.enter="redeemCode">
                    <div class="bounce-input-button" @click="redeemCode">
                        <img src="/src/assets/img/replenishment/arrow.svg" alt="arrow">
                    </div>
                </div>
            </div>
            <div class="popup-bounce">
                <div class="popup-bounce-title" :style="{ '--before-content': `'${$t('rep.curBonus')}'` }">
                    {{ $t('support2.bonus') }}
                </div>
                <div class="popup-bounce-percent" id="bonusDeposit">
                    {{ bonus }}%
                </div>
            </div>
            <!-- <div class="popup-bounce-block-input">
                <div class="bounce-input-title">
                    ПОСТАВЬ В НИК И ПОЛУЧИ БОНУС 5%
                </div>
                <div class="bounce-input-block">
                    <input type="text">
                    <div class="bounce-input-button">
                        <img src="/src/assets/img/replenishment/copy.svg" alt="copy">
                    </div>
                </div>
            </div> -->
        </div>
        <div class="popup-bg" @click="interaction.toggleReplenishmentStatus()">
        </div>
    </div>
</template>

<style scoped>
.payments-enter-active {
    transform: scale(1.0);
    opacity: 1;
    transition: all 0.25s ease;
}

.payments-leave-active {
    position: absolute;
    transform: scale(0.5);
    opacity: 0;
    transition: all 0.0s ease;
}

.payments-enter-from {
    transform: scale(0.5);
    opacity: 0;
    transition: all 0.25s ease;
}

.payments-leave-to {
    transform: scale(0.5);
    opacity: 0;
    transition: all 0.25s ease;
}









.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    flex-direction: row;
    transition: all 0.25s ease;
}

.popup-block {
    width: 47vw;
    min-height: 32.5vw;
    height: fit-content;
    background-color: rgba(54, 58, 80, 1);
    border-radius: 1vw;
    margin: 0 1vw 0 0;
    padding: 1.5vw;
    z-index: 99;
    justify-content: flex-start;
    transition: all 0.25s ease;
}

.popup-block-bounce {
    width: 17vw;
    min-height: 32.5vw;
    height: fit-content;
    background-color: rgba(54, 58, 80, 1);
    border-radius: 1vw;
    padding: 1.5vw;
    z-index: 99;
    justify-content: flex-start;
    transition: all 0.25s ease;
}

.popup-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    backdrop-filter: blur(1vw);
    background: url(/src/assets/img/header/bg.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.25s ease;
}


.popup-header {
    width: 100%;
    margin: 0 0 1.2vw 0;
    justify-content: space-between;
    flex-direction: row;
}

.popup-header-title {
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    font-size: 1vw;
    font-weight: 800;
}

.popup-header-close {
    width: 1.6vw;
    height: 1.6vw;
    padding: 0.5vw;
    border-radius: 0.4vw;
    background-color: rgba(68, 72, 97, 1);
    cursor: pointer;
    transition: all 0.25s ease;
}

.popup-header-close:hover {
    background-color: #4b506d;
}


.popup-wallet-container {
    width: 100%;
    height: 3.5vw;
    justify-content: space-between;
    flex-direction: row;
}

.popup-wallet-block.all {
    width: 3.8vw;
    padding: 0;
    border-bottom-left-radius: 0.5vw;
    border-top-left-radius: 0.5vw;
    border-left: none;
}

.popup-wallet-block.cripta {
    border-bottom-right-radius: 0.5vw;
    border-top-right-radius: 0.5vw;
}

.popup-wallet-block.usd {
    width: 3.8vw;
    padding: 0;
    border-radius: 0.5vw;
    margin: 0 0 0 0.6vw;
}

.popup-wallet-container-left-blocks {
    width: calc(100% - 3.8vw - 0.6vw);
    height: 100%;
    justify-content: flex-start;
    flex-direction: row;
}

.popup-wallet-block.active {
    background-color: rgba(72, 77, 105, 1);
    cursor: auto;
}

.popup-wallet-block {
    height: 100%;
    padding: 0 1.2vw;
    background-color: rgba(63, 67, 91, 1);
    text-transform: uppercase;
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    border-left: 0.1vw solid rgba(54, 58, 80, 1);
    font-size: 0.7vw;
    font-weight: 800;
    flex-direction: row;
    cursor: pointer;
    transition: all 0.25s ease;
}

.popup-wallet-block span {
    font-weight: 800;
}

.popup-wallet-block:hover {
    background-color: rgba(72, 77, 105, 1);
}

.wallet-block-img {
    width: 1.8vw;
    height: 1.8vw;
    margin: 0 0.6vw 0 0;
    padding: 0.5vw;
    background-color: rgba(85, 90, 122, 1);
    border-radius: 0.4vw;
}

.popup-pay-container {
    width: calc(100% + 0.8vw);
    transform: translate(0.2vw, 0px);
    height: 15vw;
    margin: 1vw 0 1.5vw 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
}

.pay-item {
    width: 8.4vw;
    height: 5.7vw;
    min-width: 8.4vw;
    min-height: 5.7vw;
    margin: 0 0.2vw 0.5vw 0.2vw;
    border-radius: 0.5vw;
    background-color: rgba(63, 67, 91, 1);
    cursor: pointer;
    transition: all 0.25s ease;
}

.pay-item:hover {
    background-color: rgba(72, 77, 105, 1);
}

.pay-item-logo {
    width: 4.7vw;
    height: 2vw;
}

.pay-item-logo img {
    height: 100%;
}

.pay-item-title {
    margin: 1vw 0 0 0;
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 700;
}


.popup-exhange-container {
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
}

.exhange-block {
    height: 100%;
}

.exhange-title {
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    margin: 0 0 0.7vw 0;
    color: rgba(219, 224, 255, 0.4);
    text-transform: uppercase;
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 800;
}

.exhange-input {
    width: 20vw;
    height: 3.5vw;
    padding: 1vw;
    border-radius: 0.5vw;
    background-color: rgba(63, 67, 91, 1);
    flex-direction: row;
    justify-content: flex-start;
}

.exhange-input.dollar img {
    width: 0.6vw;
}

.exhange-input img {
    width: 1vw;
    height: 1vw;
    min-width: 1vw;
    min-height: 1vw;
    margin: 0 0.6vw 0 0;
}

input {
    width: 100%;
    font-family: "VelaSans", sans-serif;
    font-size: 1vw;
    font-weight: 700;
    color: rgba(219, 224, 255, 1);
    outline: none;
    background: none;
    border: none;
}

.exhange-arrow {
    height: 3.5vw;
    margin: 0 1vw;
}

.exhange-arrow img {
    width: 1.5vw;
    height: 1.5vw;
    transform: translate(0, -0.1vw);
}







.popup-bounce {
    width: 100%;
    height: 3.5vw;
    background-color: rgba(77, 197, 125, 0.15);
    border: 0.1vw dashed rgba(77, 197, 125, 1);
    border-radius: 0.5vw;
    padding: 0 1vw;
    margin: 0 0 1.5vw 0;
    justify-content: space-between;
    flex-direction: row;
}

.popup-bounce-title::before {
    content: var(--before-content);
    width: 100%;
    text-transform: uppercase;
    font-family: "VelaSans", sans-serif;
    font-size: 0.6vw;
    font-weight: 700;
    color: rgba(185, 255, 213, 1);
}

.popup-bounce-title {
    text-transform: uppercase;
    font-family: "VelaSans", sans-serif;
    font-size: 1.2vw;
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    line-height: 1vw;
    transform: translate(0, -0.2vw);
}

.popup-bounce-percent {
    text-transform: uppercase;
    font-family: "VelaSans", sans-serif;
    font-size: 1.5vw;
    font-weight: 600;
    color: rgba(94, 255, 158, 1);
}

.popup-pikcha {
    width: 100%;
    height: 10.5vw;
    background: linear-gradient(136.53deg, #A06CFF 24.33%, #7441D2 83.76%);
    background-image: url(/popup/pikcha.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.7vw;
    padding: 1vw;
    margin: 0 0 1.5vw 0;
    align-items: flex-start;
    justify-content: flex-start;
}

.popup-pikcha-text {
    width: 100%;
    font-family: "VelaSans", sans-serif;
    font-size: 1vw;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    flex-direction: column;
}

.popup-pikcha-text span {
    display: flex;
    position: relative;
    font-family: "VelaSans", sans-serif;
    font-size: 1vw;
    font-weight: 700;
    color: rgba(255, 227, 82, 1);
}

.popup-pikcha-text span p {
    font-family: "VelaSans", sans-serif;
    font-size: 1vw;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
}

.popup-bounce-block-input {
    width: 100%;
}

.bounce-input-title {
    width: 100%;
    text-transform: uppercase;
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 600;
    color: rgba(219, 224, 255, 0.4);
    justify-content: flex-start;
    flex-direction: row;
}

.bounce-input-block {
    width: 100%;
    height: 3vw;
    padding: 0 0.3vw 0 1vw;
    background-color: rgba(63, 67, 91, 1);
    border-radius: 0.5vw;
    flex-direction: row;
}

.popup-bounce-block-input.promocode {
    margin: 0 0 1vw 0;
}

.bounce-input-block input {
    width: calc(100% - 2.7vw);
    font-size: 0.9vw;
    font-weight: 700;
    color: rgba(219, 224, 255, 1);
}

.bounce-input-button {
    width: 2.2vw;
    height: 2.2vw;
    min-width: 2.2vw;
    min-height: 2.2vw;
    margin: 0 0 0 0.5vw;
    background: #638CF3;
    border-radius: 0.4vw;
    cursor: pointer;
    transition: all 0.25s ease
}

.bounce-input-button img {
    width: 0.9vw;
    transition: all 0.25s ease
}

.bounce-input-button:hover {
    background-color: #638cf388;
}

.bounce-input-button:hover img {
    transform: scale(1.19);
}

.popup-bounce-block-input.promocode .bounce-input-button:hover img {
    transform: translate(0.2vw, 0px)
}


.popup-pikcha-copy-block {
    width: 100%;
    margin: 1.2vw 0 0 0;
    background-color: rgba(48, 24, 94, 0.24);
    border-radius: 0.5vw;
    border: 0.1vw dashed rgba(229, 216, 255, 1);
    justify-content: flex-end;
    flex-direction: row;
}

.popup-pikcha-copy-block-text {
    width: calc(100% - 2.7vw);
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    padding: 0 0 0 2.7vw;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
}

.bounce-input-button.copy-block {
    background: none;
}














@media (max-width: 1024px) {
    .popup-container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-y: auto;
    }

    .popup-block {
        width: 100%;
        min-height: unset;
        height: fit-content;
        border-radius: unset;
        margin: 0 0 0 0;
        padding: 30px 12px 25px 12px;
    }

    .popup-block-bounce {
        width: 100%;
        min-height: unset;
        height: fit-content;
        border-radius: unset;
        padding: 0px 12px 100px 12px;
    }

    .popup-header {
        margin: 0 0 20px 0;
    }

    .popup-wallet-container {
        height: fit-content;
    }

    .popup-pay-container {
        width: 100%;
        transform: unset;
        height: fit-content;
        margin: 0px 0 0px 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        align-content: flex-start;
    }

    .popup-exhange-container {
        flex-direction: column;
        align-items: center;
        margin: 20px 0 30px 0;
    }

    .exhange-block {
        width: 100%;
    }

    .exhange-title {
        margin: 0 0 5px 10px;
        font-size: 16px;
    }

    .exhange-input {
        width: 100%;
        min-height: 60px;
        height: fit-content;
        padding: 5px 20px;
        border-radius: 14px;
    }

    .exhange-input input {
        font-size: 18px;
    }

    .exhange-input img {
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        margin: 0 10px 0 0;
    }

    .exhange-arrow {
        width: 28px;
        height: auto;
        margin: 20px 0 15px 0;
    }

    .exhange-arrow img {
        width: 100%;
        height: auto;
        transform: rotate(90deg);
    }




    .popup-header-title {
        font-size: 18px;
    }

    .popup-header-close {
        width: 35px;
        height: 35px;
        padding: 10px;
        border-radius: 8px;
    }

    .popup-wallet-container-left-blocks::before {
        content: var(--before-content);
        display: inline-block;
        white-space: nowrap;
        color: #969DC2;
        font-family: "VelaSans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 137.6%;
        position: absolute;
        top: -25px;
    }

    .popup-wallet-container-left-blocks {
        width: 100%;
        height: fit-content;
        margin: 30px 0 15px 0;
        justify-content: space-between;
    }

    .popup-wallet-block.usd {
        display: none;
    }


    .popup-wallet-block {
        width: calc(25% - 0px);
        height: 45px;
        padding: 0 0px;
        margin: 0 5px 0 0;
        border-radius: 10px;
        background-color: rgba(63, 67, 91, 1);
        text-transform: uppercase;
        color: rgba(219, 224, 255, 1);
        font-family: "VelaSans", sans-serif;
        border-left: 1px solid rgba(54, 58, 80, 1);
        font-size: 14px;
        font-weight: 800;
        flex-direction: row;
        cursor: pointer;
        transition: all 0.25s ease;
    }

    .popup-wallet-block.all {
        width: calc(25% - 0px);
        padding: 0 0px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }

    .popup-wallet-block.cripta {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }

    .popup-wallet-block span {
        display: none;
    }

    .wallet-block-img {
        width: 20px;
        height: 20px;
        margin: 0 unset 0 0;
        padding: unset;
        background: none;
        border-radius: unset;
    }




    .pay-item {
        width: calc(33.3% - 5px);
        height: 10vw;
        min-width: unset;
        min-height: unset;
        margin: 0 5px 5px 0px;
        border-radius: 12px;
        background-color: rgba(63, 67, 91, 1);
        cursor: pointer;
        transition: all 0.25s ease;
    }

    .pay-item-logo {
        width: 40%;
        height: 40%;
    }

    .pay-item-title {
        display: none;
    }


    .popup-pikcha {
        height: fit-content;
        border-radius: 20px;
        padding: 20px;
        margin: 0 0 25px 0;
    }

    .popup-pikcha-text,
    .popup-pikcha-text span,
    .popup-pikcha-text span p {
        font-size: 22px;
    }



    .popup-pikcha-copy-block {
        width: 100%;
        margin: 20px 0 0 0;
        border-radius: 14px;
        border: 1px dashed rgba(229, 216, 255, 1);
    }

    .popup-pikcha-copy-block-text {
        width: 100%;
        height: 50px;
        font-size: 16px;
        padding: 0 0 0 0;
    }

    .bounce-input-button {
        position: absolute;
        right: 15px;
        width: 80px;
        height: 40px;
        min-width: 80px;
        min-height: 40px;
        margin: 0 0 0 0;
        background: #638CF3;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.25s ease;
    }

    .bounce-input-button.copy-block {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
    }

    .bounce-input-button img {
        width: 19px;
    }


    .bounce-input-title {
        font-size: 14px;
        padding: 0 0 0 15px;
    }

    .bounce-input-block {
        width: 100%;
        height: 54px;
        padding: 0 0 0 15px;
        background-color: rgba(63, 67, 91, 1);
        border-radius: 12px;
        flex-direction: row;
        justify-content: flex-start;
    }

    .bounce-input-block input {
        width: calc(100% - 110px);
        font-size: 14px;
        font-weight: 700;
        color: rgba(219, 224, 255, 1);
    }

    .bounce-input-block input::placeholder {
        font-size: 14px;
    }





    .popup-bounce {
        width: 100%;
        height: 64px;
        border: 1px dashed rgba(77, 197, 125, 1);
        border-radius: 12px;
        padding: 0 15px;
        margin: 12px 0 25px 0;
    }

    .popup-bounce-title::before {
        width: 100%;
        font-size: 10px;
    }

    .popup-bounce-title {
        font-size: 19px;
        color: rgba(255, 255, 255, 1);
        line-height: 20px;
        transform: translate(0, -0.0px);
    }

    .popup-bounce-percent {
        font-size: 22px;
    }


}

@media (max-width: 768px) {
    .pay-item {
        height: 70px;
    }
}</style>
