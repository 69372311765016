<script>
import window_header from "../components/window_header.vue";
import window_main from "../components/window_main.vue";
import window_footer from "../components/window_footer.vue";
import { useGameStore } from '@/stores/interface-interaction.js';


export default {
  name: "screen_main",
  data() {
    let interaction = useGameStore();
    return{
        interaction: interaction,
    }
  },
  components: {
    window_header,
    window_main,
    window_footer
  },
  methods: {

  },
  mounted() {

  },
  head(){
    return {
      title: 'CSSWEETS',
      meta: [
        {name: 'description', content: 'CSSweets предлагает 3 игровых режима CS:GO: Crush, Wheel, Coin. Регулярные промо-коды и ежедневные бонусы.'}
      ],
      htmlAttrs: {
        lang: this.$i18n.locale,
      }
    }
  },
};
</script>


<template>
  <transition name="fade" mode="out-in" appear>
    <div class="app-template-container" :class="{noneAnimation: !interaction.settingsSite.animations}">
          <window_header/>
          <window_main/>
          <window_footer/>
    </div>
  </transition>
</template>

<style>
.noneAnimation *{
  transition: all 0s !important;
}
</style>
<style scoped>
.fade-enter-active {
  transform: translate(0%, 0%);
  opacity: 1;
  transition: all 0.25s ease;
}

.fade-leave-active {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-enter-from {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-leave-to {
  transform: translate(0%, 0%);
  transition: all 0.25s ease;
}

.app-template-container{
    width: calc(100% - 140px);
    padding: 0 70px;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    /* overflow: hidden; */
    background-color: #27293A;
    justify-content: flex-start;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    transition: all 0.75s ease;
}
@media (max-width: 1640px) {
  .app-template-container{
    width: calc(100% - 100px);
    padding: 0 50px;
    max-width: 1640px;
  }
}
@media (max-width: 1440px) {
  .app-template-container{
    width: calc(100% - 60px);
    padding: 0 30px;
    max-width: 1440px;
  }
}
@media (max-width: 1280px) {
  .app-template-container{
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 1280px;
  }
}
@media (max-width: 1024px) {
  .app-template-container{
    width: calc(100% - 20px);
    padding: 0 10px;
    max-width: 1024px;
  }
}
</style>

