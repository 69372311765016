<script>
import { useGameStore } from '@/stores/interface-interaction.js';
export default {
  name: "window_footer",
  data() {
    let interaction = useGameStore();
    return{
        show_changeGame: false,
        interaction: interaction,
    }
  },
  components:{
    
  },
  methods: {
    open_changeGame(){
        this.show_changeGame =! this.show_changeGame;
    }
  },
  mounted() {

  },
};
</script>


<template>
    <div class="footer-mobile">
        <div class="footer-mobile-container">
            <div class="footer-mobile-block game" @click="open_changeGame()">
                <img src="/footer/game.svg" alt="game"/>
                <p>
                    {{$t('games.gameMode')}}
                </p>
                <img class="arrow_game" src="/footer/arrow_green.svg" alt="arrow"
                :class="{active: show_changeGame}"/>
            </div>
            <transition name="show_changeGame" mode="out-in">
                <div class="footer-mobile-block_changeGame" v-if="show_changeGame">
                    <div class="block_changeGame" @click="$router.push({name:'crash'})"
                        :class="{active: $route.name == 'crash'}">
                        Crash
                    </div>
                    <div class="block_changeGame" @click="$router.push({name:'wheel'})"
                        :class="{active: $route.name == 'wheel'}">
                        Wheel
                    </div>
                    <div class="block_changeGame" @click="$router.push({name:'coin'})"
                        :class="{active: $route.name == 'coin'}">
                        Coin
                    </div>
                    <div class="block_changeGame" @click="$router.push({name:'cases'})"
                        :class="{active: $route.name == 'cases'}">
                        Cases
                    </div>
                </div>
            </transition>
        </div>
        <div class="footer-mobile-container">
            <div class="footer-mobile-block" @click="interaction.toggleChatStatus(), interaction.currentTab = 'elements_inventory'">
                <img src="/src/assets/img/chat/inventory.svg" alt="inventory"/>
            </div>
            <div class="footer-mobile-block" @click="interaction.toggleChatStatus(), interaction.currentTab = 'elements_chat'">
                <img src="/src/assets/img/chat/chat.svg" alt="chat"/>
            </div>
        </div>
    </div>
</template>

<style scoped>
.show_changeGame-enter-active {
    margin: 10px 0 0 0;
    opacity: 0;
    transition: all 0.25s ease;
}

.show_changeGame-leave-active{}
.show_changeGame-enter-from{}
.show_changeGame-leave-to {
    margin: 10px 0 0 0;
    opacity: 0;
    transition: all 0.25s ease;
}




.footer-mobile{
    position: fixed;
    bottom: 10px;
    border: 1px solid #30344A;
    width: calc(100% - 10px);
    border-radius: 20px;
    height: 70px;
    display: none;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(32, 33, 46, 1);
    z-index: 4;
}
.footer-mobile-container{
    width: 50%;
    padding: 0 10px;
    flex-direction: row;
    justify-content: flex-start;
}
.footer-mobile-container:nth-child(2){
    justify-content: flex-end;
}
.footer-mobile-container .footer-mobile-block{
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 0 10px 0 0;
    background-color: rgba(32, 33, 46, 1);
    border: 1px solid rgba(54, 58, 80, 1);
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.25s ease
}
.footer-mobile-container .footer-mobile-block:hover{
    background-color: rgb(40, 41, 57);
}
.footer-mobile-container:nth-child(2) .footer-mobile-block{
    margin: 0 10px 0 0;
    background-color: rgba(99, 140, 243, 0.15);
    border: 0 solid rgba(99, 140, 243, 0.15);
    cursor: pointer;
    transition: all 0.25s ease
}
.footer-mobile-container:nth-child(2) .footer-mobile-block:hover{
    background-color: rgba(99, 140, 243, 0.25)
}

.footer-mobile-block.game{
    width: fit-content;
    padding: 0 30px;
    flex-direction: row;
    justify-content: space-between;
    border: none;
    background-color: rgba(95, 232, 149, 0.1);
    user-select: none;
}
.footer-mobile-block.game img:nth-child(1){
    width: 4vw;
    height: 4vw;
    min-width: 17px;
    min-height: 12px;
}
.footer-mobile-block.game p:nth-child(2){
    margin: 0 7px;
    color: #5FE895;
    font-size: 12px;
    font-family: "VelaSans", sans-serif;
    font-style: normal;
    white-space: nowrap;
    font-weight: 700;
    line-height: 137.6%;
}
.footer-mobile-block.game img:nth-child(3){
    width: 2vw;
    height: 2vw;
    min-width: 8px;
    min-height: 6px;
}
.footer-mobile-block.game:hover{
    background-color: rgba(95, 232, 149, 0.2);
}



.footer-mobile-block_changeGame{
    position: absolute;
    top: 0;
    transform: translateY(calc(-100% - 24px));
    min-width: 170px;
    min-height: 200px;
    width: 170px;
    height: 200px;
    padding: 10px;
    border-radius: 14px;
    border: 1px solid #30344A;
    background: rgba(36, 38, 54, 0.90);
    backdrop-filter: blur(10px);
    justify-content: space-between;
    transition: all 0.25s ease;
}
.block_changeGame.active{
    border-radius: 10px;
    background: var(--linear, linear-gradient(203deg, #363A50 0%, #484F74 100%));
    transition: all 0.25s ease;
}
.block_changeGame{
    width: 100%;
    height: 40px;
    padding: 0 0 0 12px;
    border-radius: 10px;
    background-color: rgba(48, 52, 74, 1);
    color: #DBE0FF;
    font-size: 12px;
    font-family: "VelaSans", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 137.6%;
    letter-spacing: -0.36px;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    transition: all 0.25s ease;
    cursor: pointer;
}
.block_changeGame:nth-child(1)::before{
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(/footer/crash.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.25s ease;
}
.block_changeGame:nth-child(2)::before{
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(/footer/wheel.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.25s ease;
}
.block_changeGame:nth-child(3)::before{
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(/footer/coin.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.25s ease;
}
.block_changeGame:nth-child(4)::before{
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(/game/case/caseImage.png);
    background-position: 60px -20px;
    background-repeat: no-repeat;
    background-size: 100px;
    transition: all 0.25s ease;
}

.arrow_game.active{
    transform: rotate(180deg);
}
.arrow_game{
    transition: all 0.25s ease;
}


@media (max-width: 1640px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1280px) {

}   
@media (max-width: 1024px) {

}
@media (max-width: 980px) {

}
@media (max-width: 1024px) {
    .footer-mobile{
        display: flex;
    }
}
@media (max-width: 640px) {

}
@media (max-width: 480px) {

}
@media (max-width: 420px) {

}
@media (max-width: 350px) {
    .footer-mobile-block.game{
        padding: 0 10px;
    }
}
</style>

