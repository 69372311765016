<script>
import { useGameStore } from '@/stores/interface-interaction.js';
export default {
  name: "elements_header_right_authorization",
  data() {
    let interaction = useGameStore();
    return{
        interaction: interaction,
    }
  },
  methods: {

  },
  mounted() {

  }
};
</script>


<template>
<div class="header-right">
    <div class="authorization-button" @click="interaction.loginSteam(this.$route.fullPath)">
        <img src="/src/assets/img/header/steam.svg" alt="steam">
        <p>
            {{ $t('games.authorized') }}
        </p>
        <p>
            {{$t('chat.login')}}
        </p>
    </div>
    <div class="profile-icon settings" @click="interaction.toggleSettingsStatus()">
        <img src="/src/assets/img/header/settings.svg" alt="settings">
    </div>
</div>
</template>

<style scoped>

.header-right{
    flex-direction: row;
}
.authorization-button{
    padding: 0.9vw 1.4vw;
    flex-direction: row;
    background-color: #4DC57D;
    border-radius: 0.6vw;
    color: #FFFFFF;
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 800;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.25s ease;
}
.authorization-button p{
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 800;
}
.authorization-button:hover{
    background-color: #37995e;
}
.authorization-button img{
    width: 1vw;
    margin: 0 0.6vw 0 0;
}

.profile-icon{
    margin: 0 0 0 1vw;
    border-radius: 0.5vw;
    width: 2.4vw;
    height: 2.4vw;
    padding: 0.6vw;
    background-color: #363A50;
    transition: all 0.25s ease;
    cursor: pointer;
}
.profile-icon:hover{
    background-color: #40445d;
}


.authorization-button p:nth-child(2){
        display: flex;
}
.authorization-button p:nth-child(3){
        display: none;
}


@media (max-width: 1640px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1024px) {

}
@media (max-width: 980px) {

}
@media (max-width: 1024px) {
    .profile-icon{
        width: 34px;
        height: 34px;
        border-radius: 10px;
        padding: 8px;
    }
    .authorization-button{
        padding: 10px 20px;
        border-radius: 12px;
    }
    .authorization-button img{
        width: 18px;
        margin: 0 10px 0 0;
    }
    .authorization-button p:nth-child(2){
        display: none;
    }
    .authorization-button p:nth-child(3){
        display: flex;
    }
    .authorization-button p{
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-family: "VelaSans", sans-serif;
        font-style: normal;
        font-weight: 800;
        line-height: 137.6%;
        text-transform: uppercase;
    }
}
@media (max-width: 640px) {

}
@media (max-width: 480px) {

}
@media (max-width: 420px) {

}
@media (max-width: 380px) {

}
</style>

