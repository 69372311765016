<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import notifications_item from "@/components/popup/notifications_item.vue";
export default {
  name: "notifications_popup",
  data() {
    let interaction = useGameStore();
    return {
      interaction: interaction,
    };
  },
  components: {
    notifications_item,
  },
  methods: {

  },
  computed: {
    noticeList() {
      let list = this.interaction.notify.notifications.slice();
      list.reverse();
      return list;
    },
    unreadedLen() {
      return this.interaction.notify.notifications.filter(n => !n.viewed).length;
    },
  },
};
</script> 


<template>
  <div class="popup-notifications" :class="{ noneAnimation: !interaction.settingsSite.animations }">
    <div class="popup-header">
      <div class="popup-header-title">
        {{$t('settings.notices')}}
      </div>
      <div class="popup-header-close"
        @click="interaction.notificationsStatus = false, interaction.readAllNotify().catch((e) => { this.$toast.error(this.$t(e.message)) })">
        <img src="/src/assets/img/shop/close.svg" alt="close">
      </div>
    </div>
    <div class="popup-notifications-container">
      <transition-group name="fade">
        <notifications_item v-for="notice in noticeList" :key="notice.id" :notice="notice" />
      </transition-group>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active {
  transform: translate(0%, 0%);
  opacity: 1;
  transition: all 0.5s ease;
}

.fade-leave-active {
  transform: translate(0%, -100%);
  opacity: 0;
  transition: all 0.5s ease;
}

.fade-enter-from {
  transform: translate(0%, -100%);
  opacity: 0;
  transition: all 0.5s ease;
}

.fade-leave-to {
  transform: translate(0%, -100%);
  transition: all 0.5s ease;
}

.popup-notifications {
  position: absolute;
  bottom: 0;
  right: 3.0vw;
  transform: translate(0, calc(100% + 0.5vw));
  width: 29vw;
  height: 22.5vw;
  padding: 1.5vw 0.7vw 1.5vw 1.5vw;
  background: #363A50;
  box-shadow: 0px 0px 2.7vw rgba(0, 0, 0, 0.2);
  border-radius: 1vw;
  justify-content: flex-start;
  cursor: auto;
  overflow: hidden;
  z-index: 5;
  transition: all 0.25s ease;
}


.popup-header {
  width: 100%;
  margin: 0 0 1.2vw 0;
  justify-content: space-between;
  flex-direction: row;
}

.popup-header-title {
  text-transform: uppercase;
  color: #FFFFFF;
  font-family: "VelaSans", sans-serif;
  font-size: 1vw;
  font-weight: 800;
}

.popup-header-close {
  width: 1.6vw;
  height: 1.6vw;
  padding: 0.5vw;
  margin: 0 0.7vw 0 0;
  border-radius: 0.4vw;
  background-color: rgba(68, 72, 97, 1);
  cursor: pointer;
  transition: all 0.25s ease;
}

.popup-header-close:hover {
  background-color: #4b506d;
}


.popup-notifications-container {
  width: 100%;
  height: calc(100%);
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
}

.popup-notifications-container::-webkit-scrollbar {
  width: 0.15vw;
}




@media (max-width: 1024px) {
  .popup-notifications {
    right: 0;
    transform: translate(0, calc(100% + 10px));
    width: calc(100vw - 20px);
    min-height: 200px;
    height: 30vh;
    padding: 15px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    border-radius: 18px;
  }

  .popup-header {
    margin: 0 0 15px 0;
  }

  .popup-header-title {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 137.6%;
    letter-spacing: 0.56px;
    text-transform: uppercase;
  }

  .popup-header-close {
    width: 20px;
    height: 20px;
    padding: 5px;
    margin: 0 0 0 0;
    border-radius: 4px;
  }
}</style>
