<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import elements_inventory_item from "@/components/elements/elements_inventory_item.vue";

import gsap from 'gsap';

export default {
  name: "elements_inventory",
  data() {
    let interaction = useGameStore();
    return {
      changeCost: 0,
      interaction: interaction,
    };
  },
  components: {
    elements_inventory_item,
  },
  methods: {
    sellSelectedItems() {
      this.interaction.sellItems(this.selectedItems.map(i => i.id)).catch((e) => { this.$toast.error(this.$t(e.message)) });
    }
  },
  computed: {
    playerItems() {
      return this.interaction.inventory ?? [];
    },
    selectedItems() {
      return this.playerItems.filter(i => i.sellChecked);
    },
    selectedCost() {
      let sum = this.selectedItems.reduce((acc, cur) => {
        return acc + cur.template.price;
      }, 0) / 1000 * this.interaction.BALANCE_BY_USD;
      return sum;
    }
  },
  watch: {
    selectedCost: function (newValue) {
      gsap.to(this.$data, { duration: 0.25, changeCost: newValue });
    }
  },
  mounted() {
    this.interaction.setBodyScroll(false);
  },
  unmounted() {
    this.interaction.setBodyScroll(true);
  }
};
</script>

<template>
  <div class="inventory-main">
    <transition name="fade" mode="out-in" appear>
      <div class="inventory-container" v-if="interaction.authorization">
        <transition-group name="fadeItem">
          <elements_inventory_item v-for="item in playerItems" :key="item.id" :item="item"
            @click="item.sellChecked = !item.sellChecked" :class="{ active: item.sellChecked, locked: !item.isWithdrawal }" />
        </transition-group>
      </div>
      <div class="block-non-authorization" v-else>
        <div class="authorization-inventory-img">
          <img src="/src/assets/img/chat/lock.svg" alt="lock">
        </div>
        <div class="authorization-inventory-text">
          {{ $t('chat.seeInventory') }}
        </div>
        <div class="authorization-button" @click="interaction.loginSteam(this.$route.fullPath)">
          {{$t('chat.login')}}
        </div>
      </div>
    </transition>
    <div class="inventory-block-button">
      <button class="sell" :class="{ active: changeCost > 0 }" @click="sellSelectedItems">
        {{$t('inventory.sell')}}
        <p v-if="changeCost > 0">
          {{ changeCost > 0 ? "(" + changeCost.toFixed(2) + ")" : "" }}
        </p>
      </button>
      <button class="shop" @click="interaction.shopStatus = true">
        {{$t('chat.skinShop')}}
      </button>
    </div>
  </div>
</template>

<style scoped>
.fadeItem-enter-active {
  transform: translateY(0%);
  opacity: 1;
  transition: all 0.25s ease;
}

.fadeItem-leave-active {
  transform: translateY(-20%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fadeItem-enter-from {
  transform: translateY(-20%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fadeItem-leave-to {
  transform: translateY(-20%);
  opacity: 0;
  transition: all 0.25s ease;
}




.fade-enter-active {
  transform: translate(0%, 0%);
  opacity: 1;
  transition: all 0.25s ease;
}

.fade-leave-active {
  transform: translate(-100%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-enter-from {
  transform: translate(-100%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-leave-to {
  transform: translate(-100%, 0%);
  transition: all 0.25s ease;
}

.authorization-inventory-img {
  width: 0.7vw;
}

.authorization-inventory-text {
  width: 80%;
  color: #A1A5CD;
  font-family: "VelaSans", sans-serif;
  font-size: 0.8vw;
  font-weight: 600;
  text-align: center;
  margin: 0.3vw 0 1.2vw 0;
}

.block-non-authorization {
  width: 100%;
  height: 100%;
}

.authorization-button {
  padding: 1vw 1.4vw;
  flex-direction: row;
  background-color: #4DC57D;
  border-radius: 0.6vw;
  color: #FFFFFF;
  font-family: "VelaSans", sans-serif;
  font-size: 0.6vw;
  font-weight: 800;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.25s ease;
}

.authorization-button:hover {
  background-color: #37995e;
}


.inventory-main {
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
}

.inventory-container {
  width: calc(100% - 1vw);
  height: calc(100% - 3vw - 1vw);
  margin: 0 0 1vw 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-y: overlay;
}

.inventory-container::-webkit-scrollbar-track {
  -webkit-box-shadow: 0 0 0 0 rgba(34, 60, 80, 0) inset;
  background-color: rgba(255, 255, 255, 0.0);
  border-radius: 0;
}

.inventory-container::-webkit-scrollbar-thumb {
  border-radius: 0vw;
  background: none;
}

.inventory-block-button {
  width: calc(100% - 2vw);
  height: 3vw;
  flex-direction: row;
  justify-content: space-between;
}

button {
  border: none;
  height: 3vw;
  width: 48%;
  border-radius: 0.6vw;
  font-family: "VelaSans", sans-serif;
  font-size: 0.9vw;
  font-weight: 700;
  cursor: pointer;
}

button p {
  font-family: "VelaSans", sans-serif;
  font-size: 0.7vw;
  font-weight: 700;
}

button.sell p {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

button.sell p::before {
  content: "";
  display: flex;
  width: 0.9vw;
  height: 0.9vw;
  margin: 0 0.2vw 0 0;
  background-image: url(/coin.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

button.sell {
  background-color: #444861;
  color: #A1A5CD;
  transition: all 0.25s ease;
}

button.sell:hover {
  background-color: #4b506d;
}

button.shop {
  color: #fff;
  background-color: #4DC57D;
  transition: all 0.25s ease;
}

button.shop:hover {
  background-color: #348855;
}




@media (max-width: 1024px) {
  .inventory-main {
    justify-content: flex-start;
  }

  .inventory-container {
    width: 100%;
    height: calc(100% - 80px);
    margin: 0;
  }

  .inventory-block-button {
    position: fixed;
    bottom: 0;
    width: calc(100% - 0px);
    padding: 0 10px;
    height: 130px;
    background-color: #363A50;
    ;
  }

  .authorization-inventory-img {
    width: 20px;
  }

  .authorization-inventory-text {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%;
    letter-spacing: -0.32px;
    margin: 7px 0 20px 0;
  }

  .authorization-button {
    padding: 15px 25px;
    border-radius: 12px;
    color: #FFF;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 137.6%;
    text-transform: uppercase;
  }



  button {
    height: 48px;
    width: 48%;
    border-radius: 12px;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 160%;
    text-transform: uppercase;
  }

  button.active {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 160%;
    text-transform: uppercase;
    background: #638CF3;
    user-select: none;
  }

  button.active:hover {
    background: #8ba8f3;
  }

  button.active:active {
    background: #638CF3;
  }

  button.active p {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 160%;
    text-transform: uppercase;
  }

  button p {
    font-size: 12px;
    align-items: center;
  }

  button.sell p::before {
    width: 14px;
    height: 14px;
    margin: 0 4px 0 0;
  }
}
</style>