import { createRouter, createWebHistory } from "vue-router";
// import { defineAsyncComponent } from 'vue'

import block_mainScreen from "@/components/block_screen/block_mainScreen.vue";

const block_crash                             = () => import("@/components/block_screen/block_crash.vue");
const block_wheel                             = () => import("@/components/block_screen/block_wheel.vue");
const block_coin                              = () => import("@/components/block_screen/block_coin.vue");
const block_boost                             = () => import("@/components/block_screen/block_boost.vue");
const block_cases                             = () => import("@/components/block_screen/block_cases.vue");
const block_sapper                            = () => import("@/components/block_screen/block_sapper.vue");
const block_support                           = () => import("@/components/block_screen/block_support.vue");
const elements_support_right_block_ticket     = () => import("@/components/elements/elements_support_right_block_ticket.vue");
const elements_support_right_block            = () => import("@/components/elements/elements_support_right_block.vue");

const block_daily_bonuses = () =>
  import("@/components/block_screen/block_daily_bonuses.vue");
const block_referral_system = () =>
  import("@/components/block_screen/block_referral_system.vue");
const block_profile = () =>
  import("@/components/block_screen/block_profile.vue");
const block_profile_other_player = () =>
  import("@/components/block_screen/block_profile_other_player.vue");
const block_fair_game = () =>
  import("@/components/block_screen/block_fair_game.vue");

const elements_profile_container_inventory = () =>
  import("@/components/elements/elements_profile_container_inventory.vue");
const elements_profile_container_crash = () =>
  import("@/components/elements/elements_profile_container_crash.vue");
const elements_profile_container_wheel = () =>
  import("@/components/elements/elements_profile_container_wheel.vue");
const elements_profile_container_coin = () =>
  import("@/components/elements/elements_profile_container_coin.vue");
  const elements_profile_container_case = () =>
  import("@/components/elements/elements_profile_container_case.vue");
const elements_profile_container_double = () =>
  import("@/components/elements/elements_profile_container_double.vue");
const elements_profile_container_sapper = () =>
  import("@/components/elements/elements_profile_container_sapper.vue");
const elements_profile_container_history = () =>
  import("@/components/elements/elements_profile_container_history.vue");

// const mobile_game_history                     = () => import("@/components/mobile/mobile_game_history.vue");
// const mobile_history_crash                    = () => import("@/components/mobile/mobile_history_crash.vue");
// const mobile_history_wheel                    = () => import("@/components/mobile/mobile_history_wheel.vue");
// const mobile_history_coin                     = () => import("@/components/mobile/mobile_history_coin.vue");

const block_internal = () => import("@/components/internal/main.vue");
const block_internal_auth = () => import("@/components/internal/auth.vue");
const block_internal_bots = () => import("@/components/internal/bots.vue");
const block_internal_promocode = () =>
  import("@/components/internal/promocode.vue");
const block_internal_support = () =>
  import("@/components/internal/support.vue");
const block_internal_faq = () => import("@/components/internal/faq.vue");
const block_internal_statistic = () =>
  import("@/components/internal/statistic.vue");
const block_internal_users = () => import("@/components/internal/users.vue");
const block_internal_cases = () => import("@/components/internal/cases.vue");
const block_internal_caseCreate = () => import("@/components/internal/caseCreate.vue");

const block_rule = () => import("@/components/block_screen/block_pravila.vue");
const blcok_terms_of_use = () =>
  import("@/components/block_screen/blcok_terms_of_use.vue");
const block_privacy_policy = () =>
  import("@/components/block_screen/block_privacy_policy.vue");
const block_battlepass = () =>
  import("@/components/block_screen/block_battlepass.vue");

import { API_BASE } from "@/stores/interface-interaction.js";
import block_cases_open from "@/components/block_screen/block_cases_open.vue";

const routes = [
  { path: "/:pathMatch(.*)*", redirect: { name: "main" } },
  { path: "/", component: block_mainScreen, name: "main" },

  { path: '/rules',             component: block_rule,                             name: 'rules'},
  { path: '/terms_of_use',      component: blcok_terms_of_use,                     name: 'terms_of_use'},
  { path: '/privacy_policy',    component: block_privacy_policy,                   name: 'privacy_policy'},

  { path: '/crash',             component: block_crash,                            name: 'crash'},
  { path: '/wheel',             component: block_wheel,                            name: 'wheel'},
  { path: '/coin',              component: block_coin,                             name: 'coin'},
  { path: '/boost',             component: block_boost,                            name: 'boost'},
  { path: '/cases',             component: block_cases,                            name: 'cases'},
  { path: '/cases/open',              component: block_cases_open,                       name: 'open'},
  { path: '/sapper',            component: block_sapper,                           name: 'sapper'},
  { path: '/support',           component: block_support,                          name: 'support', redirect: {name:'faq'},
  children:[
    { path: 'faq',              component: elements_support_right_block,           name: 'faq'},
    { path: 'tickets',          component: elements_support_right_block_ticket,    name: 'tickets'},
  ]
  },
  {path: "/privacy_policy",    component: block_privacy_policy,                   name: "privacy_policy",},
  {path: "/battlepass",        component: block_battlepass,                       name: "battlepass",},

  { path: "/crash", component: block_crash, name: "crash" },
  { path: "/wheel", component: block_wheel, name: "wheel" },
  { path: "/coin", component: block_coin, name: "coin" },
  { path: "/boost", component: block_boost, name: "boost" },
  { path: "/sapper", component: block_sapper, name: "sapper" },
  {
    path: "/support",
    component: block_support,
    name: "support",
    redirect: { name: "faq" },
    children: [
      { path: "faq", component: elements_support_right_block, name: "faq" },
      {
        path: "tickets",
        component: elements_support_right_block_ticket,
        name: "tickets",
      },
    ],
  },
  { path: "/fair_game", component: block_fair_game, name: "fair_game" },
  { path: "/dailyBonuses", component: block_daily_bonuses, name: "daily" },
  {
    path: "/referralSystem",
    component: block_referral_system,
    name: "referral",
  },
  {
    path: "/profile",
    component: block_profile,
    name: "profile",
    children: [
      {
        path: "inventory",
        component: elements_profile_container_inventory,
        name: "inventory",
      },
      {
        path: "crash",
        component: elements_profile_container_crash,
        name: "crashHistory",
      },
      {
        path: "wheel",
        component: elements_profile_container_wheel,
        name: "wheelHistory",
      },
      {
        path: "coin",
        component: elements_profile_container_coin,
        name: "coinHistory",
      },
      {
        path: "case",
        component: elements_profile_container_case,
        name: "caseHistory",
      },
      {
        path: "double",
        component: elements_profile_container_double,
        name: "doubleHistory",
      },
      {
        path: "sapper",
        component: elements_profile_container_sapper,
        name: "sapperHistory",
      },
      {
        path: "transactions",
        component: elements_profile_container_history,
        name: "transactionHistory",
      },
      // { path: 'mobileGameHistory',   component: mobile_game_history,               name: 'mobileGameHistory',
      //   children:[
      //     { path: 'crash',          component: mobile_history_crash    ,   name: 'crashHistoryMobile'},
      //     { path: 'wheel',          component: mobile_history_wheel    ,   name: 'wheelHistoryMobile'},
      //     { path: 'coin',           component: mobile_history_coin     ,   name: 'coinHistoryMobile'},
      //   ]
      // },
    ],
  },
  {
    path: "/user/:userName",
    component: block_profile_other_player,
    name: "user",
    redirect: { name: "userCrashHistory" },
    children: [
      {
        path: "crash",
        component: elements_profile_container_crash,
        name: "userCrashHistory",
      },
      {
        path: "wheel",
        component: elements_profile_container_wheel,
        name: "userWheelHistory",
      },
      {
        path: "coin",
        component: elements_profile_container_coin,
        name: "userCoinHistory",
      },
      {
        path: "case",
        component: elements_profile_container_case,
        name: "userCaseHistory",
      },
      {
        path: "double",
        component: elements_profile_container_double,
        name: "userDoubleHistory",
      },
      {
        path: "sapper",
        component: elements_profile_container_sapper,
        name: "userSapperHistory",
      },
    ],
  },
  {
    path: "/auth",
    redirect: (path) => {
      let params = new URLSearchParams(path.query);
      let authLink = `${API_BASE}/auth?${params.toString()}`;
      window.location = authLink;
      return authLink;
    },
  },
  {
    path: "/internal",
    component: block_internal,
    name: "internal",
    children: [
      { path: "", component: block_internal_auth, name: "internal_auth" },
      {
        path: "bots",
        component: block_internal_bots,
        name: "internal_bots",
        meta: { requiresAdmin: true },
      },
      {
        path: "promocode",
        component: block_internal_promocode,
        name: "internal_promocode",
        meta: { requiresAdmin: true },
      },
      {
        path: "support",
        component: block_internal_support,
        name: "internal_support",
        meta: { requiresAdmin: true },
      },
      {
        path: "faq",
        component: block_internal_faq,
        name: "internal_faq",
        meta: { requiresAdmin: true },
      },
      {
        path: "statistic",
        component: block_internal_statistic,
        name: "internal_statistic",
        meta: { requiresAdmin: true },
      },
      {
        path: "users",
        component: block_internal_users,
        name: "internal_users",
        meta: { requiresAdmin: true },
      },
      {
        path: "cases",
        component: block_internal_cases,
        name: "internal_cases",
        meta: { requiresAdmin: true },
      },
      {
        path: "casecreate",
        component: block_internal_caseCreate,
        name: "internal_casecreate",
        meta: { requiresAdmin: true },
      },
      {
        path: "caseedit",
        component: block_internal_caseCreate,
        name: "internal_caseedit",
        meta: { requiresAdmin: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0, behavior: 'smooth',}
  }
})

export default router;
