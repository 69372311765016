export default
{
    en:{
        header: {
        dailyBonuses: "Daily Bonuses",
        referral: "Referral system",
        support: "Support",
        fairGame: "Fair Game",
        back: "Back",
        Close: "Close"
        },
//Основная страница
        slide: {
        title1: "We're glad \nto see you",
        descr1: 'Bonus on any replenishment 15%',
        title2: "Get a bonus \nfor friends!",
        descr2: "Invite friends \nand get bonuses",
        title3: "Win in draws!",
        descr3: "Get rewarded for following social networks \nAnd also participate in regular giveaways!",
        screen1: "Get up",
        screen2: "to 15",
        screen3: "coins",
        sdescr1: "To get the maximum bonus",
        sdescr2: "Level up on the site",
        gamemod: "Game Modes"
        },
//Ежедневные бонусы        
        bonus: {
            title: "BONUS FOR SITE AND AVATAR IN STEAM",
            descr1: "Receive daily",
            descr2: "up to 15 Sweet Coins per day!",
            descr3: "Increase your level on the site for the maximum bonus!"
        },
        bonusRules: {
            title: "BONUS TERMS",
            rules1: "Top up your account on the site from 5 sweet coins",
            action1: "REPLENISH",
            rules2: "Steam profile level 3 or higher",
            action2: "HOW TO LEVEL UP?",
            rules3: "Steam profile must be public",
            action3: "CHANGE",
            rules4: "You must have played cs:go 10 hours or more in the last 2 weeks",
            action4: "PLAY",
            check1: "All conditions are met",
            check2: "Check conditions"
        },
        bonus1: {
            title: "BONUS 1",
            descr1: "Add our service to your nickname",
            descr2: "Add cssweets to nickname",
            check1: "Check"
        },
        bonus2: {
            title: "BONUS 2",
            descr1: "Set an avatar to your Steam profile",
            descr2: "Change avatar to",
            check1: "Check"
        },
        bonusDaily: {
            title1: "Daily bonus skins",
            title2: "For our avatar or CSSweets in your STEAM nickname",
            descr1: "The longer you hold the postscript or avatar, the greater the cost of the bonus skin!",
            descr2: "DAILY BONUSES",
            descr3: "TERMS OF RECEIVING",
        },
        social: {
            title1: "Bonus for social networks",
            title2: "Subscribe to our social networks and get a bonus item!",
            channel1: "Our Telegram channel",
            channel2: "Our Discord server",
            channel3: "Our VK group",
            channel4: "Our Instagram ",
            action: "SUBSCRIBE",
//Реферальная система
        },
        referral: {
            lvl: "Referral level",
            lvlUser: "Your level",
            nextLvl: "Up to the next level",
            link: "Referral link",
            income: "Your income",
            incomeInfo1: "Your income is made up of:",
            incomeInfo2: "1. Your referral level 2. Referral rates 3. Benefits of the site in the mode in which the bet was made",
            claim: "Claim",
            minClaim: "Minimum withdrawal amount",
            referral: "Players invited",
        },
        prog: {
            lvlProg: "Program levels",
            lvl: "Level",
            nextLvl: "Up to the next level",
            bonus: "Your bonus",
            bonusValue: "from the rate"
        },
//Поддержка        
        support: {
            faq: "FAQ",
            support: "SUPPORT",
            choose: "CHOOSE A TOPIC",
            terms: "THE SUBJECT OF YOUR APPEAL",
            myTickets: "MY TICKETS:",
            createTickets: "CREATE TICKET",
            communication: "Support Dialogue",
            youProblem: "DESCRIBE YOUR PROBLEM",
            descr1: "The more detailed you describe the situation",
            descr2: "The sooner we can solve it",
            youMessege: "Enter your message",
            time: "Average response time: 30 min"
        },        
//Профиль
        profil:{
        lvl: "Your level",
        expNext: "Up to the next level",
        comment: "1 SWEETS coin = 1 EXP",
        bonusList: "Your bonuses:",
        bonus1: "coins per day",
        bonus2: "to the deposit",
        deposit: "Deposit",
        },        
        info:{
        stat1: "Games played",
        stat2: "Total Wins",
        stat3: "Amount of winnings",
        stat4: "Winrate",
        },
        lvl:{
        accountLvl: "Account LEVELS",        
        lvl: "Level",
        exp: "EXP",
        bonusList: "Level Bonuses:",
        bonus1: "Sweets-coin daily reward",
        bonus2: "to the deposit",
        },
        linkProfil:{
        referral: "Link to exchange",
        learnLink: "Learn trade link",
        enterLink: "Enter exchange link",
        save: "Save",
        promo: "Promo-code",
        enterPromo: "Enter promo code",
        use: "Use",
        },        
        inventory:{
        inventory: "Inventory",
        history: "Transaction History",
        sell: "Sell",
        all: "Select all",
        withdraw: "Withdraw",
        },
        Modes:{
        id: "Round ID",
        bid: "Bid",
        drop: "Drop",
        result: "result",
        coefficient: "Coefficient",
        date: "Date",
        },
        modes:{
        id: "Round ID",
        bid: "Bid",
        drop: "Drop",
        result: "Result",
        coefficient: "Coefficient",
        date: "Date",
        method: "Method",
        sum: "Sum",
        bonus: "Bonus",
        time: "Time",
        status: "Status",
        withdraw: "Withdraw",
        deposit: "Deposit"
        },
        footer: {
        termsOfUse: "Terms of use",
        policy: "Privacy Policy",
        social: "Social media",
        },
//ЧАТ
        chat: {
        chat: "Chat",
        answer: "Answer",
        message: "Enter message",
        online: "Online",
        skinShop: "Skin shop",
        chooseSkin: "Choose a skin",
        lockChoose: "Hide selected",
        filer: "Filters",
        choosed: "Selected:",
        search: "Search",
        login: "Login",
        seeInventory: "Log in to \nsee inventory",
        seeChoose: "Show selected",
        cancel: "Cancel", 
        },
//Настройки
        settings:{
        settings: "settings",
        language: "language",
        sounds: "Website sounds",
        animations: "site animation",
        streamer: "streamer mode",
        volume: "Volume",
        notices: "Notices"
        },
//Игры
    games:{
    before: "Before the beginning",
    go:"The game is on",
    bet: "Make a bet",
    get: "Could Get",
    claim: "Take",
    min: "Minimum bet amount",
    notauthorized: "Not authorized",
    authorized: "Sign in via steam",
    bank: "Bank",
    gameMode: "Game mode",
    chooseColor: "Choose a color",
    autowithdraw: "Auto-withdrawal",
    inGame: "In Game",
    fair: "We are for fair play!",
    descrFair: "This window serves as evidence that the wheel is complete and rotates in accordance with the main wheel. This means that nothing but the algorithm determines the loss of one of the possible coefficients`",
    players: "Players",
    },

//Поддержка
    support2:{
    ticketName: "Ticket name",
    account: "Account",
    bonus: "Bonus",
    deposit: "Deposit",
    withdraw: "Withdraw",
    site: "Site",
    game: "Games",
    cooperation: "Cooperation",
    profil: "Profil",
    modes: "Modes",
    operations: "Operations",
    accept: "Accept",
    },

//Вывод
    items:{
    selectAll: "Select All",
    withdrawalBan: "Withdrawal prohibited",
    total: "Total",
    byu: "Buy",
    day: "Day", 
    yes: "Received",
    no: "Get",
    },

//Честная игра
    fair:{
    searchGame: "Find a game",
    hash: "Server hash",
    round: "Round",
    copyHash: "Copy hash",
    check: "Check game",
    enterSeed: "Enter seed",
    checkHash: "Check hash",
    },

    toasts: {
    copyUserid: "id {id} copied",
    copyHash: "Hash copied",
    copySite: "Site copied",
    copyRefLink: "Referral link copied",
    copyCode: "Code copied",
    saveTradeLink: "Trade link saved",
    successPromocode: "Promo code redeemed",
    promoCodUsed: "Promo code already received or does not exist",
    serverError: "Server error",
    badRequest: "Bad request",
    },
    //withdraw.vue
    with:{
        errorItem: "Item not selected",
        errorLink: "Link not provided",
        success: "Output transaction started",
        withdrawSkin: "Output cs:go skins in steam",
        withdrawSkin1: "Skins output",
        changeMetod: "Choose method",
        balance: "Balance",
        oct: "Remainder",
        price: "Price",
        enterLink: "Enter trade link",
        where: "Where to get?",
        tradeLink: "Link to trade",
        },
//replenishment.vue
        rep: {
        addBalance: "Balance replenishment",
        all: "All",
        money: "Money",
        skins: "Skins",
        crypto: "Cryptocurrencies",
        enterSum: "Enter amounts",
        youGive: "You'll get",
        stay: "put",
        nikSteam: "in nick steam and get",
        bonus: "2 bonus coins",
        crypto: "Cryptocurrencies",
        promoCode: "Promo code for replenishment",
        curBonus: "current bonus",
        selectMethod: "Choose method: "
        },
//notifications_item.vue
        not:{
        not1: "Replenishment on",
        not2: "went well",
        not3: "output to",
        historyGames: "Game history",
        check: "Check",
        },
//bonus_popup.vue
        bonPop:{
        conditions: "The terms of participation",
        set: "Institutions",
        nikSteam: "to nickname Steam",
        chooseAvatar: "Choose an avatar",
        stayAvatar: "Set an avatar",
        changeAvatar: "Change avatar on steam",
        check: "Check nickname and avatar"
        },
//interface-interaction.js
        int:{
        errorPay: "Payment method error. Try later",
        plusSum: "Enter a positive amount",
        bonusItem: "Bonus item",
        },
//block_fair_game.vue
        fair1:{
        fair: "Fair game",
        allHash: "Shared hash",
        hashServer: "Hash of the North",
        checkHash: "Check hash",
        randomNum1: "Hexadecimal random number",
        randomNum2: "Decimal random number",
        formul: "Formula",
        descr: "Description",
        title: "Getting the result of game modes Crush, Wheel, Coin",
        descr1: "The result of the round is generated on the electronic device of the first 3 players who made a bet, as well as on the site server",
        descr2: "When a round starts, the game merges the server seed and the players' client seeds, the combined seeds generate a SHA256 hash, the generated hash determines the outcome of the current round",
        serverSeed: "The server seed is generated by our system as a random string of 32 characters. \n We provide an encrypted hash of the generated number before the start of the round, its value is on the game page.",
        clientSeed: "The client seed is generated on the device of the first 3 users who place a bet.",
        },
},
//РУССССССКИИИИИИИИИИИИИИИИИИЙ
    ru:{
        header: {
        dailyBonuses: "Ежедневные бонусы",
        referral: "Реферальная система",
        support: "Поддержка",
        fairGame: "Честная Игра",
        back: "Назад",
        Close: "Закрыть"
        },
//Основная страница
        slide: {
        title1: "Рады вас видеть! \nБонусы в честь \nоткрытия",
        descr1: 'Бонус при любом пополнении 15%',
        title2: "Получи бонус \nза друзей!",
        descr2: "Приглашай друзей и получай бонусы",
        title3: "Выигрывай в \nрозыгрышах!",
        descr3: "Получи награду за подписки \nна наши соц. сети, а также участвуй \nв регулярных розыгрышах",
        screen1: "Получи",
        screen2: "до 15",
        screen3: "монет",
        sdescr1: "Повышайте уровень на сайте",
        sdescr2: "Для получения максимального бонуса",
        gamemod: "Игровые режимы"
        },
//Ежедневные бонусы        
        bonus: {
            title: "БОНУС ЗА САЙТ И АВАТАР В STEAM",
            descr1: "Получай ежедневно",
            descr2: "до 15 Свит-монеток в день!",
            descr3: "Повышай свой уровень на сайте для максимального бонуса!"
        },
        bonusRules: {
            title: "УСЛОВИЯ ПОЛУЧЕНИЯ БОНУСОВ",
            rules1: "Пополните аккаунт на сайте от 12 свитс-монет",
            action1: "ПОПОЛНИТЬ",
            rules2: "Уровень профиля Steam 3 или выше",
            action2: "КАК ПОДНЯТЬ УРОВЕНЬ?",
            rules3: "Профиль Steam должен быть публичным",
            action3: "ИЗМЕНИТЬ",
            rules4: "Вы должны сыграть в cs:go 3 часа и выше за последние 2 недели",
            action4: "ИГРАТЬ",
            check1: "Все условия выполнены",
            check2: "Проверить выполнения условий"
        },
        bonus1: {
            title: "БОНУС 1",
            descr1: "Добавь в свой никнейм приписку нашего сервиса",
            descr2: "Добавь cssweets в ник",
            check1: "Проверить"
        },
        bonus2: {
            title: "БОНУС 2",
            descr1: "Установи аватар в свой профиль Steam",
            descr2: "Сменить аватар в",
            check1: "Проверить"
        },
        bonusDaily: {
            title1: "Ежедневные бонусные скины",
            title2: "За наш аватар и CSSweets в своём никнейме STEAM",
            descr1: "Чем дольше удерживаете приписку и аватар - тем больше будет стоимость бонусного скина!",
            descr2: "ЕЖЕДНЕВНЫЕ БОНУСЫ",
            descr3: "УСЛОВИЯ ПОЛУЧЕНИЯ",
        },
        social: {
            title1: "Бонус за соцсети",
            title2: "Подпишись на наши соцсети и получи бонусный предмет!",
            channel1: "Наш Телеграмм канал",
            channel2: "Наш Дискорд сервер",
            channel3: "Наша группа ВК",
            channel4: "Наш инстаграм",
            action: "ПОДПИСАТЬСЯ"
        },
//Реферальная система
        referral: {
            lvl: "Реферальный уровень",
            lvlUser: "ВАШ УРОВЕНЬ",
            nextLvl: "До следующего уровня",
            link: "Реферальная ссылка",
            income: "Ваш доход",
            incomeInfo1: "Ваш доход складывается из:",
            incomeInfo2: "1. Вашего реферального уровня 2. Ставки реферала 3. Преимущества сайта в режиме, в котором была сделана ставка",
            claim: "Забрать",
            minClaim: "Минимальная сумма вывода",
            referral: "Игроков приглашено",
        },
        prog: {
            lvlProg: "Уровни программы",
            lvl: "Уровень",
            nextLvl: "До следующего уровня",
            bonus: "Ваш бонус",
            bonusValue: "от ставки"
        },
//Поддержка        
        support: {
            faq: "Частые вопросы",
            support: "Поддержка",
            choose: "Выберите раздел",
            terms: "Тема вашего обращения",
            tick: "Мои тикеты:",
            createTick: "Создеть тикет",
            communication: "Диалог с поддержкой",
            youProblem: "ОПИШИТЕ ВАШУ ПРОБЛЕМУ",
            descr1: "Чем подробнее вами описана ситуация",
            descr2: "Тем мы быстрее сможем её решить",  
            youMessege: "Введите ваше сообщение",
            time: "Среднее время ответа: 30 мин",
        },
//Профиль
        profil:{
        lvl: "Ваш уровень",
        expNext: "До следующего уровня",
        comment: "1 SWEETS монета = 1 EXP",
        bonusList: "Ваши бонусы:",
        bonus1: "монет в день",
        bonus2: "к депозиту",   
        deposit: "Пополнить баланс",
        operations: "Операции",
        promocodeRules:"Для использования промокодов на бонусный предмет необходимо пополнить баланс на сумму не менее 15 монет (эквивалентно 1.5$) в течение последних 5 дней."
        },
        info:{
        stat1: "Сыграно игр",
        stat2: "Всего побед",
        stat3: "Сумма выигрышей",
        stat4: "Винрейт",
        },
        lvl:{
        accountLvl: "УРОВНИ аккаунта",        
        lvl: "Уровень",
        exp: "Опыта",
        bonusList: "Бонусы за уровень:",
        bonus1: "Sweets-coin ежедневной награды",
        bonus2: "к депозиту",
        },        
        linkProfil:{
        referral: "Ссылка на обмен",
        learnLink: "Узнать ссылку на обмен",
        enterLink: "Введите ссылку на обмен",
        save: "Сохранить",
        promo: "Промо-код",
        enterPromo: "Введите промо-код",
        use: "Применить",
        },
        inventory:{
        inventory: "Инвентарь",
        history: "История транзакций",
        sell: "Продать",
        all: "Выделить всё",
        withdraw: "Вывести",
        },
        modes:{
        id: "ID раунда",
        bid: "Ставка",
        drop: "Дроп",
        result: "Результат",
        coefficient: "Коэффициент",
        date: "Дата",
        method: "Метод",
        sum: "Сумма",
        bonus: "Бонус",
        time: "Время",
        status: "Статус",
        withdraw: "Вывод",
        deposit: "Ввод"
        },

        footer: {
        termsOfUse: "Пользовательское соглашение",
        policy: "Политика конфиденциальности",
        social: "Социальные сети",
        },
//ЧАТ
        chat: {
        chat: "Чат",
        answer: "Ответить",
        messege: "Введите сообщение",
        online: "Онлайн",
        skinShop: "Магазин скинов",
        chooseSkin: "Выберите скин",
        filer: "Фильтры",
        choosed: "Выбрано:",
        search: "Поиск",
        login: "Авторизоваться",
        seeInventory: "Авторизуйтесь чтобы увидеть инвентарь",
        seeChoose: "Показать выбранные",
        lockChoose: "Скрыть выбранные",
        cancel: "Отменить",
        },
//Настройки
        settings:{
        settings: "Настройки",
        language: "Язык",
        sounds: "Звуки сайта",
        animations: "Анимации",
        streamer: "Режим стримера",
        volume: "Звук",
        notices: "Уведомления"
        },
//Игры
        games:{
        before: "До начала",
        go:"Игра идёт",
        bet: "Сделать ставку",
        get: "Могли получить",
        claim: "Забрать",
        min: "Минимальная сумма ставки",
        notauthorized: "Не авторизован",
        authorized: "Авторизоваться через стим",
        bank: "Банк",
        gameMode: "Игровые режимы",
        chooseColor: "Выберите цвет",
        autowithdraw: "Автовывод",
        inGame: "В игре",
        fair: "Мы за честную игру!",
        descrFair: "Данное окно служит доказательством, что колесо является целостным и вращается в соответсветии с основным колесом. Это значит, что ничто, кроме алгоритма не определяет выпадение одного из возможных коэффициентов",
        players: "Игроки",
        },

//Поддержка
        support2:{
        ticketName: "Название тикета",
        account: "Аккаунт",
        bonus: "Бонус",
        deposit: "Депозит",
        withdraw: "Вывод",
        site: "Сайт",
        game: "Игры",
        cooperation: "Сотрудничество",
        profil: "Профиль",
        modes: "Режимы",
        operations: "Операции",
        accept: "Применить",
        },

//Вывод
        items:{
        selectAll: "Выбрать всё",
        withdrawalBan: "Вывод запрёщён",
        total: "Итого",
        byu: "Купить",
        day: "День",
        yes: "Получено",
        no: "Получить",
        },

//Честная игра
        fair:{
        searchGame: "Найти игру",
        hash: "Хэш сервера",
        round: "Раунд",
        copyHash: "Копировать хеш",
        check: "Проверить игру",
        enterSeed: "Введите сид",
        serverHash: "Хэш сервера",
        checkHash: "Проверить хэш",
        },
//withdraw.vue
        with:{
        errorItem: "Предмет не выбран",
        errorLink: "Ссылка не указана",
        success: "Запущена транзакция вывода",
        withdrawSkin: "Вывод скинов cs:go в steam",
        withdrawSkin1: "Вывод скинов",
        changeMetod: "Выберите метод",
        balance: "Баланс",
        oct: "Остаток",
        price: "Цена",
        enterLink: "Введите ссылку на трейд",
        where: "Где взять?",
        tradeLink: "Ссылка на трейд",
        },
//replenishment.vue
        rep: {
        addBalance: "Пополнение баланса",
        all: "Все",
        money: "Деньги",
        skins: "Скины",
        crypto: "Криптовалюты",
        enterSum: "Введите сумму",
        youGive: "Вы получите",
        stay: "поставь",
        nikSteam: "в ник steam и получи",
        bonus: "2 бонусные монеты",
        crypto: "Криптовалюты",
        promoCode: "Промокод на пополнение",
        curBonus: "текущий бонус",
        selectMethod: "Выберите метод: "
        },
//notifications_item.vue
        not:{
        not1: "Пополнение на",
        not2: "прошло успешно",
        not3: "начат вывод на",
        historyGames: "История игр",
        check: "Проверить",
        },
//bonus_popup.vue
        bonPop:{
        conditions: "Условия участия",
        set: "Установи",
        nikSteam: "в ник Steam",
        chooseAvatar: "Выбери аватар",
        stayAvatar: "Установи аватар",
        changeAvatar: "Сменить аватар в стим",
        check: "Проверить ник и аватар"
        },
//interface-interaction.js
        int:{
        errorPay: "Ошибка метода оплаты. Попробуйте позже",
        plusSum: "Укажите положительную сумму",
        bonusItem: "Бонусный предмет",
        },
//block_fair_game.vue
        fair1:{
        fair: "Честная игра",
        allHash: "Общий хеш",
        hashServer: "Хэш севера",
        checkHash: "Проверить хэш",
        randomNum1: "Шестнадцатеричное случайное число",
        randomNum2: "Десятичное случайное число",
        formul: "Формула",
        descr: "Описание",
        title: "Получение результата игровых режимов Crush, Wheel, Coin",
        descr1: "Результат раунда генерируется на электронном устройстве первых 3 игроков сделавших ставку, а также на сервере сайта",
        descr2: "Когда начинается раунд игра объединяет серверный сид и клиентские сиды игроков, объединенные сиды генерируют SHA256 хеш, сформированный хеш определяет исход текущего раунда",
        serverSeed: "Серверный сид генерируется нашей системой в виде случайной строки из 32 символов. \n Мы предоставляем зашифрованный хэш сгенерированного числа до начала раунда, его значение находится на странице игры.",
        clientSeed: "Клиентский сид генерируется на устройстве первых 3 пользователей сделавших ставку.",
        },


        toasts: {
        copyUserid: "id {id} скопирован",
        copyHash: "Хэш скопирован",
        copySite: "Сайт скопирован",
        copyRefLink: "Реферальная ссылка скопирована",
        copyCode: "Код скопирован",
        saveTradeLink: "Трейд ссылка сохранена",
        successPromocode: "Промокод погашен",
        promoCodUsed: "Промо-код уже получен или не существует",
        serverError: "Ошибка сервера",
        badRequest: "Неправильный запрос",
        },



        // задания бп
        make_bids: "300 ставок",
        make_bids_crash: "50 ставок в краше",
        make_bids_wheel: "50 ставок в колесе",
        make_bids_coin: "50 ставок в коине",
        make_bids_coin_red_row: "10 ставок подряд в коине красное",
        make_bids_coin_blue_row: "10 ставок подряд в коине синее",
        make_bids_coin_green_row: "10 ставок подряд в коине зеленое",
        make_bids_wheel_X24_row: "10 ставок подряд в колесе Х24",
        make_bids_wheel_X6_row: "10 ставок подряд в колесе Х6",
        make_bids_wheel_X3_row: "10 ставок подряд в колесе Х3",
        win_bids_wheel_X24: "Выиграть в колесе Х24",
        win_bids_wheel_X6: "Выиграть в колесе Х6",
        win_bids_wheel_X3: "Выиграть в колесе Х3",
        win_bids_crash_X2: "Выиграть в краше Х2",
        win_bids_crash_X3: "Выиграть в краше Х3",
        win_bids_crash_X5: "Выиграть в краше Х5",
        win_bids_coin_blue: "Выиграть в коине синее",
        win_bids_coin_red: "Выиграть в коине красное",
        win_bids_coin_green: "Выиграть в коине зеленое",

        open_cases: "Открыть 100 кейсов",
        open_cases_min_cost: "Открыть кейс стоимостью более 200 монет",
        open_case_x2: "Получить из кейса предмет стоимостью X2",
        open_case_x5: "Получить из кейса предмет стоимостью X5",
        open_case_x10: "Получить из кейса предмет стоимостью X10",
        open_cases_sum: "Открыть кейсов на общую сумму более 1000 свитс-монет",
                
    }
}