<script>
import { parseItemData } from '../../scripts/parseItemData';
import { useGameStore } from '../../stores/interface-interaction';
export default {
  name: "elements_inventory_item",
  props:{
    item: Object
  },
  data() {
    return{
        interaction: useGameStore(),
        iconErr: null,
        infoPopup: false,
    }
  },
  components: {

  },
  methods: {

  },
  computed:{
    itemData() {
      return parseItemData(this.item, this.interaction);
    },
    price() {
      return this.itemData.price;
    },
    fullName() {
      return this.itemData.fullName;
    },
    mainName() {
      return this.itemData.mainName;
    },
    secondName() {
      return this.itemData.secondName;
    },
    status() {
      return this.itemData.status;
    },
    statusShort() {
      return this.itemData.statusShort;
    },
    itemIcon() {
      return this.itemData.img + (this.iconErr ? '' : '/100fx100f');
    },
    rarityColorSet() {

      let color = this.itemData.color;
      let opacityColor = color + '99';
      return { color, opacityColor };
    },
    timerDate(){
        return this.interaction.date;
    },
    withdrawTimerString(){
        if(!this.item.withdrawStart) return '00:00';
        let endSecs = (new Date(this.item.withdrawStart)).getTime()/1000 + 10*60 ;
        let remainingSecs = endSecs - this.timerDate.getTime()/1000;
        if (remainingSecs <= 0) {
            return '00:00';
        }
        return `${String(Math.floor((remainingSecs % 3600) / 60)).padStart(2, "0")}:${String(Math.floor((remainingSecs % 60))).padStart(2, "0")}`;
    },
  },

  mounted() {

    let itemHover = this.$refs.itemHover;
    let item = this.$refs.item;

    let bindPopupOptions = {
        offsetX: -120,
        offsetY: 30,
        offsetXInvert: false,
        offsetYInvert: false,
    }



    item.onpointermove = (e) => {

    let hoverRect = itemHover.getBoundingClientRect();

    let needPositionX = e.clientX + (bindPopupOptions.offsetXInvert ? -hoverRect.width + bindPopupOptions.offsetX : bindPopupOptions.offsetX);
    let needPositionY = e.clientY + (bindPopupOptions.offsetYInvert ? -hoverRect.height + bindPopupOptions.offsetY : bindPopupOptions.offsetY);

    let screenWidth = document.documentElement.clientWidth;
    let screenHeight = document.documentElement.clientHeight;

    if (needPositionX + hoverRect.width > screenWidth) {
        needPositionX = screenWidth - hoverRect.width;
    }
    if (needPositionX < 0) {
        needPositionX = 0;
    }

    if (needPositionY + hoverRect.height > screenHeight) {
        needPositionY = screenHeight - hoverRect.height;
    }
    if (needPositionY < 0) {
        needPositionY = 0;
    }


    let percPositionX = `${needPositionX / screenWidth * 100}%`;
    let percPositionY = `${needPositionY / screenHeight * 100}%`;


    itemHover.style.left = percPositionX;
    itemHover.style.top = percPositionY;
    };

    item.onpointerenter = (e) => {
    itemHover.style.opacity = 1;
    };

    item.onpointerleave = (e) => {
    itemHover.style.opacity = 0;
    };


    document.addEventListener("scroll", ()=>{itemHover.style.opacity = 0;});






    }

};
</script>

<template>
    <div class="inventory-item" :class="{ withdraw: Boolean(item.withdrawStart) }">
        <div class="item-text">
            {{statusShort}}
        </div>
        <div class="item-img">
            <img :src="itemIcon" :alt="mainName" @error="iconErr = true">
        </div>
        <div class="item-name">
            <marquee scrollamount="3" >{{fullName.replace(/ *\([^)]*\) */g, "")}}</marquee>
            <p class="mobile_name">
                {{fullName.replace(/ *\([^)]*\) */g, "")}}
            </p>
        </div>
        <div class="item-prise">
            <span>{{price}}</span>
        </div>
        <div class="item-rarity-line" :style="{'--rarity-color': rarityColorSet.color, '--rarity-color-shadow': rarityColorSet.opacityColor}"></div>
        <div class="inventory-item-hover">
            {{fullName.replace(/ *\([^)]*\) */g, "")}}
        </div>
        <div class="locked-block_inHover">
            <div class="block_inHover-container">
                <div class="block_inHover-text">
                    {{$t('int.bonusItem')}}
                </div>
                <span class="block_inHover-link">
                    {{ $t('items.withdrawalBan') }}
                </span>
            </div>
        </div>
        <div class="withdraw-block_inHover">
            <div class="block_inHover-container_withdrow">
                <span class="withdrow_block_inHover_title">
                    Осталось:
                </span>
                <span class="block_inHover-link_withdrow">
                    {{ withdrawTimerString }}
                </span>
                <div class="withdrow_block_inHover_button" ref="item">
                    {{ interaction.isMobile == false ? 'Что это?' : '?' }}
                </div>
                <!-- <div class="withdrow_block_inHover_button" 
                    v-if="!interaction.isMobile"
                    @click.stop="infoPopup = !infoPopup"
                    ref="item"
                    >
                    Что это?
                </div>
                <div class="withdrow_block_inHover_button" 
                    v-else
                    @click.stop="infoPopup = !infoPopup"
                    ref="item"
                    >
                    ?
                </div> -->
                <teleport to="body">
                    <transition name="fadeMove" mode="out-in">
                        <span class="popupInfo_text" ref="itemHover">
                            Предмет заблокирован. После успешного вывода он появится у вас в STEAM, если вывод не осуществится - предмет будет разблокирован
                        </span>
                    </transition>
                </teleport>
                <!-- <teleport to="body">
                    <transition name="fadeMove" mode="out-in">
                        <div class="popupInfo" v-if="infoPopup">
                            <span class="popupInfo_text">
                                Предмет заблокирован. После успешного вывода он появится у вас в STEAM, если вывод не осуществится - предмет будет разблокирован
                            </span>
                            <div class="popupInfoClose" @click="infoPopup = !infoPopup"></div>
                        </div>
                    </transition>
                </teleport> -->
            </div>
        </div>
    </div>
</template>

<style scoped>
.fadeMove-enter-active {
    transform: translate(0%, 0%);
    opacity: 1;
    transition: all 0.25s ease;
}

.fadeMove-leave-active {
    transform: translate(0%, 0%);
    opacity: 0;
    transition: all 0.25s ease;
}

.fadeMove-enter-from {
    transform: translate(0%, 0%);
    opacity: 0;
    transition: all 0.25s ease;
}

.fadeMove-leave-to {
    transform: translate(0%, 0%);
    opacity: 0;
    transition: all 0.25s ease;
}


.inventory-item.withdrowPage{
    width: 7.4vw;
    height: 7.4vw;
    min-width: 7.4vw;
    min-height: 7.4vw;
    border-radius: 0.6vw;
    margin: 0 0.4vw 0.4vw 0;
    padding: 0.3vw;
}
.inventory-item.withdrowPage .item-img{
    height: calc(100% - 3.5vw);
}
.inventory-item.withdrowPage .item-img img{
    width: 4vw;
    height: 4vw;
}

.inventory-item.withdrowPage .item-text{
    width: calc(100% - 0.5vw);
    font-size: 0.8vw;
}
.inventory-item.withdrowPage .item-name{
    width: calc(100% - 0.5vw);
    font-size: 0.6vw;
}
.inventory-item.withdrowPage .item-prise{
    width: calc(100% - 0.5vw);
    font-size: 0.8vw;
    margin: 0 0 0.3vw 0;
}


.inventory-item.locked{
    background: linear-gradient(179.94deg, rgba(243, 190, 111, 0.4) 0.05%, rgba(243, 190, 111, 0) 99.95%);
    border: 0.1vw solid rgba(255, 212, 148, 0.55);
}
.inventory-item.locked:hover{
    cursor: auto;
}
.inventory-item.locked:hover .inventory-item-hover, .inventory-item.withdraw:hover .inventory-item-hover{
    opacity: 0;
    pointer-events: none;
}
.inventory-item.active{
    background-color: rgba(131, 135, 161, 0.5);
    border: 0.1vw solid rgba(131, 135, 161, 1);
}
.inventory-item{
    background-color: #444861;
    border: 0.0vw solid rgba(131, 135, 161, 0);
    border-radius: 1vw;
    height: 7.8vw;
    min-height: 7.8vw;
    width: 7.8vw;
    min-width: 7.8vw;
    padding: 0.5vw;
    margin: 0.5vw;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.25s ease;
}
/* .withdraw-block_blure{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(0.2em);
    z-index: 4;
} */
.inventory-item.withdraw .withdraw-block_inHover{
    opacity: 1;
    z-index: 5;
}

.inventory-item.withdraw .item-rarity-line,
.inventory-item.withdraw .item-prise,
.inventory-item.withdraw .item-name,
.inventory-item.withdraw .item-img,
.inventory-item.withdraw .item-text{
    filter: blur(0.2em);
}

/* .inventory-item.withdraw{
    filter: blur(0.2em);
} */
/* .inventory-item.withdraw:hover{
    filter: none;
} */
.item-img{
    height: calc(100% - 3.5vw);
}
.item-img img{
    width: 4vw;
    height: 4vw;
}
.item-text{
    width: calc(100% - 1vw);
    height: fit-content;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 700;
    color: #898FB0;
}
.item-name{
    width: calc(100% - 1vw);
    height: fit-content;
    overflow-x: hidden;
    white-space: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "VelaSans", sans-serif;
    font-size: 0.6vw;
    font-weight: 700;
    color: #898FB0;
}
.item-prise span::before{
  content: "";
  display: flex;
  width: 0.9vw;
  height: 0.9vw;
  margin: 0 0.2vw 0 0;
  background-image: url(/coin.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.item-prise{
    width: calc(100% - 1vw);
    height: fit-content;
    overflow-x: hidden;
    justify-content: flex-start;
    align-items: center;
    font-family: "VelaSans", sans-serif;
    color: #FFF;
    overflow: hidden;
    flex-direction: row;
}
.item-prise span{
    display: flex;
    align-items: center;
    font-size: 0.9vw;
    font-weight: 700;
}

.inventory-item.active:hover .inventory-item-hover{
    opacity: 0;
    pointer-events: none;
}
.inventory-item:hover .inventory-item-hover{
    opacity: 1;
    pointer-events: auto;
}


.inventory-item-hover{
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0 0.5vw;
    height: 77%;
    background-color: #444861;
    font-family: "VelaSans", sans-serif;
    font-size: 0.6vw;
    font-weight: 800;
    color: #898FB0;
    z-index: 5;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.25s ease;
}

.item-rarity-line{
    position: absolute;
    bottom: 0;
    height: 0.1vw;
    width: 50%;
    --rarity-color: #FF4949;
    --rarity-color-shadow: rgba(255, 73, 73, 0.6);
    
    background: var(--rarity-color);
    -webkit-box-shadow: 0px 0px 1.5vw 0.5vw var(--rarity-color-shadow);
    -moz-box-shadow: 0px 0px 1.5vw 0.5vw var(--rarity-color-shadow);
    box-shadow: 0px 0px 1.5vw 0.5vw var(--rarity-color-shadow);
}


.inventory-item.locked:hover .locked-block_inHover{
    opacity: 1;
    pointer-events: auto;
}

.inventory-item.withdraw:hover .withdraw-block_inHover{
    opacity: 1;
    pointer-events: auto;
}

.inventory-item.locked:hover .item-text{opacity: 0;}
.inventory-item.locked:hover .item-img{opacity: 0;}
.inventory-item.locked:hover .item-name{opacity: 0;}
.inventory-item.locked:hover .item-prise{opacity: 0;}
.inventory-item.locked:hover .item-rarity-line{opacity: 0;}


.withdraw-block_inHover{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.5vw;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.25s ease;
}
.locked-block_inHover{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.5vw;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.25s ease;
}
.block_inHover-container{
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding: 1vw 0.5vw;
    border-radius: 0.5vw;
}
.block_inHover-container_withdrow{
    width: 100%;
    height: 100%;
    padding: 0.5vw;
    justify-content: center;
    border-radius: 1vw;
    border: 1px solid #FF4949; 
    background: var(--Linear, linear-gradient(180deg, rgba(255, 73, 73, 0.20) 0%, rgba(255, 73, 73, 0.00) 100%));

}
.block_inHover-text::before{
    content: "";
    width: 0.6vw;
    height: 0.6vw;
    margin: 0 0 0.3vw 0;
    background: url(/popup/crown.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.block_inHover-text{
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 600;
    line-height: 100.0%;
    color: rgba(255, 212, 148, 1);
    text-align: center;
}
.block_inHover-link{
    width: 100%;
    padding: 0.3vw 0;
    border-radius: 0.4vw;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.6vw;
    color: rgba(255, 115, 132, 1);
    background: rgba(255, 115, 132, 0.3);
    transition: all 0.25s ease;
}
.block_inHover-link_withdrow{
    color: #FFF;
    text-align: center;
    font-family: "VelaSans", sans-serif;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 600;
    line-height: 137.6%; /* 35.776px */
    transform: translateY(-0.5vw);
}
.withdrow_block_inHover_title{
    color: #FFF;
    text-align: center;
    font-family: "VelaSans", sans-serif;
    font-size: 0.6vw;
    font-style: normal;
    font-weight: 500;
    line-height: 137.6%; /* 16.512px */
    transform: translateY(-0.5vw);
}
.withdrow_block_inHover_button{
    position: absolute;
    bottom: 0.5vw;
    width: 90%;
    color: #FFF;
    text-align: center;
    font-family: "VelaSans", sans-serif;
    font-size: 0.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: 137.6%; /* 13.76px */
    text-transform: uppercase;
    border-radius: 0.4vw;
    padding: 0.5vw 0;
    background: rgba(255, 73, 73, 0.90);
}


.mobile_name{
    display: none;
}


.popupInfo{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
    transition: all 0.25s ease;
}
.popupInfo_text{
    position: fixed;
    opacity: 0;
    pointer-events: none;

    max-width: 280px;
    border-radius: 10px;
    padding: 15px;
    border: 2px solid #494D65;
    background: #363A50;
    color: #DBE0FF;
    font-family: "VelaSans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 20.4px */
    z-index: 22;
}
.popupInfoClose{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 21;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(0.2em);
    transition: all 0.25s ease;
}



@media (max-width: 1024px) {
    .inventory-item.locked{
        background: linear-gradient(179.94deg, rgba(243, 190, 111, 0.4) 0.05%, rgba(243, 190, 111, 0) 99.95%);
        border: 1px solid rgba(255, 212, 148, 0.55);
    }
    .inventory-item.locked:hover{
        cursor: auto;
    }
    .inventory-item.locked:hover .inventory-item-hover{
        opacity: 0;
        pointer-events: none;
    }
    .inventory-item.active{
        background-color: rgba(131, 135, 161, 0.5);
        border: 1px solid rgba(131, 135, 161, 1);
    }
    .inventory-item{
        background-color: #444861;
        border: 0.0vw solid rgba(131, 135, 161, 0);
        border-radius: 15px;
        height: 115px;
        min-height: 115px;
        width: 115px;
        min-width: 115px;
        padding: 10px;
        margin: 4px;
        justify-content: space-between;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.25s ease;
    }

    .inventory-item.withdrowPage .item-img{
        height: calc(100% - 50px);
    }
    .inventory-item.withdrowPage .item-img img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .item-img{
        height: calc(100% - 50px);
    }
    .item-img img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .inventory-item.withdrowPage .item-text {
        width: calc(100% - 0px);
        color: #898FB0;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.22px;
    }
    .item-text{
        width: calc(100% - 0px);
        color: #898FB0;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.22px;
    }
    .mobile_name{
        display: inline;
        width: calc(100% - 0px);
        overflow: hidden;
        color: #898FB0;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.22px;
    }
    .item-name marquee{
        display: none;
    }
    .item-name{
        width: calc(100% - 0px);
        overflow: hidden;
        color: #898FB0;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.22px;
    }
    .item-prise span::before{
        content: "";
        display: flex;
        width: 13px;
        height: 13px;
        margin: 0 4px 0 0;
    }

    .item-prise{
        width: calc(100% - 0px);
        height: fit-content;
        overflow-x: hidden;
        justify-content: flex-start;
        align-items: center;
        font-family: "VelaSans", sans-serif;
        color: #FFF;
        overflow: hidden;
        flex-direction: row;
    }
    .item-prise span{
        color: #FFF;
        font-size: 13.92px;
        font-style: normal;
        font-weight: 700;
        line-height: 138%;
        letter-spacing: -0.278px;
    }





    .inventory-item-hover {
        padding: 0 5px;
        height: 77%;
        background-color: #444861;
        font-family: "VelaSans", sans-serif;
        font-size: 11px;
        font-weight: 700;
        color: #898FB0;
        z-index: 5;
        text-align: center;
        opacity: 0;
        pointer-events: none;
        transition: all 0.25s ease;
    }
    .locked-block_inHover{
        border-radius: 10px;
    }
    .block_inHover-container{
        padding: 10px 5px;
        border-radius: 10px;
    }
    .block_inHover-text{
        font-size: 14px;
    }
    .block_inHover-text::before {
        width: 14px;
        height: 14px;
        margin: 0 0 8px 0;
    }
    .block_inHover-link{
        padding: 4px 0;
        border-radius: 8px;
        font-size: 10px;
    }

    .item-rarity-line{
        height: 1px;
        box-shadow: 0px 0px 20px 10px var(--rarity-color-shadow);
    }
    


    .block_inHover-container_withdrow{
        border-radius: 16px;
        padding: 10px;
    }
    .block_inHover-link_withdrow{
        font-size: 26px;
        transform: translateY(0px);
    }
    .withdrow_block_inHover_title{
        font-size: 12px;
        transform: translateY(0px);
    }
    .withdrow_block_inHover_button{
        top: 6px;
        right: 6px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        bottom: unset;
        font-size: 10px;
    }
}

</style>