<script>
import { useGameStore } from '@/stores/interface-interaction.js';
import notifications from "@/components/popup/notifications.vue";
import replenishment from "@/components/popup/replenishment.vue";
import withdraw from "@/components/popup/withdraw.vue";

import gsap from 'gsap';

export default {
  name: "elements_header_right",
  data() {
    let interaction = useGameStore();
    return{
        statusPopupWithdrow: false,
        tweenedNumber: Number(interaction.userFullBalance),
        interaction: interaction,
    }
  },
  components: {
    notifications,
    replenishment,
    withdraw,
  },    
  methods: {
    changeStatusPopupWithdrow(){
      this.statusPopupWithdrow = !this.statusPopupWithdrow;
    },
  },
  computed:{
    unreadedLen(){
        return this.interaction.notify.notifications.filter(n => !n.viewed).length;
    },
    profileImage(){
      return this.interaction.user?.avatar ?? '/defaultAvatar.png';
    },
    balance(){
      return this.interaction.userFullBalance;
    },
    animatedNumber: function() {
      return this.tweenedNumber.toFixed(2);
    },
    profileLevel(){
      return this.interaction.user?.userLevelId ?? 0;
    },
    enablePopup(){
      if(this.interaction.replenishmentStatus){
        return replenishment;
      }
      if(this.interaction.withdrowStatus){
        return withdraw;
      }
      return undefined;
    },
  },
  watch: {
    balance: function(newValue, oldValue) {
      if(oldValue < newValue)this.interaction.playSound('increaseBalance');
      gsap.to(this.$data, { duration: 0.5, tweenedNumber: newValue });
    },
    "interaction.inventory": function(newValue, oldValue) {
      if(oldValue.length < newValue.length)this.interaction.playSound('increaseBalance');
    },
  }
};
</script>


<template>
<div class="header-right">
    <div class="header-block-balance">
        <div class="balance">
           <span class="Bcoin">{{animatedNumber}}</span>
        </div>
        <div class="add-icon" 
                :class="{active: statusPopupWithdrow}"
                @click="changeStatusPopupWithdrow(),
                interaction.notificationsStatus = false"
              >
            <img src="/src/assets/img/header/add.svg" alt="arrow">
        </div>
        <div class="header-popup-balance" :class="{active: statusPopupWithdrow}">
          <div class="balance-pop-add" @click="interaction.toggleReplenishmentStatus(), statusPopupWithdrow = false">
            <img src="/src/assets/img/header/plus.svg" alt="add"/>
            {{$t('profil.deposit')}}
          </div>
          <div class="balance-pop-withdrow" @click="interaction.toggleWithdrowStatus(), statusPopupWithdrow = false">
            <img src="/src/assets/img/header/arrow.svg" alt="withdrow"/>
            {{$t('inventory.withdraw')}}
          </div>
        </div>
    </div>
    <div class="profile-block" @click="$router.push({name: 'inventory'}), interaction.mobileMenuStatus = false">
        <div class="profile-img">
            <img :src="profileImage" alt="profile">
        </div>
        <div class="profile-block-lvl">
            {{ profileLevel }} lvl
        </div>
    </div>
    <div class="profile-icon settings" @click="interaction.toggleSettingsStatus()">
        <img src="/src/assets/img/header/settings.svg" alt="settings">
    </div>
    <div class="profile-icon notifications" @click="interaction.toggleNotificationsStatus(), statusPopupWithdrow = false">
        <img src="/src/assets/img/header/notifications.svg" alt="notifications">
        <div class="notifications-amount">
            {{unreadedLen}}
        </div>
    </div>
    <transition name="fade" mode="out-in" appear>
        <notifications v-if="interaction.notificationsStatus"/>
    </transition>
    <div class="profile-icon logout" @click="interaction.logout()">
        <img src="/src/assets/img/header/logout.svg" alt="logout">
    </div>
    <teleport to="body">
      <transition name="fadeMove" mode="out-in" appear>
          <component :is="enablePopup" v-if="enablePopup"/>
      </transition>
    </teleport>
</div>
</template>


<style scoped>
.fadeMove-enter-active {
  transform: translate(0%, 0%);
  opacity: 1;
  transition: all 0.25s ease;
}

.fadeMove-leave-active {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fadeMove-enter-from {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fadeMove-leave-to {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-enter-active {
  opacity: 1;
  transition: all 0.25s ease;
}

.fade-leave-active {
  transform: translate(0, calc(100% + 0.5vw)) scale(0.9);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-enter-from {
  transform: translate(0, calc(100% + 0.5vw)) scale(0.9);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-leave-to {
  transform: translate(0, calc(100% + 0.5vw)) scale(0.9);
  opacity: 0;
  transition: all 0.25s ease;
}




.header-right{
    flex-direction: row;
}


.header-block-balance{
    flex-direction: row;
    background-color: #363A50;  
    border-radius: 0.6vw;
    padding: 0.5vw 0.6vw;
}
.balance::before{
  content: "";
  display: flex;
  width: 0.9vw;
  height: 0.9vw;
  min-width: 0.9vw;
  min-height: 0.9vw;
  margin: 0 0.2vw 0 0;
  background-image: url(/coin.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.balance{
  flex-direction: row;
}
.balance span{
    color: #fff;
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 800;
    margin: 0 0.6vw 0 0;
    transform: translate(0px, 0.1vw);
}
.add-icon.active img{
  transform: rotate(180deg);
}
.add-icon{
    width: 1.7vw;
    height: 1.7vw;
    border-radius: 0.5vw;
    background-color: #5FE895;  
    transition: all 0.25s ease;
    cursor: pointer;
}
.add-icon img{
  width: 0.7vw;
  height: 0.7vw;
  transition: all 0.25s ease;
}
.add-icon:hover{
    background-color: #67ffa4;  
}
.profile-img{
    border-radius: 0.6vw;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.profile-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.profile-block{
    margin: 0 0.5vw 0 0.7vw;
    border-radius: 0.6vw;
    width: 3vw;
    height: 3vw;
    border: 0.1vw solid #4F536D;
    background-color: #4F536D;  
    cursor: pointer;
}
.profile-block-lvl{
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 2.4vw;
    width: 2.4vw;
    max-width: 2.4vw;
    transform: translate(15%, 50%);
    border-radius: 0.4vw;
    padding: 0px 0.4vw;
    background-color: #4F536D;
    color: #5FE895;
    font-family: "VelaSans", sans-serif;
    font-size: 0.6vw;
    font-weight: 800;
}
.profile-icon{
    margin: 0 0 0 0.7vw;
    border-radius: 0.5vw;
    width: 2.4vw;
    height: 2.4vw;
    padding: 0.7vw;
    background-color: #363A50;
    transition: all 0.25s ease;
    cursor: pointer;
}
.profile-icon:hover{
    background-color: #40445d;
}
.notifications-amount{
    position: absolute;
    top: 0;
    right: 0;
    width: 0.9vw;
    height: 0.9vw;
    transform: translate(30%, -30%);
    border-radius: 50%;
    background-color: #5FE895;
    color: #1A6C3B;
    font-family: "VelaSans", sans-serif;
    font-size: 0.6vw;
    font-weight: 800;
}
.profile-icon.logout img{
    transform: translate(-0.1vw, 0);
}



.header-popup-balance.active{
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0px, 3.4vw);
  width: 11.5vw;
  height: 7vw;
  padding: 0.7vw;
  background-color: rgba(54, 58, 80, 1);
  border-radius: 0.6vw;
  box-shadow: 0px 0px 0.5vw rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  z-index: 6;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.25s ease;
}
.header-popup-balance.active > div{
  opacity: 1;
}
.header-popup-balance{
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0px, -0.2vw) scale(0.1);
  width: 11.5vw;
  height: 7vw;
  padding: 0.7vw;
  background-color: rgba(54, 58, 80, 1);
  border-radius: 0.6vw;
  justify-content: space-between;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  transition: all 0.25s ease;
}
.header-popup-balance > div{
  opacity: 0;
}
.balance-pop-add{
    width: 100%;
    height: 2.5vw;
    border-radius: 0.5vw;
    padding: 0.7vw;
    background-color: rgba(63, 67, 91, 1);
    color: rgba(219, 224, 255, 1);
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 600;
    flex-direction: row;
    justify-content: flex-start;
    transition: all 0.25s ease;
    cursor: pointer;
}
.balance-pop-add img{
  width: 1.5vw;
  height: 1.5vw;
  padding: 0.4vw;
  background: linear-gradient(160.74deg, #DBE0FF 12.95%, rgba(219, 224, 255, 0.59) 131.94%);
  border-radius: 0.4vw;
  margin: 0 0.6vw 0 0;
  transition: all 0.25s ease;
}


.balance-pop-add:hover img{
  margin: 0 1vw 0 0px;
}
.balance-pop-withdrow:hover img{
  transform: rotate(90deg);
  margin: 0 1vw 0 0px;
}
.balance-pop-withdrow{
  width: 100%;
  height: 2.5vw;
  border-radius: 0.5vw;
  padding: 0.7vw;
  background-color: rgba(63, 67, 91, 1);
  color: rgba(219, 224, 255, 1);
  font-family: "VelaSans", sans-serif;
  font-size: 0.7vw;
  font-weight: 600;
  flex-direction: row;
  justify-content: flex-start;
  transition: all 0.25s ease;
  cursor: pointer;
}
.balance-pop-withdrow img{
  width: 1.5vw;
  height: 1.5vw;
  padding: 0.4vw;
  background: linear-gradient(160.74deg, #DBE0FF 12.95%, rgba(219, 224, 255, 0.59) 131.94%);
  border-radius: 0.4vw;
  margin: 0 0.6vw 0 0;
  transition: all 0.25s ease;
}






@media (max-width: 1640px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1024px) {

}
@media (max-width: 980px) {

}
@media (max-width: 1024px) {
    .logout{
      display: none;
    }
    .profile-icon{
      width: 34px;
      height: 34px;
      padding: 9px;
      border-radius: 10px;
      margin: 0 0 0 5px;
    }
    .notifications-amount{
      width: 16px;
      height: 16px;
      font-size: 12px;
    }

    .profile-block{
      width: 44px;
      height: 44px;
      border-radius: 14px;
      margin: 0 5px 0 5px;
    }
    .profile-block img{
      border-radius: 14px;
    }
    .profile-block-lvl{
      width: 34px;
      max-width: 34px;
      transform: translate(15%, 50%);
      border-radius: 6px;
      padding: 0px 2px;
      background-color: #4F536D;
      color: #5FE895;
      font-family: "VelaSans", sans-serif;
      font-size: 10px;
      font-weight: 800;
    }

    .header-block-balance{
      border-radius: 12px;
      padding: 10px 12px;
    }
    .balance span{
      font-size: 14px;
      font-weight: 800;
      margin: 0 12px 0 0;
      transform: translate(0px, 0px);
    }
    .balance::before {
      width: 15px;
      height: 15px;
      min-width: 15px;
      min-height: 15px;
      margin: 0 4px 0 0;
    }

    .add-icon{
      width: 28px;
      height: 28px;
      border-radius: 8px;
    }
    .add-icon img{
      width: 12px;
      height: 12px;
    }

    .header-popup-balance.active{
      transform: translate(-20px, 55px);
      width: 210px;
      height: 105px;
      padding: 10px;
      border-radius: 14px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
    .balance-pop-add{
      height: 40px;
      border-radius: 10px;
      padding: 8px;
      font-size: 14px;
    }
    .balance-pop-add img {
      width: 24px;
      height: 24px;
      padding: 8px;
      border-radius: 6px;
      margin: 0 5px 0 0;
    }
    .balance-pop-withdrow{
      height: 40px;
      border-radius: 10px;
      padding: 8px;
      font-size: 14px;
    }
    .balance-pop-withdrow img {
      width: 24px;
      height: 24px;
      padding: 8px;
      border-radius: 6px;
      margin: 0 5px 0 0;
    }

}
@media (max-width: 640px) {

}
@media (max-width: 480px) {

}
@media (max-width: 420px) {

}
@media (max-width: 390px) {
  .profile-icon{
      width: 25px;
      height: 25px;
      padding: 6px;
      border-radius: 6px;
      margin: 0 0 0 5px;
    }
    .notifications-amount{
      width: 12px;
      height: 12px;
      font-size: 8px;
    }

    .profile-block{
      width: 36px;
      height: 36px;
      border-radius: 10px;
      margin: 0 0px 0 5px;
    }
    .header-block-balance{
      border-radius: 10px;
      padding: 9px 7px;
    }
    .balance span{
      font-size: 12px;
      font-weight: 800;
      margin: 0 6px 0 0;
      transform: translate(0px, 0px);
    }
    .balance::before {
      width: 12px;
      height: 12px;
      min-width: 12px;
      min-height: 12px;
      margin: 0 4px 0 0;
    }
    .add-icon{
      width: 20px;
      height: 20px;
      border-radius: 6px;
    }
    .add-icon img{
      width: 10px;
      height: 10px;
    }
}
</style>

