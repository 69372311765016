<script>
import { parseItemData } from '../../scripts/parseItemData';
import { useGameStore } from '../../stores/interface-interaction';

export default {
  name: "elements_live_drop_item",
  props: {
    drop: Object
  },
  data() {
    return {
      iconErr: null,
    }
  },
  components: {

  },
  methods: {

  },
  computed: {
    items() {
      return this.drop?.rewardItems ?? [];
    },
    sum() {
      return +this.drop.rewardSum;
    },
    item() {
      return this.items[0];
    },
    itemData() {
      return parseItemData(this.item, useGameStore());
    },
    price() {
      return this.itemData.price;
    },
    fullName() {
      return this.itemData.fullName;
    },
    mainName() {
      return this.itemData.mainName;
    },
    secondName() {
      return this.itemData.secondName;
    },
    status() {
      return this.itemData.status;
    },
    statusShort() {
      return this.itemData.statusShort;
    },
    itemIcon() {
      return this.itemData.img + (this.iconErr ? '' : '/100fx100f');
    },
    rarityColorSet() {

      let color = this.itemData.color;
      let opacityColor = color + '99';
      return { color, opacityColor };
    },
    user(){
      return this.drop?.user?.username;
    }
  }
};
</script>

<template>
  <div class="live-element" @click="$router.push({name: 'userCrashHistory', params:{userName: user}})">
    <div class="normal_element">
      <div class="live-element-title">
        <span> <p>
          {{ secondName }} 
              </p> 
        </span>
        <span> <p>
          {{ mainName }} 
              </p> 
        </span>
      </div>
      <div class="live-item-img">
        <img :src="itemIcon" :alt="mainName" @error="iconErr = true">
      </div>
      <div class="item-rarity-line"
        :style="{ '--rarity-color': rarityColorSet.color, '--rarity-color-shadow': rarityColorSet.opacityColor }"></div>
    </div>
    <div class="hover_element" :class="{coin: drop.mode =='coin',
                                        wheel: drop.mode =='wheel',
                                        crash: drop.mode =='crash',
                                        'case': drop.mode =='case',}">
      <div class="live-element-title">
        <span> <p class="cost">
          {{ sum }} 
              </p> 
        </span>
        <span> <p>
          {{ user }} 
              </p> 
        </span>
      </div>
      <div class="live-item-img">
      </div>
    </div>
  </div>
</template>

<style scoped>

.cost{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.cost::before{
    content: "";
    display: flex;
    width: 0.8vw;
    height: 0.8vw;
    min-width: 0.8vw;
    min-height: 0.8vw;
    margin: 0 0.2vw 0 0;
    background-image: url(/coin.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.normal_element{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  pointer-events: auto;
  opacity: 1;
  transition: all 0.25s ease;
}

.hover_element.coin{
  background-image: url(/livedrop/coin.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hover_element.wheel{
  background-image: url(/livedrop/wheel.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hover_element.crash{
  background-image: url(/livedrop/crash.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hover_element.case{
  background-image: url(/livedrop/case.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}




.hover_element{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  pointer-events: none;
  opacity: 0;
  transition: all 0.25s ease;
}


.live-element:hover .hover_element{
  pointer-events: auto;
  opacity: 1;
}
.live-element:hover .normal_element{
  pointer-events: none;
  opacity: 0;
}



.live-element {
  width: 10vw;
  min-width: 10vw;
  height: 100%;
  padding: 0 1vw;
  margin: 0 0.6vw 0 0;
  border-radius: 0.7vw;
  background-color: rgba(54, 58, 80, 1);
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.25s ease;
}

.live-element-title{
  width: 5vw;
  padding: 0 0 0 0;
  overflow-x: hidden;
}
.live-element-title span {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: nowrap;
  overflow: hidden;
}
.live-element-title span p{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.live-element-title span:nth-child(1) p{
  width: 100%;
  font-family: "VelaSans", sans-serif;
  font-size: 0.8vw;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
}

.live-element-title span:nth-child(2) p{
  width: 100%;
  font-family: "VelaSans", sans-serif;
  font-size: 0.6vw;
  font-weight: 600;
  color: rgba(185, 189, 227, 1);
}

.live-item-img {
  width: 3vw;
  height: 3vw;
  min-width: 3vw;
  min-height: 3vw;
  margin: 0 0 0 0.5vw;
}

.item-rarity-line {
  position: absolute;
  bottom: 0;
  height: 0.1vw;
  width: 80%;
  --rarity-color: #FF4949;
  --rarity-color-shadow: rgba(255, 73, 73, 0.6);

  background: var(--rarity-color);
  -webkit-box-shadow: 0px 0px 1.5vw 0.5vw var(--rarity-color-shadow);
  -moz-box-shadow: 0px 0px 1.5vw 0.5vw var(--rarity-color-shadow);
  box-shadow: 0px 0px 1.5vw 0.5vw var(--rarity-color-shadow);
}




@media (max-width: 1024px) {
  .item-rarity-line{
    height: 1px;
  }
  .live-element {
    width: 70px;
    min-width: 70px;
    padding: 0 10px;
    margin: 0 5px 0 0;
    border-radius: 15px;
  }
  .live-element-title{
    display: none;
  }
  .live-item-img {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    margin: 0 0 0 0;
  }
}


</style>