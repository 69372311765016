<script>
export default {
  name: "component_footer",
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  },
  mounted() {
    // Update the year every second (optional)
    setInterval(() => {
      this.currentYear = new Date().getFullYear();
    }, 1000);
  }
};
</script>

<template>
    <div class="footer">
      <div class="footer-block">
        <div class="footer-block-item">
          <div class="footer-img" @click="$router.push({ name: 'main' })">
            <img src="/src/assets/img/header/logo.svg" alt="logo">
          </div>
          <div class="footer-text">
            {{ currentYear }} cssweets
          </div>
        </div>
        <div class="footer-block-item">
          <div class="footer-text">
            {{ $t('footer.social') }}
          </div>
          <div class="footer-icon">
            <a href="https://instagram.com/_cssweets_csgo?igshid=NTc4MTIwNjQ2YQ==" target="_blank">
              <img src="/src/assets/img/menu/instagram.svg" alt="instagram">
            </a>
          </div>
          <div class="footer-icon">
            <a href="https://t.me/CSSWEETS1" target="_blank">
              <img src="/src/assets/img/menu/telegram.svg" alt="telegram">
            </a>
          </div>
          <div class="footer-icon">
            <a href="https://vk.com/public221074435" target="_blank">
              <img src="/src/assets/img/menu/vk.svg" alt="vk">
            </a>
          </div>
          <div class="footer-icon">
            <a href="https://discord.gg/dKSkUeyVaJ" target="_blank">
              <img src="/src/assets/img/menu/discord.svg" alt="discord">
            </a>
          </div>
        </div>
      </div>
      <div class="footer-link">
        <RouterLink to="/terms_of_use" class="link-item">
          {{ $t('footer.termsOfUse') }}
        </RouterLink>
        <RouterLink to="/privacy_policy" class="link-item">
          {{ $t('footer.policy') }}
        </RouterLink>
      </div>
    </div>
</template>

<style scoped>
  .footer{
    width: 100%;
    height: fit-content;
    margin: 0 0 20px 0;
  }
  .footer-block{
    width: 100%;
    padding: 0 1.5vw;
    height: 6vw;
    border-radius: 1vw;
    background-color: #363A50;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer-block-item{
    height: 100%;
    flex-direction: row;
  }
  .footer-icon{
    width: 1.5vw;
    height: 1.5vw;
    margin: 0 0 0 1vw;
    transition: all 0.25s ease;
    cursor: pointer;
  }
  .footer-icon:hover{
    transform: scale(1.19);
  }

  .footer-link{
    margin: 1vw 0 0 0;
    flex-direction: row;
  }
  .link-item{
    margin: 0 0.5vw;
    color: #61657D;
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.25s ease;
  }
  .link-item:hover{
    color: #7b809d;
  }
  .footer-img{
    width: 10vw;
    height: 2vw;
    margin: 0 0.5vw 0 0;
    cursor: pointer;
  }
  .footer-title{
    margin: 0 0 0 0.5vw;
    color: #FFF;
    font-family: "Raleway", "VelaSans", sans-serif;
    font-size: 1.2vw;
    font-weight: 500;
    flex-direction: row;
  }
  .footer-title span{
    display: flex;
    margin: 0 0.5vw 0 0;
    font-weight: 700;
  }
  .footer-text{
    transform: translate(0, 0.15vw);
    text-transform: uppercase;
    color: #767A94;
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 800;
  }




  @media (max-width: 1280px) {
    .footer{
        margin: 0 0 100px 0;
    }
} 
@media (max-width: 1024px) {
    .footer{
        display: none;
    }
} 
</style>