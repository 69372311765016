<script>
import noUiSlider from 'nouislider';
export default {
    name: "interface_slider",
    props: {
        'model-value': Array,
        'range': {
            type: Object,
            default: () => {
                return {
                    min: 0,
                    '30%': 100,
                    '50%': 1000,
                    '85%': 5000,
                    max: 10000
                }
            }
        }
    },
    emits: ['update:model-value'],
    mounted() {

        let slider = this.$refs.slider;
        noUiSlider.create(slider, {
            start: this.modelValue,
            connect: true,
            range: this.range,
            step: 0.01,
            tooltips: {
                to: v => +v,
                from: v => +v
            },
        });

        slider.noUiSlider.on('update', (values) => {
            this.$emit('update:model-value', values);
        });

    },
};
</script>


<template>
    <div ref="slider" class="slider"></div>
</template>

<style scoped>
.slider {
    margin: 0px 0px 0px 0px;
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 0.2vw;
    border: none;
    outline: none;
    border: 0.1vw solid rgba(68, 72, 97, 1);
    background-color: rgba(68, 72, 97, 1);
    border-radius: 0.3vw;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}
</style>
