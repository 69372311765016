<script>
import { useGameStore } from '@/stores/interface-interaction.js';

export default {
  name: "elements_chat_open",
  data() {
    let interaction = useGameStore();
    return{
        interaction: interaction,
    }
  },
  components: {

  },
  methods: {

  },
  mounted() {

  }
};
</script>

<template>
  <div class="chat-open" :class="{main: $route.name == 'main'}">
    <div class="chat-hide" @click="interaction.toggleChatStatus(), interaction.currentTab = 'elements_chat'">
      <img src="/src/assets/img/chat/chat.svg" alt="chat">
    </div>
    <div class="chat-hide inventory" @click="interaction.toggleChatStatus(), interaction.currentTab = 'elements_inventory'">
      <img src="/src/assets/img/chat/inventory.svg" alt="chat">
    </div>
  </div>
</template>

<style scoped>

.chat-open{
    position: sticky;
    right: 0px;
    transform: translate(-0.1vw, 0px);
    top: 7vw;
    width: 2.5vw;
    align-items: flex-start;
    transition: all 0.5s ease;
}
.chat-hide.inventory{
  margin: 0.5vw 0 0 0.2vw;
}
.chat-hide{
    margin: 0 0 0 0.2vw;
    width: 2.2vw;
    height: 2.2vw;
    padding: 0.4vw;
    border-radius: 0.4vw;
    background-color: rgba(99, 140, 243, 0.15);
    transition: all 0.25s ease;
    cursor: pointer;
}
.chat-hide:hover{
    background-color: #525777;
}

.chat-hide img{
  width: 0.8vw;
  height: 0.8vw;
}

@media (max-width: 1640px) {
}
@media (max-width: 1440px) {
    .chat-open.main{
      top: calc(7vw + 4vw);
    }
    .chat-open{
      position: fixed;
      transform: translate(-0.1vw, 0px);
  }
}
@media (max-width: 1024px) {
  .chat-open{
    display: none;
  }
}
</style>