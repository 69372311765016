<script>
import { useGameStore } from '../../stores/interface-interaction';
export default {
  name: "ban_popup",
  props:{
    user: Object
  },
  emits: ['close'],
  data() {
    let interaction = useGameStore();
    return{
        interaction: interaction,

        banWithdraw: false,
        banGame: false,
        banChat: true,

        banDays: 1,
        banHours: 0
        
    }
  },
  components:{

  },
  computed: {
    profileImage(){
        return this.user.avatar;
    },
    profileName(){
        return this.user.username ?? '???';
    },
    profileLevel(){
        return this.user.userLevelId ?? '?';
    },
  },
  methods: {
    goToProfile(username){
        this.$router.push({name: 'userCrashHistory', params:{userName: username}});
        if(window.innerWidth < 768){
            useGameStore().toggleChatStatus();
        }
    },
    async banUser(forever){


        try {

            if(!(this.banChat || this.banGame || this.banWithdraw)){
                throw new Error("не выбран способ бана");
            }

            const banTo = new Date();

            if(forever){
                banTo.setFullYear(banTo.getFullYear() + 100);
            }else{
                banTo.setDate(banTo.getDate() + this.banDays);
                const banMinutes = banTo.getMinutes() + this.banHours*60;
                banTo.setMinutes(banMinutes);
            }


            const ban = await this.interaction.banUser(+this.user.id, banTo, this.banChat, this.banGame, this.banWithdraw);
            this.$toast.success(`Забанен ${this.user.username} до ${(new Date(ban.to)).toLocaleString()} в ${this.banChat ? "чате" : ""} ${this.banGame ? "игре" : ""} ${this.banWithdraw ? "выводе" : ""}`);
            this.$emit('close');
        } catch (e) {
            this.$toast.error(this.$t(e.message));
        }
        
    },
  },
};
</script>

<template>
<div class="ban_popup">
                    <div class="ban_popup-block">

                        <div class="ban_popup-block-top">
                            <div class="item-profile" @click="goToProfile(user.username)">
                                <div class="item-img">
                                    <img :src="profileImage" alt="icon">
                                </div>
                                <div class="item-lvl">
                                    {{ profileLevel }} lvl
                                </div>
                            </div>
                            <div class="item-container">
                                <div class="item-name">
                                    {{profileName}}
                                </div>
                            </div>
                        </div>
                        <div class="closeBan" @click="$emit('close')">
                            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="31" height="31" rx="6.25829" fill="#42475F"/>
                            <path d="M10.093 9.83502L20.907 20.649" stroke="#858BA5" stroke-width="1.44186" stroke-linecap="round"/>
                            <path d="M20.907 9.83502L10.093 20.649" stroke="#858BA5" stroke-width="1.44186" stroke-linecap="round"/>
                            </svg> 
                        </div>
                        <div class="ban_popup-block-container">
                            <div class="ban_popup-block-container_change">

                                
                                <input type="checkbox" v-model="banChat" id="ban-chat">
                                <label for="ban-chat">Бан в чате</label>

                                <input type="checkbox" v-model="banGame" id="ban-game">
                                <label for="ban-game">Бан на ставки</label>

                                <input type="checkbox" v-model="banWithdraw" id="ban-withdraw">
                                <label for="ban-withdraw">Бан на вывод</label>



                                <!-- <select name="" id="">
                                    <option value="">
                                        Бан в чате
                                    </option>
                                    <option value="">
                                        Бан на ставки
                                    </option>
                                    <option value="">
                                        Бан на вывод
                                    </option>
                                </select> -->
                            </div>
                            <div class="ban_popup-block-container__elements">
                                <div class="ban_element__block">
                                    дни: <input type="number" v-model="banDays" min="0">
                                </div>
                                <div class="ban_element__block">
                                    часы: <input type="number" v-model="banHours" min="0">
                                </div>
                            </div>
                            <div class="ban_popup-block-container__buttons">
                                <div class="ban__button" @click="banUser()">
                                    забанить
                                </div>
                                <div class="ban__button" @click="banUser(true)">
                                    забанить навсегда
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="ban_popup-bg" @click="$emit('close')"></div>
                </div>
</template>


<style>
.item-message span{
    color: #5FE895;
    transition: all 0.25s ease;
    cursor: pointer;
}
.item-message span:hover{
    color: #29a65b;
}
</style>
<style scoped>



.fade-enter-active {
  transform: translate(0%, 0%);
  opacity: 1;
  transition: all 0.25s ease;
}

.fade-leave-active {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-enter-from {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}

.fade-leave-to {
  transform: translate(0%, 0%);
  opacity: 0;
  transition: all 0.25s ease;
}







.chat-item{
    background-color: #444861;
    border-radius: 1vw;
    max-height: fit-content;
    height: auto;
    min-height: auto;
    width: 100%;
    padding: 0.8vw;
    margin: 0.5vw 0px;
    justify-content: space-between;
    transition: all 0.25s ease;
}
.item-header{
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 0 1.1vw 0;
}
.item-profile{
    cursor: pointer;
}
.item-img{
    border: 0.2vw solid #4F536D;
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 50%;
    overflow: hidden;
}
.item-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.item-lvl{
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 2vw;
    width: 2vw;
    max-width: 2.4vw;
    transform: translate(15%, 50%);
    border-radius: 0.4vw;
    padding: 0.1vw 0.4vw;
    background-color: #4F536D;
    color: #5FE895;
    font-family: "VelaSans", sans-serif;
    font-size: 0.5vw;
    font-weight: 800;
}
.item-container{
    width: calc(100% - 2.5vw - 1.4vw);
    align-items: flex-start;
    margin: 0 0 0 1vw;
}
.item-name.admin{
    color: #ff4545;
}
.item-name{
    color: #A1A5CD;
    font-family: "VelaSans", sans-serif;
    font-size: 0.9vw;
    font-weight: 800;
}
.item-time{
    background-color: #77ADFF40;
    padding: 0px 0.2vw;
    border-radius: 0.3vw;
    color: #A4DEFF;
    font-family: "VelaSans", sans-serif;
    font-size: 0.7vw;
    font-weight: 600;
}
.item-ban{
    cursor: pointer;
}
.item-ban svg{
    margin: 0 0 10px 0;
}
.item-mute{
    border-radius: 0.3vw;
    width: 1.4vw;
    height: 1.4vw;
    padding: 0.3vw;
    background-color: #595D77;
    transition: all 0.25s ease;
    cursor: pointer;
}
.item-mute:hover{
    background-color: #646884;
}
.item-message{
    width: 100%;
    height: fit-content;
    text-align: left;
    word-break: break-all;
    word-wrap: break-word; 
    color: #FFF;
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 500;
    word-break: normal;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    display: block;
}
.item-answer{
    width: 100%;
    margin: 1vw 0 0 0;
    justify-content: flex-start;
    flex-direction: row;
}
.answer-img{
    width: 0.5vw;
    height: 0.5vw;
    cursor: pointer;
}
.answer-text{
    margin: 0 0 0 0.5vw;
    color: #7E85AD;
    font-family: "VelaSans", sans-serif;
    font-size: 0.8vw;
    font-weight: 700;
    transition: all 0.25s ease;
    cursor: pointer;
}
.answer-text:hover{
    color: #8991bc;
}

.streamer-blur{
    filter:blur(0.3rem);
}
.streamer-blur:hover{
    filter:blur(0rem);
}
/* 
.streamer-blur .item-img img, .streamer-blur .item-message, .streamer-blur .item-name{
    filter:blur(0.3rem);
}
.streamer-blur .item-img:hover img, .streamer-blur .item-message:hover, .streamer-blur .item-name:hover{
    filter:blur(0);
} */





.ban_popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
    transition: all 0.25s ease;
}
.ban_popup-block {
    width: fit-content;
    height: fit-content;
    padding: 20px;
    background-color: #2f3244;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 21;
}
.ban_popup-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(5px);
    transition: all 0.25s ease;
}


.ban_popup-block-top{
    width: 100%;
    flex-direction: row;
    margin: 0 0 20px 0;
}



.ban_popup-block-container{
    width: 100%;

}
.ban_popup-block-container_change{
    margin: 10px 0 0 0;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
.ban_popup-block-container_change input{
    display: none;
}
.ban_popup-block-container_change input:checked +label{
    background-color: rgba(187, 66, 66, 0.3);
    color: rgba(255, 88, 88, 1);
    border: 1px solid rgb(187, 66, 66);
}
.ban_popup-block-container_change input:checked +label::before{
    color: rgba(255, 88, 88, 1);
    opacity: 1;
    line-height: 100%;
    transform: translateX(0px);
}
.ban_popup-block-container_change label::before{
    content: "выбран";
    position: absolute;
    top: -20px;
    left: 0;
    text-align: left;
    width: 100%;
    transform: translateX(-20px);
    font-family: "VelaSans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    opacity: 0;
    transition: all 0.25s ease;
}
.ban_popup-block-container_change label{
    width: 150px;
    height: 100%;
    border-radius: 7px;
    padding: 10px;
    background-color: #444965;
    outline: none;
    border: 1px solid rgba(187, 66, 66, 0);
    text-align: center;
    user-select: none;
    color: #DBE0FF;
    font-family: "VelaSans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
    letter-spacing: -0.72px;
    transition: all 0.25s ease;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.ban_popup-block-container_change label:nth-child(4){
    margin: 0 10px;
}

.ban_line__item{
    width: 100%;
    color: #DBE0FF;
    text-align: center;
    font-family: "VelaSans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 137.6%; /* 17.888px */
    letter-spacing: 0.52px;
    text-transform: uppercase;
}
.ban_popup-block-container__elements{
    width: 100%;
    margin: 10px 0 0 0;
    color: #DBE0FF;
    text-align: center;
    font-family: "VelaSans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 137.6%; /* 17.888px */
    letter-spacing: 0.52px;
    text-transform: uppercase;
}
.ban_element__block{
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0;
    flex-direction: row;
    color: #DBE0FF;
    text-align: center;
    font-family: "VelaSans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 137.6%; /* 17.888px */
    letter-spacing: 0.52px;
    text-transform: uppercase;
}
.ban_element__block input{
    margin: 0 0 0 5px;
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: none;
    border-radius: 7px;
    background: #444965;
    color: #DBE0FF;
    font-family: "VelaSans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 137.6%; /* 24.768px */
    letter-spacing: -0.72px;
}
.ban_popup-block-container__buttons{
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 0 0 0;
}
.ban__button{
    width: 100%;
    height: 50px;
    margin: 10px 0;
    color: #DBE0FF;
    text-align: center;
    font-family: "VelaSans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 137.6%; /* 17.888px */
    letter-spacing: 0.52px;
    border-radius: 10px;
    padding: 10px 30px;
    background: rgba(197, 77, 77, 0.80);
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;
    transition: all 0.25s ease;
}
.ban__button:hover{
    transform: translateY(-5px);
    background: rgba(238, 67, 67, 0.8);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
}


.closeBan{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

























@media (max-width: 1024px) {
    .chat-item{
        border-radius: 15px;
        padding: 18px;
        margin: 4px 0;
    }
    .item-header{
        margin: 0 0 10px 0;
    }
    .item-img{
        width: 44px;
        height: 44px;
        border-radius: 14px;
        border: 2px solid #4F536D;
    }
    .item-lvl{
        position: absolute;
        bottom: 0;
        right: 0;
        min-width: 36px;
        width: 36px;
        max-width: 36px;
        transform: translate(15%, 50%);
        border-radius: 5px;
        padding: 2px 4px;

        color: #5FE895;
        font-size: 8px;
        font-style: normal;
        font-weight: 800;
        line-height: 137.6%;
    }

    .item-container{
        width: calc(100% - 44px - 32px);
        margin: 0 0 0 10px;
    }
    .item-mute{
        width: 32px;
        height: 32px;
        border-radius: 6px;
        padding: 6px;
    }

    .item-name{
        color: #A1A5CD;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 137.6%;
        letter-spacing: -0.54px;
        margin: 0 0 2px 0;
    }
    .item-time{
        padding: 2px 8px;
        border-radius: 6px;
        color: #A4DEFF;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 137.6%;
        letter-spacing: -0.33px;
    }

    .item-message{
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }
    .item-answer{
        margin: 10px 0 0 0;
    }
    .answer-img{
        width: 9px;
        height: 8px;
        margin: 0 2px 0 0;
    }
    .answer-text{
        color: #7E85AD;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: -0.363px;
    }
}
</style>